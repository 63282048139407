<script setup lang="ts">
import { type InertiaForm, router } from '@inertiajs/vue3';
import { onUnmounted } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate.ts';
import { useConfirm } from 'primevue/useconfirm';

const { form } = defineProps<{ form: InertiaForm<object> }>();

const emit = defineEmits<{
  'confirm-cancel': [];
}>();

const { translate } = useTranslate();
const confirm = useConfirm();

const confirmUnsavedChanges = router.on('before', (event) => {
  if (event.detail.visit.method === 'get' && form.isDirty) {
    event.preventDefault();

    confirm.require({
      header: translate('global.form.dirty.header'),
      message: translate('global.form.dirty.message'),
      acceptLabel: translate('global.form.dirty.accept'),
      rejectProps: {
        label: translate('global.form.dirty.reject'),
        severity: 'secondary',
        text: true,
      },
      accept: () => {
        confirmUnsavedChanges();
        router.visit(event.detail.visit.url);
      },
      reject: () => emit('confirm-cancel'),
    });
  }
});
onUnmounted(() => confirmUnsavedChanges());

</script>

<template>
  <slot/>
</template>
