<script setup lang="ts">
import { type DomainResource, DomainType } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { computed, ref } from 'vue';
import updateDomain from '@/Pages/Domains/UpdateDomain';
import ExposureReportModal from '@/Pages/Domains/Exposure/Partials/ExposureReportModal.vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import type { MenuItem } from 'primevue/menuitem';
import ParkedDomainModel from '@/Pages/Domains/Partials/ParkedDomainModel.vue';
import { useRoute } from 'ziggy-js';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';

const { domain } = defineProps<{
  domain: DomainResource;
  severity: 'secondary' | 'info' | 'success' | 'warn' | 'danger' | 'contrast' | null | undefined;
}>();

const { translate } = useTranslate();
const toast = useToast();
const route = useRoute();

const emits = defineEmits<{
  (e: 'show-logs', domain: DomainResource): void;
}>();

const menuItems = computed(() => {
  let menuItems: MenuItem[] = [];

  if (!domain.is_favorite) {
    menuItems.push({
      command: () => updateDomain(domain, { is_favorite: true }),
      label: translate('domains.actions.set_primary'),
      icon: 'pi pi-star',
    });
  }

  if (domain.type === 'parked') {
    menuItems.push({
      command: () => updateDomain(domain, { type: DomainType.ACTIVE }),
      color: 'text-green-600',
      label: translate('domains.actions.set_as_active'),
      icon: 'pi pi-play',
    });
  } else {
    menuItems.push({
      command: () => showParkedDomainModal.value = true,
      color: 'text-red-600',
      label: translate('domains.actions.set_as_parked'),
      icon: 'pi pi-pause-circle',
    })
  }

  if (domain.grants?.sync_dns) {
    menuItems.push({
      command: async () => {
        try {
          await axios.post(
            route('api.dns.sync', { customer: domain.customer_uuid, domain: domain.uuid }),
          );
          toast.add({ severity: 'success', summary: translate('global.form.success.title'), life: 5000 });
        } catch (error) {
          console.error(error);
          toast.add({
            severity: 'error',
            summary: translate('global.form.failed.title'),
            detail: translate('global.form.failed.description'),
            life: 5000,
          });
        }
      },
      label: translate('domains.actions.sync_dns_records'),
      icon: 'pi pi-refresh',
    });
  }
  menuItems.push({
    command: () => showGenerateReportModal.value = true,
    label: translate('domains.actions.domain_report'),
    icon: 'pi pi-chart-pie',
  }, {
    command: () => emits('show-logs', domain),
    label: translate('domains.actions.view_logs'),
    icon: 'pi pi-history',
  });

  return menuItems;
});

const menu = ref();
const showGenerateReportModal = ref(false);
const showParkedDomainModal = ref(false);

const toggle = (event: Event) => {
  menu.value.toggle(event);
};
</script>

<template>
  <div class="pi">
    <Button
      type="button"
      text
      icon="pi pi-ellipsis-v"
      @click="toggle"
      size="small"
      rounded
      severity="secondary"
      aria-haspopup="true"
      aria-controls="domain_menu"
    />
    <Menu
      ref="menu"
      id="overlay_menu"
      :model="menuItems"
      :popup="true"
    >
      <template #item="{ item, props }">
        <a class="flex items-center" v-bind="props.action">
          <span :class="`${item?.icon} ${item?.color}`" />
          <span :class="item?.color">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
    <ExposureReportModal
      v-if="showGenerateReportModal"
      @hide="showGenerateReportModal = false"
      :domain="domain"
    />
    <ParkedDomainModel
      v-if="showParkedDomainModal"
      @hide="showParkedDomainModal = false"
      :domain="domain"
    />
  </div>
</template>
