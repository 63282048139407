<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import { useTranslate } from '@/Utils/Translations/useTranslate.ts';
import { ref } from 'vue';
import Menu from 'primevue/menu';
import { Link } from '@inertiajs/vue3';
import { useComponentTokens } from '@/Utils/useComponentTokens.ts';

const { isMobileMenuOpen = false }
  = defineProps<{ isMobileMenuOpen: boolean }>();

const { activeCustomer, activeDomain } = usePage().props;

const { translate } = useTranslate();

const route = useRoute();

const activeRoute = (wildcard: string) => Boolean(route().current(wildcard));

const domainCreateRoute = route('ui.domains.create', { customer: activeCustomer.uuid });

const senderStatisticsRoute = activeDomain
  ? route('ui.senders-statistics.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

const failureReportsRoute = activeDomain
  ? route('ui.failure-reports.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

const tlsStatisticsRoute = activeDomain
  ? route('ui.tls-statistics.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

const items = ref([
  {
    label: translate('menu.home'),
    icon: 'pi pi-home',
    to: route('ui.dashboard.show', { customer: activeCustomer.uuid }),
    class: activeRoute('ui.dashboard.show') ? 'p-focus' : '',
  },
  {
    label: translate('menu.reports.reports'),
    items: [
      {
        label: translate('menu.reports.email_senders'),
        icon: 'pi pi-chart-bar',
        to: senderStatisticsRoute,
        class: activeRoute('ui.senders-statistics.index') ? 'p-focus' : '',
      },
      {
        label: translate('menu.reports.tls_rpt_reports'),
        icon: 'pi pi-chart-line',
        to: tlsStatisticsRoute,
        class: activeRoute('ui.tls-statistics.index') ? 'p-focus' : '',
      },
      {
        label: translate('menu.reports.failure_reports'),
        icon: 'pi pi-chart-scatter',
        to: failureReportsRoute,
        class: activeRoute('ui.failure-reports.index') ? 'p-focus' : '',
      },
    ],
  },
  {
    label: translate('menu.account'),
    items: [
      {
        label: translate('menu.domains'),
        icon: 'pi pi-server',
        to: route('ui.domains.index', activeCustomer.uuid),
        class: activeRoute('ui.domains.*') || activeRoute('*-settings.*') ? 'p-focus' : '',
      },
      {
        label: translate('menu.settings.alerts'),
        icon: 'pi pi-bell',
        to: route('ui.alerts.index', { customer: activeCustomer.uuid }),
        class: activeRoute('ui.alerts.*') ? 'p-focus' : '',
      },
    ],
  },
]);
const menuTokens = useComponentTokens()?.sidebar?.menu || {};
</script>

<template>
  <div
    :class="{hidden : !isMobileMenuOpen}"
    class="side-bar-menu flex-col gap-1 bg-section-default rounded xl:bg-transparent xl:flex"
  >
    <Menu
      :model="items"
      :dt="menuTokens"
      class="w-full"
    >
      <template #item="{ item, props }">
        <Link
          :href="item.to"
          class="flex items-center"
          v-bind="props.action"
        >
          <span :class="item.icon" />
          <span v-text="item.label" />
        </Link>
      </template>
    </Menu>
  </div>
</template>
