<script setup lang="ts">
import Drawer from 'primevue/drawer';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { router, useForm } from '@inertiajs/vue3';
import { computed, reactive } from 'vue';
import FormGroup from '@/Components/FormGroup.vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import { useRoute } from 'ziggy-js';
import type { PartnerResource } from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http.ts';
import Select from 'primevue/select';

const { partner, partners } = defineProps<{
  partner: PartnerResource | null;
  partners: PaginatedResponse<PartnerResource>;
}>();

const form = useForm({ ...partner });

const state = reactive({
  isOpen: true,
});

const title = computed(() => (partner ? 'Update' : 'Create'));
const route = useRoute();
const parents = computed(() => partners.data
  .filter(partner => partner.uuid !== form.uuid)
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(partner => ({
    label: partner.name,
    value: partner.uuid,
  })),
);

function submit() {
  if (partner && form.uuid) {
    form.put(
      route('ui.partners.update', { partner: form.uuid }),
      responseCallbacks,
    );
    return;
  }

  form.post(route('ui.partners.store'), {
    onSuccess: () => {
      state.isOpen = false;
    },
  });
}

const closeDrawer = () => {
  state.isOpen = false;
  router.get(route('ui.partners.index'), {}, { preserveState: !!partner });
};
</script>

<template>
  <Drawer
    v-model:visible="state.isOpen"
    @hide="closeDrawer"
    :header="title + ' a partner'"
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded-l-2xl'
      }
    }"
  >
    <FormGroup :for-form="form" label="Name" field="name">
      <template v-slot:element="slotProps">
        <InputText v-bind="slotProps" v-model="form.name" />
      </template>
    </FormGroup>

    <FormGroup :for-form="form" label="Parent Partner" field="parent_uuid">
      <template v-slot:element="slotProps">
        <Select
          v-model="form.parent_uuid"
          v-bind="slotProps"
          :allow-empty="true"
          :options="parents"
          optionLabel="label"
          optionValue="value"
        />
      </template>
    </FormGroup>

    <template #footer>
      <div class="flex flex-row-reverse items-center gap-2">
        <form @submit.prevent="submit">
          <Button label="Save" size="large" type="submit" severity="primary" />
        </form>
      </div>
    </template>
  </Drawer>
</template>
