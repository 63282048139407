<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import BlockedVolumeChart from '@/Pages/Statistics/Sender/Charts/BlockedVolumeChart.vue';
import { computed } from 'vue';
import Legend from '@/Components/Legend.vue';

const { dailyStatistics } = defineProps<{
  dailyStatistics: Record<string, StatisticsTotals>;
}>();

const { formatNumber } = useFormatters();

const blockedVolumeTotal = computed(() => sumBy(Object.values(dailyStatistics), 'total_not_delivered'));
const overriddenBlocked = computed(() => sumBy(Object.values(dailyStatistics), 'total_blocked'));
const notDeliveredNotOverridden = computed(() => sumBy(Object.values(dailyStatistics), stat => stat.total_not_delivered - stat.total_blocked));
</script>
<template>
  <ChartCard
    :title="$t('senders_reports.cards.blocked_volume')"
  >
    <template #lead>
      <h6 class="mt-4 text-2xl">{{ formatNumber(blockedVolumeTotal) }}</h6>
    </template>
    <template #details>
      <div class="leading-5">
        <Legend
          :text="`${$t('senders_reports.senders.legends.overridden')} (${formatNumber(overriddenBlocked)})`"
          :icon-size="3"
          icon-class="bg-graphs-warning"
        />
        <Legend
          :text="`${$t('senders_reports.senders.legends.not_delivered')} (${formatNumber(notDeliveredNotOverridden)})`"
          :icon-size="3"
          icon-class="bg-graphs-negative"

        />
      </div>
    </template>
    <template #graph>
      <BlockedVolumeChart :data="dailyStatistics"/>
    </template>
  </ChartCard>
</template>
