<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import Slider from 'primevue/slider';
import AccordionPanel from 'primevue/accordionpanel';
import { reactive } from 'vue';
import { type InertiaForm } from '@inertiajs/vue3';
import type { DmarcSettingsResource } from '@/types/types.gen';
import { useFormatters } from '@/Utils/Formatting';

const form = defineModel<InertiaForm<DmarcSettingsResource>>({ required: true });

const state = reactive({
  same_policy: form.value.policy === form.value.subdomain_policy,
});
const { formatPercentage } = useFormatters();
const toggleApplySamePolicy = () => {
  if (state.same_policy) {
    form.value.subdomain_policy = form.value.policy;
  }
};
</script>

<template>
  <AccordionPanel value="0">
    <AccordionHeader>
      {{ $t('dmarc_settings.policy.heading') }}
    </AccordionHeader>
    <AccordionContent>
      <div class="flex flex-col gap-8 px-5 py-4">
        <FormGroup
          :label="$t('dmarc_settings.policy.fields.policy.input_label')"
          :error="form.errors.policy"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.policy"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                { label: 'None', value: 'none' },
                { label: 'Quarantine', value: 'quarantine' },
                { label: 'Reject', value: 'reject' }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>
        <FormGroup :label="$t('dmarc_settings.policy.fields.diff_subdomain_policy.input_label')">
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="state.same_policy"
              v-bind="slotProps"
              :allow-empty="false"
              @change="toggleApplySamePolicy"
              :options="[
                { label: $t('global.fields.yes_no.options.yes'), value: false },
                { label: $t('global.fields.yes_no.options.no'), value: true }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="!state.same_policy"
          :label="$t('dmarc_settings.policy.fields.subdomain_policy.input_label')"
          :error="form.errors.subdomain_policy"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.subdomain_policy"
              :allow-empty="false"
              v-bind="slotProps"
              :options="[
                { label: 'None', value: 'none' },
                { label: 'Quarantine', value: 'quarantine' },
                { label: 'Reject', value: 'reject' }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          :label="$t('dmarc_settings.policy.fields.percentage.input_label')"
          :error="form.errors.policy_percentage"
        >
          <template v-slot:element="slotProps">
            <Slider
              v-bind="slotProps"
              v-model="form.policy_percentage"
              class="w-56 my-1"
            />
            {{ formatPercentage(form.policy_percentage) }}
          </template>
        </FormGroup>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>
