<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import RadioButton from 'primevue/radiobutton';
import FormGroup from '@/Components/FormGroup.vue';
import type { InertiaForm } from '@inertiajs/vue3';
import { SpfQualifier, type SpfSettingsForm } from '@/Pages/Domains/SpfSettings/types';

const form = defineModel<InertiaForm<SpfSettingsForm>>({ required: true });
</script>

<template>
  <div>
    <div class="pb-5 pt-8 text-xl font-bold">
      {{ $t('spf_settings.settings.heading') }}
    </div>

    <div class="flex flex-col gap-8">
      <FormGroup
        :for-form="form"
        :label="$t('spf_settings.settings.fields.flattening.input_label')"
        :info="$t('spf_settings.settings.fields.flattening.tooltip')"
        field="enable_flattening"
      >
        <template v-slot:element="slotProps">
          <SelectButton
            v-model="form.enable_flattening"
            v-bind="slotProps"
            :allowEmpty="false"
            :options="[
              { label: $t('global.fields.enabled_disabled.options.enabled'), value: true },
              { label: $t('global.fields.enabled_disabled.options.disabled'), value: false }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>

      <FormGroup
        :for-form="form"
        :label="$t('spf_settings.settings.fields.all_mechanism_qualifier.input_label')"
        field="all_mechanism_qualifier"
      >
        <template #element>
          <div class="flex flex-col gap-2">
            <div
              v-for="option in SpfQualifier"
              :key="option"
              class="flex items-center"
            >
              <RadioButton
                v-model="form.all_mechanism_qualifier"
                :inputId="`all_mechanism_qualifier${option}`"
                name="all_mechanism_qualifier"
                :value="option"
              />
              <label
                :for="'all_mechanism_qualifier' + option"
                class="ml-2"
                v-text="$t(`spf_settings.settings.fields.all_mechanism_qualifier.options.${option}`)"
                v-tooltip.top="$t(`spf_settings.settings.fields.all_mechanism_qualifier.options_tooltips.${option}`)"
              />
            </div>
          </div>
        </template>
      </FormGroup>
    </div>
  </div>
</template>
