import { UiFeatures } from '@/types/types.gen';
import { usePage } from '@inertiajs/vue3';

export const useUiFeatures = (feature: UiFeatures | UiFeatures[]) => {
  const { props: { oemConfig } } = usePage();

  if (Array.isArray(feature)) {
    return feature.some(
      feature => oemConfig.ui_features.some(
        uiFeature => uiFeature === feature,
      ),
    );
  }

  return oemConfig.ui_features.some(
    uiFeature => uiFeature === feature,
  );
}
