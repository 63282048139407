<script setup lang="ts">
import Drawer from 'primevue/drawer';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import { type InertiaForm, router } from '@inertiajs/vue3';
import { ref } from 'vue';
import FormGroup from '@/Components/FormGroup.vue';
import type { CustomerResource } from '@/types/types.gen';
import type { DomainCreateForm } from '@/types/form';

defineProps<{
  customer: CustomerResource;
}>();

const emits = defineEmits(['submit']);

const isOpen = ref(true);

const form = defineModel<InertiaForm<DomainCreateForm>>({ required: true });
</script>

<template>
  <Drawer
    v-model:visible="isOpen"
    @hide="router.get(route('ui.domains.index', customer.uuid))"
    :header="$t('domains.create_drawer.header')"
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded'
      }
    }"
  >
    <form @submit.prevent="emits('submit')">
      <div class="flex flex-col gap-7">
        <FormGroup :label="$t('domains.fields.name.input_label')" :error="form.errors.domain_name" :example="$t('domains.fields.name.example')">
          <template v-slot:element="slotProps">
            <InputText v-bind="slotProps" v-model="form.domain_name" />
          </template>
        </FormGroup>

        <FormGroup :label="$t('domains.fields.type.input_label')" :error="form.errors.type">
          <template v-slot:element="slotProps">
            <SelectButton
              v-bind="slotProps"
              v-model="form.type"
              :allow-empty="false"
              :options="[
                { label: $t('domains.fields.type.options.active'), value: 'active' },
                { label: $t('domains.fields.type.options.parked'), value: 'parked' }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>
      </div>
    </form>
    <template #footer>
      <div class="flex flex-row-reverse">
        <form @submit.prevent="emits('submit')">
          <Button :label="$t('global.buttons.save')" size="large" type="submit" />
        </form>
      </div>
    </template>
  </Drawer>
</template>
