<script setup lang="ts">
import { uniqueId } from 'lodash';
import { computed } from 'vue';

const props = defineProps<{
  label?: string;
  error?: string | null;
  info?: string | null;
  example?: string | null;
  id?: string;
}>();

const inputId = computed(() => props.id ?? uniqueId());
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex gap-2 items-center">
      <label
        :for="inputId"
        v-text="label"
      />
      <div
        v-if="info"
        class="pi pi-info-circle"
        v-tooltip.top="info"
      />
    </div>
    <div class="flex flex-col gap-1">
      <slot name="element" :invalid="Boolean(error)" :id="inputId" />
      <div
        v-if="error"
        class="text-sm text-danger-default"
        v-text="error"
      />
      <div
        v-else-if="example"
        class="text-sm"
        v-text="example"
      />
    </div>
  </div>
</template>
