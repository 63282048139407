<script setup lang="ts">
import { computed } from 'vue';
import { SenderType } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate.ts';

const { senderType } = defineProps<{ senderType: SenderType | null }>();

const { translate } = useTranslate();

const translatedSenderType = computed(() => {
  if (senderType === null) {
    return translate('senders.type.other');
  }

  const translations: Record<SenderType, string> = {
    [SenderType.MAILBOX_PROVIDER]: translate('senders.type.mailbox_provider'),
    [SenderType.MAIL_FILTER]: translate('senders.type.mail_filter'),
    [SenderType.TRANSACTIONAL_MAIL]: translate('senders.type.transactional_mail'),
    [SenderType.SALES___MARKETING]: translate('senders.type.sales_and_marketing'),
    [SenderType.HOSTING]: translate('senders.type.hosting'),
    [SenderType.ISP]: translate('senders.type.isp'),
    [SenderType.SOCIAL]: translate('senders.type.social'),
    [SenderType.OTHER]: translate('senders.type.other'),
  };

  return translations[senderType] || translate('senders.type.other');
});
</script>

<template>
  <div>{{ translatedSenderType }}</div>
</template>
