<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import type {
  DomainResource,
  TlsReporterStatisticsResource,
  TlsVolumeStatisticsResource,
} from '@/types/types.gen';
import Card from 'primevue/card';
import Select from 'primevue/select';
import DatePicker, { type DatePickerEmitsOptions } from 'primevue/datepicker';
import { reactive } from 'vue';
import TimelineChart from '@/Pages/Tls/Partials/TimelineChart.vue';
import Legend from '@/Components/Legend.vue';
import ReportersTable from '@/Pages/Tls/Partials/ReportersTable.vue';
import { Link, router } from '@inertiajs/vue3';
import { format } from 'date-fns';
import { useRoute } from 'ziggy-js';
import type { DateRange } from '@/Pages/Statistics/Sender/types';
import WarningCard from '@/Components/WarningCard.vue';
import Button from 'primevue/button';

const { domain, dateRange } = defineProps<{
  dateRange: DateRange;
  domain: DomainResource;
  domains: DomainResource[];
  reporters: TlsReporterStatisticsResource[];
  volume: TlsVolumeStatisticsResource[];
  isTlsRptConfigured: boolean;
}>();

const state = reactive<{
  dateRange: [Date, Date];
  activeDomain: string;
}>({
  dateRange: [new Date(`${(dateRange.start_date)}`), new Date(`${(dateRange.end_date)}`)],
  activeDomain: domain.uuid,
});

const changeParams = (params: {
  domain?: string;
  start_date?: string;
  end_date?: string;
} = {}) => {
  router.get(useRoute()('ui.tls-statistics.index', {
    customer: domain.customer_uuid,
    domain: params.domain ?? domain.uuid,
    start_date: params.start_date ?? format(state.dateRange[0], 'yyyy-LL-dd'),
    end_date: params.end_date ?? format(state.dateRange[1], 'yyyy-LL-dd'),
  }));
};

const updateDomain = (domainUuid: string) => {
  changeParams({ domain: domainUuid });
}

const updateDateRange: DatePickerEmitsOptions['update:modelValue'] = (dateRange) => {
  // Will only have 2 dates after full range is selected
  if (!Array.isArray(dateRange) || !dateRange[0] || !dateRange[1]) {
    return;
  }

  changeParams({
    start_date: format(dateRange[0], 'yyyy-LL-dd'),
    end_date: format(dateRange[1], 'yyyy-LL-dd'),
  });
};

</script>

<template>
  <AppLayout :title="$t('tls_reports.title')">
    <div class="text-2xl font-semibold mb-8"
      v-text="$t('tls_reports.heading', {domain: domain.domain_name})"
    />

    <div class="flex flex-col gap-8">
      <div class="flex flex-row gap-5">
        <Select
          filter
          v-model="state.activeDomain"
          optionValue="uuid"
          optionLabel="domain_name"
          :options="domains ?? []"
          @update:modelValue="updateDomain($event)"
        />
        <DatePicker
          class="w-80"
          selectionMode="range"
          showIcon
          v-model="state.dateRange"
          iconDisplay="input"
          :maxDate="new Date()"
          hideOnRangeSelection
          @update:modelValue="updateDateRange($event)"
          dateFormat="yy-mm-dd"
          :fluid=true
        />
      </div>

      <WarningCard
        v-if="!isTlsRptConfigured"
        class="w-full"
        :title="$t('tls_reports.cards.not_configured.header')"
        :description="$t('tls_reports.cards.not_configured.description')"
      >
        <Link class="mt-2" :href="route('ui.tls-settings.edit', [domain.customer_uuid, domain.uuid])">
          <Button :label="$t('tls_reports.cards.not_configured.action')" size="small" />
        </Link>
      </WarningCard>

      <WarningCard
        v-else-if="!volume.length"
        class="w-full"
        :title="$t('reports.cards.no_reporting_data.header')"
        :description="$t('reports.cards.no_reporting_data.description')"
      />

      <div v-else class="flex flex-col gap-8">
        <Card class="w-full">
          <template #content>
            <div class="flex justify-between items-center mb-8">
              <div class="text-xl font-semibold"
                v-text="$t('tls_reports.timeline.heading')"
              />

              <div class="flex gap-6 text-sm bg-section-default py-3 px-4 rounded">
                <Legend
                  :icon-size="2"
                  :text="$t('tls_reports.timeline.legend.success')"
                  :icon-class="['bg-graphs-positive']"
                />
                <Legend
                  :icon-size="2"
                  :text="$t('tls_reports.timeline.legend.failure')"
                  :icon-class="['bg-graphs-negative']"
                />
              </div>
            </div>
            <TimelineChart :data="volume" />
          </template>
        </Card>

        <Card>
          <template #content>
            <div class="text-xl font-semibold mb-10"
               v-text="$t('tls_reports.reporters.heading')"
            />
            <ReportersTable
              :reporters="reporters"
            />
          </template>
        </Card>
      </div>
    </div>
  </AppLayout>
</template>
