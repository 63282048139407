<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import DeliverabilityChart from '@/Pages/Statistics/Sender/Charts/DeliverabilityChart.vue';
import { computed } from 'vue';

const { data } = defineProps<{ data: StatisticsTotals[] }>();

const { formatNumber, formatPercentage } = useFormatters();

const totalAmount = computed(() => sumBy(data, 'total_incidents'));
const deliveredAmount = computed(() => sumBy(data, 'total_delivered'));
</script>
<template>
  <ChartCard
    :title="$t('senders_reports.cards.deliverability')"
  >
    <template #lead>
      <h6 class="mt-4 text-2xl">{{ formatNumber(deliveredAmount) }}</h6>
      <span class="text-lg">
        / {{ formatNumber(totalAmount)}}
      </span>
    </template>
    <template #graph>
      <DeliverabilityChart :data="data">
        <template #default>
          <h6 class="font-bold">
            {{ formatPercentage(deliveredAmount/totalAmount * 100) }}
          </h6>
          <small class="text-sm">
            {{ $t('senders_reports.cards.deliverable') }}
          </small>
        </template>
      </DeliverabilityChart>
    </template>
  </ChartCard>
</template>
