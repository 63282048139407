<script setup lang="ts">
import { SenderCategory } from '@/types/types.gen.js';
import Select from 'primevue/select';
import Message from 'primevue/message';
import { computed } from 'vue';
import { snakeCase } from 'lodash';
import { useTranslate } from '@/Utils/Translations/useTranslate';

defineEmits<{ 'update-category': [category: SenderCategory] }>();

const { isSaving = false, category } = defineProps<{ category: SenderCategory; isSaving?: boolean; hasSender: boolean }>();

const { translate } = useTranslate();
const categories = Object.entries(SenderCategory)
  .filter(([_, value]) => value !== SenderCategory.ALL)
  .map(([key, value]) => {
    const formattedCategory = snakeCase(key);
    /** @ts-expect-error There is no way to typehint this using a dynamic key */
    const label = translate(`senders_reports.categories.${formattedCategory}`);

    return { label, value };
  });

const className = computed(() => `category-selector-${snakeCase(category)}`)
</script>
<template>
  <Select
    v-if="hasSender"
    :modelValue="$props.category"
    :loading="isSaving"
    :options="categories"
    optionLabel="label"
    optionValue="value"
    @update:modelValue="$emit('update-category', $event)"
    class="category-selector w-full font-bold"
    :class="[className]"
  />
  <Message v-else
    class="italic"
    v-tooltip.top="$t('senders_reports.categories.uncategorized.tooltip')"
    severity="secondary"
    variant="simple"
    size="small"
    icon="pi pi-info-circle"
  >
    {{ $t('senders_reports.categories.uncategorized.message') }}
  </Message>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
.category-selector {
  --p-select-border-radius: var(--p-button-border-radius);
  --p-select-padding-y: calc(var(--p-select-padding-x) * 0.6)
}
/**
 * The semantic css variables that referenced here are not defined in the code,
 * but exist in the figma designs.
 * As far as I can tell the "design-tokens" exist, but do not end up as css variables in the compiled code.
 * @see https://primevue.org/theming/styled/#colors
 *
 * The below hex values need to be defined as a light/dark theme in the design tokens,
 * Or the existing design tokens must be made available to reference here.
 */
.category-selector-unknown {
  --p-select-border-color: tranparent;
}
.category-selector-authorised_compliant {
  --p-select-background: var(--p-success-background);
  --p-select-border-color: tranparent;
  --p-select-color: var(--p-success-foreground);
  --p-select-dropdown-color: var(--p-success-foreground);

}
.category-selector-forwarder {
  --p-select-background: var(--p-warn-background);
  --p-select-border-color: tranparent;
  --p-select-color: var(--p-warn-foreground);
  --p-select-dropdown-color: var(--p-warn-foreground);
}
.category-selector-suspicious {
  --p-select-background: var(--p-danger-background);
  --p-select-border-color: tranparent;
  --p-select-color: var(--p-danger-foreground);
  --p-select-dropdown-color: var(--p-danger-foreground);
}
.category-selector-unauthorised {
  --p-select-background: var(--p-danger-background);
  --p-select-border-color: tranparent;
  --p-select-color: var(--p-danger-foreground);
  --p-select-dropdown-color: var(--p-danger-foreground);
}

.dark .category-selector-unauthorised {
  --p-select-background: var(--p-surface-700);
}
</style>
