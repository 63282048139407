export const Translations = {
    "de": {
        "alerts": {
            "actions": {
                "create_activate": "Erstellen und aktivieren",
                "edit_alert": {
                    "tooltip": "Benachrichtigung bearbeiten"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Konfiguration",
                    "mail_senders": "E-Mail-Absender",
                    "none": "Keiner",
                    "reports": "Berichte",
                    "status": "Status"
                },
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Aktivität",
                "options": {
                    "customer-created": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto erstellt wird.",
                        "heading": "Kunde erstellt"
                    },
                    "customer-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto gelöscht wird.",
                        "heading": "Kunde gelöscht"
                    },
                    "customer-exposure-report": {
                        "description": "Planen Sie, wann Sie den Expositionsbericht erhalten möchten.",
                        "heading": "Expositionsbericht"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planen Sie ein DMARC-Compliance-Zertifikat, wenn die DMARC-Richtlinie auf „Ablehnen“ eingestellt ist.",
                        "heading": "DMARC-Compliance-Zertifikat bei Ablehnung"
                    },
                    "dns-delegation-unverified": {
                        "description": "Benachrichtigen Sie mich, wenn die erneute Überprüfung der DNS-Delegierung fehlschlägt.",
                        "heading": "Die erneute Überprüfung des Setups ist fehlgeschlagen"
                    },
                    "dns-delegation-verified": {
                        "description": "Benachrichtigen, an welcher Stelle sich der Verifizierungsstatus geändert hat",
                        "heading": "Verifizierungsstatus geändert"
                    },
                    "domain-compliance-drop": {
                        "description": "Benachrichtigen Sie mich, wenn die Compliance unter einen Grenzwert fällt.",
                        "heading": "Compliance gesunken"
                    },
                    "domain-created": {
                        "description": "Benachrichtigen Sie mich, wenn eine neue Domain hinzugefügt wird.",
                        "heading": "Domain erstellt"
                    },
                    "domain-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn eine Domain gelöscht wird.",
                        "heading": "Domain gelöscht"
                    },
                    "domain-score-change": {
                        "description": "Benachrichtigen Sie mich, wenn sich der DMARC-Compliance-Score geändert hat.",
                        "heading": "DMARC-Compliance-Score geändert"
                    },
                    "domain-settings-change": {
                        "description": "Benachrichtigen Sie mich, wenn Einstellungen geändert werden.",
                        "heading": "Änderungen an Einstellungen"
                    },
                    "sender-threat-level-change": {
                        "description": "Benachrichtigen Sie mich, wenn die Bedrohungsstufe für neue oder bestehende Absender steigt.",
                        "heading": "Bedrohungsstufe erhöht"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Benachrichtigen Sie mich, wenn ein neuer, allgemein genehmigter Absender erkannt wird.",
                        "heading": "Genehmigter Absender gefunden"
                    }
                }
            },
            "create": {
                "title": "Benachrichtigung erstellen"
            },
            "edit": {
                "title": "Benachrichtigung bearbeiten"
            },
            "email": {
                "description": "Geben Sie den E-Mail-Empfänger an, den Sie benachrichtigen möchten.",
                "heading": "E-Mail"
            },
            "email_settings": {
                "heading": "E-Mail anpassen"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Kunde",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequenz"
                },
                "recipients": {
                    "input_label": "Empfänger"
                },
                "scope": {
                    "input_label": "Überwachungsebene",
                    "listing-title": ":scope Aktivität",
                    "options": {
                        "customer": "Kunde",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup-Überprüfung",
                    "input_label": "Wählen Sie Unten aus"
                },
                "start_date": {
                    "input_label": "Startdatum"
                },
                "status": {
                    "input_label": "Status"
                },
                "subject_tags": {
                    "example": "z.B. [Schwere der Benachrichtigung][Konto][Paket][Domain]",
                    "input_label": "Aktivieren Sie Präfixe für die Kategorisierung der Betreffzeile"
                }
            },
            "frequency": {
                "description": "Wählen Sie aus, wie oft Sie diese Benachrichtigung erhalten möchten.",
                "disabled-text": "Nicht verfügbar – Dies ist eine ereignisbasierte Aktivität",
                "heading": "Frequenz",
                "options": {
                    "monthly": "Monatlich",
                    "quarterly": "Vierteljährlich",
                    "six_monthly": "Sechsmonatlich",
                    "two_monthly": "Zweimonatlich",
                    "yearly": "Jährlich"
                },
                "starting": "Beginnt"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Benachrichtigung",
                        "frequency": "Frequenz",
                        "recipients": "Empfänger",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Aktivität"
                    }
                },
                "title": "Benachrichtigung"
            },
            "monitor": {
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Überwachung"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Täglich",
                        "fortnightly": "Alle zwei Wochen",
                        "hourly": "Stündlich",
                        "immediate": "Sofort",
                        "monthly": "Monatlich",
                        "quarterly": "Vierteljährlich",
                        "six_monthly": "Sechs monatlich",
                        "two_monthly": "Zwei monatlich",
                        "weekly": "Wöchentlich",
                        "yearly": "Jährlich"
                    }
                }
            },
            "senders": {
                "type": {
                    "hosting": "Hosting",
                    "isp": "ISP",
                    "mailbox_provider": "E-Mail-Anbieter",
                    "mail_filter": "Mail-Filter",
                    "other": "Andere",
                    "sales_and_marketing": "Vertrieb & Marketing",
                    "social": "Soziale Netzwerke",
                    "transactional_mail": "Transaktionale E-Mail"
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Benachrichtigung ist inaktiv",
                        "value": "aus"
                    },
                    "on": {
                        "tooltip": "Benachrichtigung ist aktiv",
                        "value": "an"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deaktivieren Sie das BIMI-Datensatz-Hosting",
                "heading": "BIMI-Datensatz-Hosting ist derzeit aktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat einfach verwalten zu können.",
                "modal": {
                    "header": "Deaktivieren Sie BIMI-Datensatz-Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie BIMI-Datensatz-Hosting",
                "heading": "BIMI-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat zu verwalten.",
                "modal": {
                    "header": "Aktivieren Sie BIMI-Datensatz-Hosting :domain",
                    "message": "Um BIMI zu verwenden, benötigen Sie für Ihre Domäne ein Verified Mark Certificate (VMC) oder Common Mark Certificate (CMC), das von einer Drittanbieter-Zertifizierungsstelle ausgestellt wurde."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "CN-Zertifikat",
                    "expiry": "Ablauf des Zertifikats",
                    "input_label": "BIMI Verified Mark-Zertifikat (Dateityp: pem)",
                    "url": "Autoritäts-\/Zertifikats-URL"
                },
                "image": {
                    "input_label": "BIMI Logo (Dateityp: SVG)",
                    "preview": "Logovorschau",
                    "size": "Logogröße",
                    "url": "Logo-URL"
                }
            },
            "info": {
                "heading": "Was ist BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) ist ein Standard, der es einfacher macht, Ihr Logo neben Ihren Nachrichten im Posteingang anzuzeigen, betrügerische E-Mails zu verhindern und die Zustellbarkeit zu verbessern. BIMI arbeitet mit DMARC, DKIM und SPF zusammen, um die Authentizität einer E-Mail zu überprüfen und die Markensichtbarkeit zu verbessern."
            },
            "settings": {
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Zurück zu den Einstellungen",
                "share": "Setup-Anweisungen teilen",
                "verify": "Verifizieren",
                "view": "Setup-Anweisungen anschauen"
            },
            "bimi": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der öffentlichen BIMI-Schlüssel Ihrer Domain zu ermöglichen.",
                "modal": {
                    "not_configured": {
                        "header": "Einrichtungsanleitung nicht verfügbar",
                        "message": "Um DNS-Einträge für Ihre BIMI-Konfiguration zu generieren, müssen Sie ein Logo hochladen."
                    }
                }
            },
            "buttons": {
                "raw_record": "TXT-Eintrag stattdessen verwenden"
            },
            "description": "Um die Überwachung und Verwaltung über DMARC Manager zu ermöglichen, nehmen Sie bitte die folgenden Änderungen in den DNS-Einstellungen Ihrer Domain vor.",
            "dkim": {
                "description": "Fügen Sie die folgenden Einträge zu den DNS-Einträgen Ihrer Domain hinzu, um die Konfiguration ihrer öffentlichen DKIM-Schlüssel zu ermöglichen.",
                "no_nameservers": "Die DKIM-öffentliche Schlüsselzone wird erstellt. Bitte warten Sie auf die Einrichtungshinweise..."
            },
            "dmarc": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der DMARC-Richtlinie Ihrer Domain zu ermöglichen und Berichte über E-Mail-Authentifizierungsaktivitäten über unser Dashboard zu erfassen.",
                "warning": "Beachten Sie die erforderlichen Unterstriche (_) im Host und die Punkte im Datensatzwert."
            },
            "existing_dns_record_found": "Vorhandener :record gefunden",
            "heading": ":title Setup-Anweisungen",
            "instructions": {
                "add": ":rdType Datensatz hinzufügen",
                "heading": ":rdType Einrichtungsanweisungen",
                "new": "Schritt 2: Neuen :rdType Eintrag hinzufügen",
                "remove": "Schritt 1: Vorhandenen :rdType Eintrag entfernen"
            },
            "mta_policy": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration der MTA-Richtlinie Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Richtlinie"
            },
            "mta_record": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration des MTA-Eintrags Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Eintrag"
            },
            "record": {
                "host": "Gastgeber",
                "type": "Typ des Eintrags",
                "value": "Wert"
            },
            "spf": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration des SPF-Eintrags Ihrer Domain über unsere Plattform zu ermöglichen. Unsere Plattform stellt die Gültigkeit des Eintrags sicher und nutzt Optimierungstechnologien, die das Risiko des Erreichens von DNS-Suchgrenzen verringern und sicherstellen, dass Ihre Richtlinie nicht zu durchlässig ist."
            },
            "status": {
                "last_date": "Zuletzt verifiziert am",
                "pending": "Ausstehende Verifizierung",
                "verified": "Verifiziert"
            },
            "tls_rpt": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration des TLS-RPT-Eintrags Ihrer Domain zu ermöglichen und Berichte zu erfassen.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Kunden verwalten"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "E-Mail-Volumen",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt & Sonstiges"
                    }
                },
                "domain_totals": {
                    "action": "Domains anzeigen",
                    "enforced": "Erzwungen",
                    "heading": "Domains",
                    "reporting": "Reporting",
                    "total": "Gesamt"
                },
                "sender_totals": {
                    "action": "Absender anzeigen",
                    "heading": "Absender",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Alle Domains anschauen"
                },
                "legends": {
                    "failing": "DMARC fehlgeschlagen",
                    "forwards": "Weiterleiten",
                    "passing": "DMARC bestanden"
                },
                "table": {
                    "action": {
                        "view_report": "Bericht anschauen"
                    },
                    "header": {
                        "approved": "Genehmigt",
                        "domain": "Domains",
                        "senders": "Absender",
                        "unapproved": "Nicht genehmigt",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Domainbericht anschauen"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "domain": "Domain",
                        "sender": "Zugelassene Absender nach Volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Dashboard"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert stimmt nicht mit dem erwarteten Wert überein.",
                        "missing": "Es konnte kein übereinstimmender CNAME-Eintrag gefunden werden."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "Es konnten keine übereinstimmenden Nameserver gefunden werden.",
                        "mismatch": "Die Nameserver-Einträge stimmen nicht mit der erwarteten Konfiguration überein.",
                        "missing": "Es müssen mindestens zwei Nameserver-Einträge vorhanden sein."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "Der DKIM-Alignmentmodus stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der DKIM-Alignmentmodus fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "aspf": {
                        "mismatch": "Der SPF-Alignmentmodus stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der SPF-Alignmentmodus fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "cname": {
                        "mismatch": "Der CNAME-Eintrag wurde nicht ordnungsgemäß hinzugefügt und der Eintrag stimmt nicht mit der erwarteten Konfiguration überein.",
                        "partial": "Der CNAME-Eintrag wurde nicht ordnungsgemäß hinzugefügt. Dies kann zu Konflikten bei Konfigurationen führen."
                    },
                    "fo": {
                        "mismatch": "Die Fehlerberichtoptionen stimmen nicht mit der Konfiguration überein.",
                        "missing": "Die Fehlerberichtoptionen fehlen, sind jedoch nicht auf den Standard konfiguriert."
                    },
                    "p": {
                        "mismatch": "Die Richtlinie stimmt nicht mit der Konfiguration überein.",
                        "missing": "Die Richtlinie fehlt."
                    },
                    "pct": {
                        "mismatch": "Der Richtlinienanteil stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der Richtlinienanteil fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "rf": {
                        "mismatch": "Das Format der Fehlerberichterstattung stimmt nicht mit der Konfiguration überein."
                    },
                    "ri": {
                        "mismatch": "Das Intervall für aggregierte Berichte stimmt nicht mit der Konfiguration überein.",
                        "missing": "Das Intervall für aggregierte Berichte fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "rua": {
                        "mismatch": "Die Empfänger der aggregierten Berichte stimmen nicht mit der Konfiguration überein.",
                        "order": "Die Empfänger der aggregierten Berichte sind nicht in der richtigen Reihenfolge."
                    },
                    "ruf": {
                        "mismatch": "Die Empfänger der Fehlerberichte stimmen nicht mit der Konfiguration überein.",
                        "order": "Die Empfänger von Fehlerberichten befinden sich nicht in der korrekten Reihenfolge."
                    },
                    "sp": {
                        "mismatch": "Die Subdomain-Richtlinie stimmt nicht mit der Konfiguration überein.",
                        "missing": "Die Subdomain-Richtlinie fehlt, ist aber im Vergleich mit der Domain-Richtlinie anders konfiguriert."
                    },
                    "txt": {
                        "reporting-only": "Der Eintrag stimmt nicht mit der erwarteten Konfiguration überein, aber unsere Berichtsadresse ist vorhanden."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert entspricht nicht dem erwarteten Wert.",
                        "missing": "Kein übereinstimmender CNAME-Eintrag konnte gefunden werden."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "Der TXT-Wert entspricht nicht dem erwarteten Wert."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert entspricht nicht dem erwarteten Wert.",
                        "missing": "Es konnte kein übereinstimmender CNAME-Eintrag gefunden werden."
                    },
                    "rua": {
                        "missing": "Die erwartete Berichtsadresse fehlt."
                    }
                }
            },
            "verify": {
                "error": "Verifizierung fehlgeschlagen",
                "nxdomain": "Eintrag nicht gefunden.",
                "success": "Verifizierung erfolgreich abgeschlossen",
                "timeout": "Verifizierung abgelaufen"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Neuen öffentlichen Schlüssel hinzufügen",
                "edit_public_key": {
                    "tooltip": "Öffentlichen Schlüssel bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neuen öffentlichen Schlüssel hinzufügen"
            },
            "disable": {
                "action": "DKIM-Management deaktiviert",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist derzeit aktiviert",
                "message": "Es wird empfohlen, die DKIM-Verwaltung zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "DKIM-Management für :domain deaktivieren",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "edit_drawer": {
                "header": "Öffentlichen Schlüssel bearbeiten"
            },
            "enable": {
                "action": "DKIM-Management aktivieren",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist deaktiviert",
                "message": "Es wird empfohlen, das DKIM-Management zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "DKIM-Management für :domain aktivieren",
                    "message": "Durch die Aktivierung des DKIM-Managements können Sie Ihre öffentlichen Schlüssel ganz einfach verwalten."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "record_type": {
                    "input_label": "Typ des Eintrags"
                },
                "record_value": {
                    "example": "z.B. dkim.example.com",
                    "input_label": "Wert des Eintrags"
                },
                "selector": {
                    "input_label": "Selektor"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "Import des öffentlichen DKIM-Schlüssels abgeschlossen"
                },
                "disabled_tooltip": "Öffentliche DKIM-Schlüssel können nur für Domains importiert werden, die über Berichte verfügen.",
                "header": "Importieren Sie die öffentlichen DKIM-Schlüssel für :domain erneut",
                "message": "Alle vorhandenen öffentlichen Schlüssel werden entfernt und durch importierte Anweisungen ersetzt."
            },
            "info": {
                "heading": "Was ist DKIM?",
                "message": "Domain Keys Identified Mail ist eine E-Mail-Authentifizierungsmethode, die es einem Absender ermöglicht, eine E-Mail mit einem privaten Schlüssel digital zu signieren. Der private Schlüssel ist dabei mit dem Domainnamen des Absenders verknüpft ist und kann durch den E-Mail-Server des Empfängers über einen öffentlichen Schlüssel überprüft werden, der in den DNS-Einträgen des Absenders veröffentlicht ist. Dies hilft, die Authentizität und Integrität der E-Mail zu überprüfen und das Risiko von Spam- und Phishing-Angriffen zu verringern.“"
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Richtlinie für ausgehende Signaturen",
                        "options": {
                            "all": "Alle E-Mails",
                            "some": "Einige E-Mails"
                        }
                    },
                    "testing": {
                        "input_label": "Testmodus"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Typ des Eintrags",
                        "selector": "Selektor",
                        "value": "Wert"
                    }
                }
            },
            "title": "Einstellungen bearbeiten – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM-Abgleichmodus"
                    },
                    "options": {
                        "r": "Entspannt",
                        "s": "Strikt"
                    },
                    "spf_mode": {
                        "input_label": "SPF-Abgleichmodus"
                    }
                },
                "heading": "Abgleicheinstellungen"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Unterschiedliche Subdomain-Richtlinie anwenden"
                    },
                    "percentage": {
                        "input_label": "Prozentsatz"
                    },
                    "policy": {
                        "input_label": "DMARC-Richtlinie"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain-Richtlinie"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Berichte senden an"
                    },
                    "interval": {
                        "input_label": "Berichtsintervall",
                        "options": {
                            "1_day": "Einmal am Tag",
                            "1_hour": "Stündlich",
                            "2_hours": "Alle 2 Stunden",
                            "4_hours": "Alle 4 Stunden",
                            "6_hours": "Alle 6 Stunden"
                        }
                    }
                },
                "heading": "Aggregierte Berichte"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Aktivieren",
                    "header": "Fehlerberichterstattung aktivieren",
                    "message": "Fehlerberichte geben Ihnen weitere Einblicke in die E-Mails, die DMARC nicht bestanden haben. Durch Aktivieren dieser Funktion können wir die Berichtsdaten verarbeiten und anzeigen, die möglicherweise personenbezogene Informationen wie E-Mail-Adressen oder Nachrichteninhalte enthalten.",
                    "message_disclaimer": "Falls die rechtlichen Richtlinien Ihrer Organisation es untersagen, diese Art von Bericht zu aktivieren, sollten Sie diese Berichte lieber manuell verarbeiten und den Empfänger für die Berichte angeben.",
                    "reject": "Deaktivieren"
                },
                "fields": {
                    "options": {
                        "input_label": "Berichtsoptionen",
                        "options": {
                            "d": "DKIM schlägt unabhängig vom Abgleich fehl",
                            "s": "SPF schlägt unabhängig vom Abgleich fehl",
                            "0": "SPF oder DKIM schlagen fehl und stimmen nicht überein",
                            "1": "SPF und DKIM schlagen fehl und stimmen nicht überein"
                        }
                    },
                    "status": {
                        "input_label": "Berichtsverarbeitung",
                        "options": {
                            "disabled": "Deaktiviert",
                            "enabled": "Aktiviert",
                            "specified": "Spezifische Empfänger"
                        }
                    }
                },
                "heading": "Fehlerberichte"
            },
            "title": "Einstellungen bearbeiten – DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC-Compliance-Zertifikat",
                "domain_report": "Domain-Bericht",
                "edit_settings": {
                    "tooltip": "Einstellungen bearbeiten"
                },
                "remove_primary": "Aus Favoriten entfernen",
                "set_as_active": "Als „Aktiv festlegen",
                "set_as_parked": "Als „Geparkt“ festlegen",
                "set_primary": "Als „Primär markieren",
                "sync_dns_records": "DNS-Einträge synchronisieren",
                "view_logs": "Protokolle anschauen"
            },
            "create_drawer": {
                "header": "Neue Domain hinzufügen"
            },
            "fields": {
                "name": {
                    "example": "z.B. „example.com“ oder „subdomain.example.com“",
                    "input_label": "Domainname"
                },
                "type": {
                    "input_label": "Domaintyp",
                    "options": {
                        "active": "Aktiv",
                        "parked": "Geparkt"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Richtlinie",
                        "score": "Score",
                        "status": "Status",
                        "type": "Typ"
                    }
                },
                "title": "Domains"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "park_modal": {
                "action": "Als geparkt festlegen",
                "description": "Geparkte Domains sind für Domains vorgesehen, von denen keine E-Mails gesendet werden. Das Festlegen der Domain als geparkt ändert die DMARC-Richtlinie auf Ablehnen und setzt den SPF-Mechanismusqualifizierer auf Alle fehlschlagen. Dadurch wird das Senden von E-Mails verhindert.",
                "header": "Bestätigen: Domain als geparkt festlegen"
            },
            "report_modal": {
                "action": "Link generieren",
                "fields": {
                    "copy_link": {
                        "input_label": "Kopieren Sie den Link unten, um den Domain-Bericht zu teilen"
                    },
                    "date_range": {
                        "input_label": "Datumsbereich"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einem Tag",
                            "1_month": "Einem Monat",
                            "1_week": "Einer Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Domain-Bericht"
            },
            "settings": {
                "title": "Einstellungen bearbeiten"
            },
            "setup_modal": {
                "action": "Link generieren",
                "fields": {
                    "copy_link": {
                        "input_label": "Link zu den Einrichtungsanweisungen"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einem Tag",
                            "1_month": "Einem Monat",
                            "1_week": "Einer Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Link zu den Setup-Anweisungen"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Keiner"
                    },
                    "quarantine": {
                        "value": "Quarantäne"
                    },
                    "reject": {
                        "value": "Ablehnen"
                    },
                    "tooltip": "Richtlinie angewendet"
                },
                "primary": {
                    "tooltip": "Primär",
                    "value": "Primär"
                },
                "score": {
                    "high": {
                        "tooltip": "Hohes Risiko"
                    },
                    "low": {
                        "tooltip": "Geringes Risiko"
                    },
                    "moderate": {
                        "tooltip": "Mittleres Risiko"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verifiziert, keine Meldung",
                        "tooltip_partial": "Teilweise verifiziert, keine Meldung",
                        "value": "Keine Meldung"
                    },
                    "not_verified": {
                        "tooltip": "Nicht verifiziert, keine Meldung",
                        "value": "Nicht verifiziert"
                    },
                    "reporting": {
                        "tooltip": "Verifiziert, Meldung",
                        "tooltip_partial": "Teilweise verifiziert, Meldung",
                        "value": "Meldung"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Aktive Domain",
                        "value": "Aktiv"
                    },
                    "parked": {
                        "tooltip": "Geparkte Domain",
                        "value": "Geparkt"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "z.B. empfänger@example.com",
                    "input_label": "E-Mail bearbeiten",
                    "placeholder": "E-Mail-Adresse eingeben",
                    "subscribed_label": "Abonniert",
                    "unsubscribed_label": "Abgemeldet",
                    "validation_message": "Gültige E-Mail-Adresse eingeben"
                }
            }
        },
        "errors": {
            "menu": "Fehler",
            "401": {
                "description": "Sie nicht dazu berechtigt, auf diese Seite zuzugreifen.",
                "title": "401: Nicht autorisiert"
            },
            "403": {
                "description": "Sie dürfen nicht auf diese Seite zugreifen.",
                "title": "403: Verboten"
            },
            "404": {
                "description": "Die von Ihnen gesuchte Seite konnte nicht gefunden werden.",
                "title": "404: Seite nicht gefunden"
            },
            "500": {
                "description": "Hoppla, auf unseren Servern ist ein Fehler aufgetreten.",
                "title": "500: Serverfehler"
            },
            "502": {
                "description": "Leider sind unsere Server ausgefallen.",
                "title": "502: Bad Gateway"
            },
            "503": {
                "description": "Es tut uns leid, wir führen Wartungsarbeiten durch. Bitte versuchen Sie es später erneut.",
                "title": "503: Dienst nicht verfügbar"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "E-Mail-Volumen",
                "legends": {
                    "failing": "DMARC schlägt fehl",
                    "forward": "Vorwärts",
                    "passing": "DMARC bestanden"
                }
            },
            "heading": "Domainbericht",
            "insights": {
                "exposure": {
                    "compliance": {
                        "configured": "Der Großteil Ihres E-Mail-Volumens ist DMARC-fähig",
                        "highly_configured": "Ein großer Prozentsatz Ihres E-Mail-Volumens ist nicht DMARC-fähig",
                        "semi_configured": "Der Großteil Ihres E-Mail-Volumens wird die DMARC-Authentifizierung nicht bestehen, was Sie daran hindert, Ihre Sicherheitsniveau zu erhöhen."
                    },
                    "compliance_type": {
                        "configured": "Der Großteil Ihres E-Mail-Volumens durchläuft beide E-Mail-Authentifizierungsmechanismen",
                        "dkim": "Der Großteil Ihres E-Mail-Volumens wird automatische Weiterleitungen mit DKIM bestehen.",
                        "one_mechanism": "Ein Großteil Ihres E-Mail-Volumens besteht nur eine Authentifizierungsmethode.",
                        "spf": "Der Großteil Ihres E-Mail-Volumens scheitert bei automatischen Weiterleitungen an DMARC"
                    },
                    "dmarc": {
                        "invalid_record": "Ihre DMARC-Richtlinie ist ungültig und kann nicht durchgesetzt werden, wodurch Ihre Domain für Missbrauch anfällig bleibt.",
                        "no_aggregate_report_addresses": "Sie haben keinen vollständigen Überblick über die von Ihrer Domain gesendeten E-Mails, was die Konfiguration erschwert",
                        "no_failure_report_addresses": "Sie erhalten keine Fehlerberichte, was es schwieriger macht, Absender zu identifizieren, die Ihre Domain missbrauchen.",
                        "no_record": "Ihre Domain kann keine DMARC-Richtlinie durchsetzen oder Berichte empfangen, da kein DMARC-Eintrag vorhanden ist, was Ihre Domain für Missbrauch anfällig macht.",
                        "percentage_not_full": "Ihre DMARC-Richtlinie wird nur auf einen Teil der E-Mails angewendet, und Sie erhalten möglicherweise nicht für das gesamte Volumen Berichte.",
                        "percentage_zero": "Ihre DMARC-Richtlinie wird auf keine E-Mails angewendet, und Sie erhalten möglicherweise keine Berichte über das gesamte Volumen.",
                        "policy_none": "Bei nicht authentifizierten E-Mails von Ihrer Domain werden keine Maßnahmen ergriffen, wodurch Ihre Domain einem Missbrauch ausgesetzt ist.",
                        "policy_quarantine": "Nicht authentifizierte E-Mails von Ihrer Domain werden nur unter Quarantäne gestellt.",
                        "sub_domain_policy_none": "Bei nicht authentifizierten E-Mails von Subdomains werden keine Maßnahmen ergriffen, sodass Ihre Domain anfällig für Missbrauch ist.",
                        "sub_domain_policy_quarantine": "Nicht authentifizierte E-Mails von Subdomains werden nur unter Quarantäne gestellt.",
                        "too_many_records": "Ihre Domain verfügt über zu viele DMARC-Richtlinien und diese werden ignoriert, sodass Ihre Domain anfällig für Missbrauch ist."
                    },
                    "sender_compliance": {
                        "configured": "Ihr Top-Absender ist DMARC-fähig.",
                        "fail_auto_forward": "Der Großteil der E-Mails von Ihrem Top-Absender wird bei automatischen Weiterleitungen die DMARC-Überprüfung nicht bestehen.",
                        "less_50": "Ihr Top-Versanddienst ist nicht korrekt konfiguriert.",
                        "less_80": "Viele E-Mails Ihres Top-Absenders sind nicht authentifiziert.",
                        "pass_auto_forward": "Der Großteil der E-Mails von Ihrem Top-Absender wird bei automatischen Weiterleitungen die DMARC-Überprüfung bestehen.",
                        "semi_configured": "Ihr Top-Absender sendet keine vollständig authentifizierten E-Mails."
                    },
                    "sender_exposure": {
                        "configured": "Alle Ihre Top-Absender gelten als konfiguriert.",
                        "less_50": "Die meisten Ihrer Top-Absender sind korrekt konfiguriert.",
                        "more_50": "Die meisten Ihrer Top-Absender sind nicht konfiguriert, sodass Ihre Domain anfällig für Missbrauch ist.",
                        "none": "Keiner Ihrer Top-Absender ist konfiguriert, sodass Ihre Domain anfällig für Missbrauch ist."
                    },
                    "spf": {
                        "all_missing": "Nicht autorisierte Absendeserver werden nur als verdächtig gekennzeichnet und nicht abgelehnt.",
                        "all_neutral": "Stellen Sie Ihren SPF-Eintrag auf „Hard Fail“ ein, wenn ein Absender nicht autorisiert ist.",
                        "all_pass": "Nicht autorisierte Absendeserver dürfen von Ihrer Domain aus senden.",
                        "all_soft_fail": "Es kann sein, dass nicht autorisierte Absendeserver von Ihrer Domain aus senden.",
                        "invalid_record": "Ihr SPF-Eintrag ist ungültig und kann nicht erzwungen werden, wodurch Ihre Domain anfällig für Missbrauch ist.",
                        "no_record": "Ihre Domain listet keine autorisierten Absendeserver auf, wodurch Ihre Domain anfällig für Missbrauch ist.",
                        "record_contains_errors": "Ihr SPF-Eintrag enthält Fehler und listet möglicherweise nicht alle autorisierten Absender auf.",
                        "recursive_lookup": "Ihr SPF-Eintrag enthält rekursive Einbindungen, wodurch er ungültig ist und Ihre Domain anfällig für Missbrauch ist.",
                        "too_many_dns_lookups": "Ihr SPF-Eintrag enthält zu viele DNS-Abfragen, wodurch er ungültig ist und Ihre Domain anfällig für Missbrauch ist.",
                        "too_many_mx_address_records": "Ihr SPF-Eintrag enthält zu viele MX-Einträge, wodurch er ungültig ist und Ihre Domain anfällig für Missbrauch ist.",
                        "too_many_records": "Ihre Domain hat zu viele SPF-Einträge, die ignoriert werden, wodurch Ihre Domain anfällig für Missbrauch ist.",
                        "too_many_void_lookups": "Ihr SPF-Eintrag enthält zu viele ungültige Abfragen, wodurch er ungültig wird und Ihre Domain anfällig für Missbrauch ist."
                    }
                },
                "recommendations": {
                    "compliance": {
                        "warning": "Erwägen Sie, Ihr DMARC-Sicherheitsniveau zu erhöhen."
                    },
                    "compliance_type": {
                        "warning": "Implementieren Sie die DKIM-Signierung auf Ihren sendenden Servern."
                    },
                    "dmarc": {
                        "invalid_record": "Korrigieren Sie die Fehler in Ihrem DMARC-Eintrag.",
                        "no_record": "Fügen Sie den DMARC-Eintrag für Ihre Domain zu Ihrem DNS hinzu.",
                        "no_rua": "Fügen Sie die Adressen der aggregierten Berichte des DMARC Managers zu Ihrem DMARC-Eintrag hinzu, um mit dem Empfang von Berichten zu beginnen.",
                        "no_ruf": "Fügen Sie die Adressen der Fehlerberichte des DMARC Managers zu Ihrem DMARC-Eintrag hinzu, um detaillierte Fehlerberichte zu erhalten.",
                        "percentage_not_full": "Setzen Sie den Richtlinienprozentsatz auf 100 %, sobald dies sicher möglich ist.",
                        "percentage_zero": "Erhöhen Sie den Richtlinienprozentsatz in Ihren DMARC-Einstellungen.",
                        "policy_quarantine": "Stellen Sie Ihre DMARC-Richtlinie so ein, dass sie abgelehnt wird, sobald dies sicher möglich ist.",
                        "sub_domain_policy_quarantine": "Setzen Sie die DMARC-Richtlinie für Ihre Subdomains auf 'reject', sobald dies sicher möglich ist.",
                        "too_many_records": "Entfernen Sie unnötige DMARC-Einträge. Sie sollten nur einen haben."
                    },
                    "senders": {
                        "configured": "Alle Ihre Top-Absender sind konfiguriert.",
                        "info": "Nutzen Sie die Berichterstellung, um sicherzustellen, dass alle legitimen Sendedienste korrekt autorisiert sind.",
                        "semi_configured": "Nutzen Sie die Berichterstellung, um zu ermitteln, welche Dienste noch konfiguriert werden müssen",
                        "zero_configured": "Alle sendenden Quellen erfordern eine Konfiguration. Implementieren Sie effektive Autorisierungsmechanismen für jede."
                    },
                    "spf": {
                        "all_missing": "Stellen Sie Ihren SPF-Eintrag auf „Hard Fail“ ein, wenn ein Absender nicht autorisiert ist.",
                        "all_neutral": "Stellen Sie Ihren SPF-Eintrag auf „Hard Fail“ ein, wenn ein Absender nicht autorisiert ist.",
                        "all_pass": "Stellen Sie Ihren SPF-Eintrag auf „Hard Fail“ ein, wenn ein Absender nicht autorisiert ist.",
                        "all_soft_fail": "Stellen Sie Ihren SPF-Eintrag auf „Hard Fail“ ein, wenn ein Absender nicht autorisiert ist.",
                        "contains_errors": "Korrigieren Sie die Fehler in Ihrem SPF-Eintrag.",
                        "high_lookup_count": "Verwenden Sie die SPF-Optimierung, um die Anzahl der DNS-Anbfragen zu reduzieren.",
                        "high_void_lookup_count": "Verwenden Sie die SPF-Optimierung, um die Anzahl fehlgeschlagener Abfragen zu reduzieren.",
                        "invalid_record": "Korrigieren Sie die Fehler in Ihrem SPF-Eintrag.",
                        "no_record": "Fügen Sie einen SPF-Eintrag für Ihre Domain hinzu und verwalten Sie ihn einfach in DMARC Manager",
                        "recursive_lookup": "Verwenden Sie die SPF-Verwaltung in DMARC Manager, um alle rekursiven Einbindungen zu entfernen.",
                        "too_many_dns_lookups": "Verwenden Sie die SPF-Optimierung in DMARC Manager, um die Anzahl der DNS-Abfragen zu reduzieren.",
                        "too_many_mx_records": "Verwenden Sie die SPF-Optimierung in DMARC Manager, um die Anzahl der Abfragen von Ressourceneinträgen in Ihrem SPF-Eintrag zu reduzieren.",
                        "too_many_records": "Entfernen Sie alle unnötigen SPF-Einträge. Sie sollten nur einen haben.",
                        "too_many_void_lookups": "Verwenden Sie die SPF-Optimierung in DMARC Manager, um die Anzahl unnötiger Abfragen zu reduzieren und alle ungültigen Einbindungen in Ihrem SPF-Eintrag zu entfernen."
                    }
                }
            },
            "recommendations": {
                "heading": "Empfehlungen"
            },
            "risk_analysis": {
                "heading": "Risikoanalyse"
            },
            "scoring": {
                "note": "Hinweis: Der Domain-Score wird zum Zeitpunkt der Erstellung des Berichts berechnet und ist zum Zeitpunkt der Anzeige möglicherweise nicht aktuell."
            },
            "sender_readiness": {
                "heading": "DMARC-Bereitschaft des Absenders",
                "legends": {
                    "configured": {
                        "description": "Die DMARC-Bereitschaft ist ein Maß für die Konfiguration, die zur Implementierung vollständiger Compliance erforderlich ist.",
                        "heading": "Konfiguriert"
                    },
                    "partially": {
                        "description": "Bei teilweise konfigurierten Quellen ist mindestens ein Autorisierungsmechanismus aktiv. Möglicherweise ist jedoch eine zusätzliche Einrichtung erforderlich.",
                        "heading": "Teilweise konfiguriert"
                    },
                    "unconfigured": {
                        "description": "Unzulässige oder nicht konfigurierte Quellen sind Quellen, die E-Mails von Ihrer Domain senden und möglicherweise Ihre Organisation imitieren.",
                        "heading": "Unzulässig oder nicht konfiguriert"
                    }
                }
            },
            "title": "Domainbericht für :domain",
            "top_countries": {
                "heading": "Top-Absenderländer – E-Mail-Volumen"
            },
            "top_senders": {
                "heading": "Top-Absender – Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Bericht herunterladen",
                "header": "Fehlerbericht",
                "menu": {
                    "key_properties": "Schlüsseleigenschaften",
                    "more_info": "Weitere Informationen"
                }
            },
            "properties": {
                "authentication_failure": "Authentifizierungsfehler",
                "date": "Datum",
                "delivery_results": "Lieferergebnisse",
                "dkim_domain": "DKIM-Domain",
                "dkim_header": "DKIM-kanonisierter Header",
                "dkim_identity": "DKIM-Identität",
                "dkim_selector": "DKIM-Selektor",
                "dmarc_version": "DMARC-Version",
                "feedback_type": "Feedback-Typ",
                "incidents": "Anzahl der Vorfälle",
                "original_recipients": "Ursprüngliche Empfänger",
                "reporting_domains": "Gemeldete Domains",
                "reporting_mta": "MTA melden",
                "reporting_user_agent": "Meldender Benutzeragent",
                "return_path": "Rückweg",
                "source_ip": "Ursprüngliche IP-Adresse",
                "spf_dns": "SPF-DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentifizierung",
                    "date": "Datum",
                    "ip_address": "IP-Adresse",
                    "result": "Ergebnis",
                    "return_path": "Return-Path"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Zugestellt",
                    "none": "Keine, akzeptieren",
                    "smg_policy_action": "Angewendete Richtlinie",
                    "spam": "Abgelehnt",
                    "unknown": "Unbekannt"
                }
            },
            "title": "Fehlerberichte für :domain"
        },
        "global": {
            "buttons": {
                "add": "Hinzufügen",
                "add_new": "Neu hinzufügen",
                "back": "Zurück",
                "cancel": "Abbrechen",
                "delete": "Löschen",
                "import": "Importieren",
                "next": "Nächste",
                "no": "Nein",
                "open_link": "Link öffnen",
                "remove": "Entfernen",
                "save": "Speichern",
                "save_exit": "Speichern und zurück",
                "select": "Auswählen",
                "yes": "Ja"
            },
            "confirm_delete": "Möchten Sie fortfahren?",
            "copied": "Kopiert",
            "created": "Erstellt",
            "deleted": "Gelöscht",
            "fail": "Fehler",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Deaktiviert",
                        "enabled": "Aktiviert"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Aus",
                        "on": "An"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "Nein",
                        "yes": "Ja"
                    }
                }
            },
            "form": {
                "dirty": {
                    "accept": "Änderungen verwerfen",
                    "header": "Sie haben ungespeicherte Änderungen",
                    "message": "Ihre Änderungen werden verworfen. Möchten Sie fortfahren?",
                    "reject": "Abbrechen"
                },
                "failed": {
                    "description": "Fehler: Bitte füllen Sie alle erforderlichen Felder korrekt aus.",
                    "title": "Es sind Fehler in Ihrem Formular aufgetreten."
                },
                "success": {
                    "title": "Ihre Änderungen wurden gespeichert."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Abmelden",
            "not_found": "Nicht gefunden",
            "pagination": {
                "of": "von",
                "showing": "Zeigt",
                "to": "Zu"
            },
            "pass": "Bestanden",
            "read_more": "Mehr",
            "remove": "Entfernen",
            "show_less": "Weniger anzeigen",
            "show_more": "Mehr anzeigen",
            "system": "System",
            "theme": {
                "dark": "Dunkel",
                "light": "Licht"
            },
            "to": "Zu"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Datumsbereich",
                "to": "An"
            },
            "menu": {
                "overrides": "Überschreibt",
                "overview": "Überblick",
                "reporters": "Reporter",
                "results": "Ergebnisse"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Angewendete Richtlinie „Keine“",
                    "policy_applied_quarantine": "Angewendete Richtlinie „Quarantäne“",
                    "policy_applied_reject": "Angewendete Richtlinie „Ablehnen“"
                },
                "overridden_policy_breakdown": "Aufschlüsselung der überschriebenen Richtlinie",
                "volume_overridden_policy": "Volumen mit überschriebenen Richtlinien"
            },
            "overview": {
                "cards": {
                    "compliance": "Compliance",
                    "deliverability": "Zustellbarkeit",
                    "volume": "Volumen"
                },
                "properties": {
                    "country": "Land",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Absender"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "envelope_from": "Envelope From",
                        "envelope_to": "Envelope To",
                        "header_from": "Header From",
                        "no_data": "Keine Reporterdaten verfügbar",
                        "percent_of_total": "% der Gesamtmenge",
                        "reporter": "Reporter",
                        "volume": "Volumen"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Übereinstimmung",
                    "error": "Fehler",
                    "fail": "Fehlgeschlagen",
                    "failed": "Fehlgeschlagen\/Keine Übereinstimmung",
                    "none": "Keiner",
                    "pass": "Bestanden",
                    "passed": "Bestanden\/Übereinstimmend"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Aktion angewendet",
                    "info": {
                        "tooltip": "Vom Empfänger ergriffene Maßnahmen"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Zugestellt",
                            "value": "Zugestellt"
                        },
                        "quarantine": {
                            "tooltip": "Unter Quarantäne gestellt",
                            "value": "Unter Quarantäne gestellt"
                        },
                        "reject": {
                            "tooltip": "Abgelehnt",
                            "value": "Abgelehnt"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Übereinstimmung",
                            "dmarc_via_dkim": "DMARC über DKIM",
                            "results": "Ergebnisse",
                            "selector": "Selektor",
                            "signing_domain": "Signierende Domain"
                        },
                        "heading": "DKIM-Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM nicht übereinstimmend",
                            "no_info": "Keine DKIM-Informationen",
                            "yes": "DKIM übereinstimmend"
                        },
                        "header": "DKIM-Ergebnisse",
                        "no_details": "Keine DKIM-Signaturdetails",
                        "pass_tooltips": {
                            "no": "DKIM fehlgeschlagen",
                            "none": "DKIM Keine",
                            "yes": "DKIM bestanden"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "Die Richtlinie :policy wurde vom Empfänger angewendet"
                        },
                        "header": {
                            "from_domain": "From Domain"
                        },
                        "heading": "Weitere Details"
                    },
                    "results": {
                        "header": "DMARC-Ergebnisse",
                        "pass_tooltips": {
                            "no": "DMARC fehlgeschlagen",
                            "yes": "DMARC bestanden"
                        }
                    }
                },
                "override_comment": {
                    "header": "Kommentar"
                },
                "override_type": {
                    "header": "Überschreibungstyp"
                },
                "published_policy": {
                    "header": "Veröffentlichte Richtlinie",
                    "info": {
                        "tooltip": "DMARC-Richtlinie zum Zeitpunkt der Validierung"
                    }
                },
                "reporter": {
                    "header": "Reporter"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Übereinstimmung",
                            "dmarc_via_spf": "DMARC über SPF",
                            "result": "Ergebnis",
                            "return_path_domain": "Rückweg-Domain"
                        },
                        "heading": "SPF-Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF nicht übereinstimmend",
                            "no_info": "Keine SPF-Informationen",
                            "yes": "SPF übereinstimmend"
                        },
                        "header": "SPF-Ergebnisse",
                        "no_details": "Keine SPF-Details",
                        "pass_tooltips": {
                            "error": "Temporärer SPF-Fehler",
                            "no": "SPF fehlgeschlagen",
                            "none": "SPF keiner",
                            "yes": "SPF bestanden"
                        }
                    }
                },
                "volume": {
                    "header": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "erstellt",
                "deleted": "gelöscht",
                "restored": "wiederhergestellt",
                "updated": "aktualisiert"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Zertifikat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "nameservers": "Nameserver",
                        "selector": "Selektor"
                    },
                    "title": "BIMI-Einstellungen :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Beschreibung",
                        "record_type": "Eintragstyp",
                        "selector": "Selektor",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Wert"
                    },
                    "title": "Öffentlicher DKIM-Schlüssel :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "is_policy_enabled": "Richtlinie aktiviert",
                        "nameservers": "Nameserver",
                        "policy": "Richtlinie"
                    },
                    "title": "DKIM-Einstellungen :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM-Abgleich",
                        "policy": "Richtlinie",
                        "policy_percentage": "Prozentsatz der Richtlinie",
                        "rua_emails": "Aggregierte Berichtsempfänger",
                        "rua_interval": "Aggregiertes Berichtsintervall",
                        "ruf_emails": "Empfänger von Fehlerberichten",
                        "ruf_format": "Format des Fehlerberichts",
                        "ruf_options": "Optionen für Fehlerberichte",
                        "ruf_status": "Status des Fehlerberichts",
                        "spf_alignment": "SPF-Abgleich",
                        "subdomain_policy": "Subdomain-Richtlinie"
                    },
                    "title": "DMARC-Einstellungen :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Nachrichten",
                        "result": "Ergebnis",
                        "started_at": "Begonnen am",
                        "status": "Status",
                        "type": "Typ"
                    },
                    "title": "DNS-Delegierungsverifizierung :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Primär",
                        "logo": "Logo",
                        "score": "Domain-Score",
                        "type": "Typ"
                    },
                    "title": "Domain :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Eintrag-Hosting aktiviert",
                        "mx_records": "MX-Einträge",
                        "policy": "Richtlinieneinstellung"
                    },
                    "title": "MTA-STS-Einstellungen :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Beschreibung",
                        "mechanism": "Eintragstyp",
                        "order": "Reihenfolge",
                        "qualifier": "Qualifikator des Eintrags",
                        "status": "Status",
                        "value": "Hostwert"
                    },
                    "title": "SPF-Anweisungen :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Qualifikator des Eintrags der Top-Level-Domain",
                        "enable_flattening": "SPF-Abflachung aktiviert"
                    },
                    "title": "SPF-Einstellungen :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT aktiviert",
                        "rua_emails": "E-Mails melden"
                    },
                    "title": "TLS-RPT-Einstellungen :event"
                }
            },
            "header": ":type Protokolle"
        },
        "menu": {
            "account": "Konto",
            "domains": "Domains",
            "home": "Dashboard",
            "reports": {
                "email_senders": "E-Mail-Absender",
                "failure_reports": "Fehlerberichte",
                "reports": "Berichte",
                "tls_rpt_reports": "TLS-RPT-Berichte"
            },
            "settings": {
                "alerts": "Benachrichtigungen",
                "logout": "Abmelden",
                "settings": "Einstellungen"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "MTA-STS-Hosting deaktiviert",
                "heading": "MTA-STS-Hosting ist aktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung und das MTA-STS-Richtlinienhosting zu aktivieren.",
                "modal": {
                    "header": "Deaktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie MTA-STS-Hosting",
                "heading": "MTA-STS-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, MTA-STS Record and Policy Hosting zu aktivieren, um Ihre Datensätze effektiv verwalten zu können.",
                "modal": {
                    "header": "Aktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Durch die Aktivierung von MTA-STS-Hosting können Sie Ihren Eintrag und das Hosting für Ihre Richtlinie verwalten."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX-Server"
                },
                "policy": {
                    "input_label": "Höhe der Richtliniendurchsetzung",
                    "options": {
                        "enforce": "Erzwungen",
                        "none": "Keine",
                        "testing": "Zum Testen"
                    }
                }
            },
            "heading": "MTA-STS-Eintrag und Richtlinien-Hosting",
            "info": {
                "heading": "Was ist TLS-RPT und MTA-STS?",
                "message": "TLS-RPT oder SMTP TLS Reporting (Transport Layer Security Reporting) ermöglicht es sendenden Systemen, Statistiken und Informationen über Fehler mit Empfängerdomains auszutauschen. MTA-STS (Mail Transfer Agents - Strict Transport Security) erlaubt es E-Mail-Dienstanbietern, festzulegen, dass eingehende SMTP-Verbindungen nur über TLS gesichert akzeptiert werden."
            },
            "status": {
                "policy": {
                    "pending": "Richtlinienüberprüfung ausstehend",
                    "verified": "Richtlinie bestätigt"
                },
                "record": {
                    "pending": "Eintragsüberprüfung ausstehend",
                    "verified": "Eintrag bestätigt"
                }
            }
        },
        "partners": {
            "title": "Partner verwalten"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "DMARC-Einstellungen konfigurieren",
                        "manage_domains": "Domains verwalten"
                    },
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis Daten angezeigt werden. Berichte können nur für aktive und verifizierte Domains abgerufen werden. Verwalten Sie Ihre Domains im Modul Domains.",
                    "header": "Keine Berichtsdaten verfügbar"
                },
                "no_reporting_data": {
                    "description": "Für den ausgewählten Zeitraum sind keine Berichtsdaten verfügbar. Bitte passen Sie Ihre Auswahl an und versuchen Sie es erneut.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            },
            "senders": {
                "unknown": "Unbekannt"
            }
        },
        "scoring": {
            "impersonation": {
                "blurb": {
                    "high": "Ihre Domain hat derzeit kaum oder keinen Schutz, was sie anfällig für die Nutzung durch Cyberkriminelle in E-Mail-basierten Angriffen macht. Dies könnte zu finanziellen Verlusten, vermindertem Vertrauen und Rufschädigung führen.",
                    "low": "Ihr Unternehmen hat ein sehr geringes Risiko, dass Cyberkriminelle Ihre Domain für das Versenden gefälschter E-Mails nutzen. Domains mit einer Bewertung von 5 sind zu 100% DMARC-konform und vor Nachahmungs- und Phishing-Angriffen geschützt.",
                    "moderate": "Es ist noch eine Änderung erforderlich, um Ihre Domain vollständig zu schützen. Erfahrene Cyberkriminelle könnten immer noch Ihr Unternehmen und Ihre Stakeholder nachahmen. Die Zustellbarkeit legitimer E-Mails könnte beeinträchtigt werden, und einige Nachrichten könnten im Spam- oder Junk-Ordner landen."
                },
                "header": "Nachahmung"
            },
            "marketing": {
                "blurb": {
                    "high": "Derzeit wird Ihr Branding nicht mit Ihren E-Mails angezeigt. Branding schafft Vertrauen und versichert den Empfängern, dass eine E-Mail authentisch ist. Wir empfehlen eine vollständige BIMI-Implementierung, um Erkennung, Sichtbarkeit und Vertrauen zu steigern.",
                    "low": "Ihr Unternehmen nutzt E-Mail-Branding effektiv, wodurch Ihre E-Mails vertrauenswürdiger werden. Ihr Logo und ein verifiziertes Zeichen (falls zutreffend) werden für Empfänger in E-Mail-Clients sichtbar sein, die BIMI unterstützen.",
                    "moderate": "Ihre E-Mail-Branding-Einstellungen benötigen einige Anpassungen. Ihr Logo und ein verifiziertes Zeichen (falls zutreffend) werden möglicherweise in einigen E-Mail-Clients angezeigt, die meisten erfordern jedoch eine vollständige BIMI-Implementierung, um das Branding darzustellen."
                },
                "header": "Marketing"
            },
            "note": "HINWEIS: Dies ist Ihre aktuelle Domain-Bewertung und spiegelt möglicherweise keine Änderungen wider, die Sie heute vorgenommen haben.",
            "overall": {
                "blurb": {
                    "high": "Sie haben keine effektiven Kontrollen, um Ihre Domain vor Nachahmung und dem Abfangen von E-Mail-Kommunikation zu schützen. Dies setzt Ihre Marke und die Empfänger Ihrer E-Mails einem Risiko für Angriffe aus, mindert Vertrauen und kann die E-Mail-Zustellbarkeit beeinträchtigen.",
                    "low": "Sie haben Maßnahmen ergriffen, um Empfänger vor bösartigen E-Mails von Ihrer Domain zu schützen. Ein besonderes Augenmerk sollte auf die Bereiche gelegt werden, in denen die Kontrollen verbessert werden können. Diese Maßnahmen stärken das Vertrauen in Ihre Marke, schützen die Empfänger vor Angriffen und tragen dazu bei, dass E-Mails effektiv zugestellt werden.",
                    "moderate": "Sie haben einige Maßnahmen implementiert, um Empfänger vor bösartigen E-Mails von Ihrer Domain zu schützen. Dennoch sollte auf Bereiche geachtet werden, in denen Kontrollen verstärkt werden können. Diese Maßnahmen helfen, Vertrauen in Ihr Unternehmen aufzubauen, Empfänger vor Angriffen zu schützen und die effektive Zustellung von E-Mails sicherzustellen."
                },
                "header": "Domain-Bewertung"
            },
            "privacy": {
                "blurb": {
                    "high": "Ihre Domain hat minimale bis keine Abwehrmaßnahmen für die E-Mail-Privatsphäre. Es besteht ein hohes Risiko, dass Ihre Kommunikation abgefangen oder von unbefugten Nutzern kompromittiert wird. Obwohl die meisten Absender ihre Kommunikation standardmäßig verschlüsseln, ist es entscheidend, Richtlinien für diejenigen durchzusetzen, die dies nicht tun.",
                    "low": "Ihre E-Mails haben ein geringes Risiko, von Cyberkriminellen eingesehen oder abgefangen zu werden. Ihre Domain hat starke Verschlüsselungsstandards, und Sie erhalten Berichte darüber, wie gut diese eingehalten werden.",
                    "moderate": "Sie müssen Ihre E-Mail-Verschlüsselung verschärfen. Wenn diese nicht sicher ist, könnten Ihre E-Mails abgefangen werden."
                },
                "header": "Privatsphäre"
            },
            "rating": {
                "high": "Hohes Risiko",
                "low": "Geringes Risiko",
                "moderate": "Mäßiges Risiko"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Anzahl blockiert",
                "compliance": "Compliance",
                "deliverability": "Zustellbarkeit",
                "deliverable": "Zustellbar",
                "deliverable_volume": "Anzahl zugestellt",
                "ip_addresses": "IP-Adressen",
                "senders": "Absender",
                "volume": "E-Mail-Anzahl"
            },
            "categories": {
                "all": "Alle Absender",
                "authorised_compliant": "Genehmigt",
                "forwarder": "Weiterleiter",
                "suspicious": "Verdächtig",
                "unauthorised": "Nicht genehmigt",
                "uncategorized": {
                    "message": "Quelle wird analysiert...",
                    "tooltip": "Absender nicht identifiziert"
                },
                "unknown": "Keine"
            },
            "menu": {
                "compliance": "Compliance",
                "deliverability": "Zustellbarkeit",
                "reputation": "Reputation",
                "threats": "Bedrohungen"
            },
            "messages": {
                "category_updated": {
                    "error": "Die Absenderkategorie konnte nicht aktualisiert werden",
                    "success": "Absenderkategorie aktualisiert"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Auf der Blacklist",
                    "clean": "Gültig",
                    "compliant": "Konform",
                    "delivered": "Zugestellt",
                    "failing_dmarc": "DMARC-prüfung fehlgeschlagen",
                    "forward": "Weiterleitungen",
                    "not_delivered": "Nicht zugestellt",
                    "overridden": "Überschrieben",
                    "overridden_as_delivered": "Als zugestellt überschrieben",
                    "overridden_as_not_delivered": "Als nicht zugestellt überschrieben",
                    "passing": "Bestanden",
                    "passing_dkim": "Nur DKIM bestanden",
                    "passing_dkim_spf": "DKIM und SPF bestanden",
                    "passing_dmarc": "DMARC bestanden",
                    "passing_spf": "Nur SPF bestanden",
                    "senders": "Absender"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Genehmigte Absender-IPs",
                        "approved_sender_volume": "Genehmigtes Absendervolumen",
                        "blacklisted": "Auf der Blacklist",
                        "blacklists": "Blacklists",
                        "blocked_volume": "Anzahl blockiert",
                        "category": {
                            "header": "Kategorie",
                            "tooltip": "Jeden Absender kategorisieren, um Berichterstattungsdaten zu sortieren."
                        },
                        "clean": "Gültig",
                        "compliance": "Compliance",
                        "country": "Land",
                        "deliverability": "Zuzstellbarkeit",
                        "delivered": "Zugestellt",
                        "failing": "Fehlgeschlagen",
                        "forwards": "Weiterleitungen",
                        "host": "Host",
                        "ip": "IP",
                        "ip_addresses": "IP-Adressen",
                        "not_delivered": "Nicht zugestellt",
                        "passing": "Bestanden",
                        "sender": "Absender",
                        "top_blacklisted_senders": "Top-Absender auf der Blacklist",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Anzahl der Blacklists, in denen die IP-Adresse aufgeführt ist"
                    }
                }
            },
            "title": "E-Mail-Absender für :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Neue SPF-Anweisung hinzufügen",
                "edit_record": {
                    "tooltip": "Datensatz bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neue SPF-Anweisung hinzufügen"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Anweisungen",
                        "order": "Reihenfolge"
                    }
                },
                "titles": {
                    "allow_a_none": "A-Einträge für Ihre eigene Domain erlauben",
                    "allow_a_value": "A-Einträge erlauben für :value",
                    "allow_exists": "Das Existenz-Makro :value erlauben",
                    "allow_include": ":value einschließen",
                    "allow_ip4_address": "Die IPv4-Adresse :value einschließen",
                    "allow_ip4_addresses": "Die IPv4-Adressen in :value einschließen",
                    "allow_ip6_address": "Die IPv6-Adresse :value einschließen",
                    "allow_ip6_addresses": "Die IPv6-Adressen in :value einschließen",
                    "allow_mx_none": "MX-Einträge für Ihre eigene Domain erlauben",
                    "allow_mx_value": "MX-Einträge erlauben für :value",
                    "fail_a_none": "Fehler bei A-Einträgen für Ihre eigene Domain",
                    "fail_a_value": "Fehler bei A-Einträgen für :value",
                    "fail_exists": "Fehler im Existenz-Makro :value",
                    "fail_include": "Fehler beim Einschließen von :value",
                    "fail_ip4_address": "Fehler bei der IPv4-Adresse :value",
                    "fail_ip4_addresses": "Fehler bei den IPv4-Adressen in :value",
                    "fail_ip6_address": "Fehler bei der IPv6-Adresse :value",
                    "fail_ip6_addresses": "Fehler bei den IPv6-Adressen in :value",
                    "fail_mx_none": "Fehler bei MX-Einträgen für Ihre eigene Domain",
                    "fail_mx_value": "Fehler bei MX-Einträgen für :value",
                    "neutral_a_none": "Neutral bei A-Einträgen für Ihre eigene Domain",
                    "neutral_a_value": "Neutral bei A-Einträgen für :value",
                    "neutral_exists": "Neutral im Existenz-Makro :value",
                    "neutral_include": "Neutral beim Einschließen von :value",
                    "neutral_ip4_address": "Neutral bei der IPv4-Adresse :value",
                    "neutral_ip4_addresses": "Neutral bei den IPv4-Adressen in :value",
                    "neutral_ip6_address": "Neutral bei der IPv6-Adresse :value",
                    "neutral_ip6_addresses": "Neutral bei den IPv6-Adressen in :value",
                    "neutral_mx_none": "Neutral bei MX-Einträgen für Ihre eigene Domain",
                    "neutral_mx_value": "Neutral bei MX-Einträgen für :value",
                    "soft_fail_a_none": "Leichter Fehler bei A-Einträgen für Ihre eigene Domain",
                    "soft_fail_a_value": "Leichter Fehler bei A-Einträgen für :value",
                    "soft_fail_exists": "Leichter Fehler im Existenz-Makro :value",
                    "soft_fail_include": "Leichter Fehler beim Einschließen von :value",
                    "soft_fail_ip4_address": "Leichter Fehler bei der IPv4-Adresse :value",
                    "soft_fail_ip4_addresses": "Leichter Fehler bei den IPv4-Adressen in :value",
                    "soft_fail_ip6_address": "Leichter Fehler bei der IPv6-Adresse :value",
                    "soft_fail_ip6_addresses": "Leichter Fehler bei den IPv6-Adressen in :value",
                    "soft_fail_mx_none": "Leichter Fehler bei MX-Einträgen für Ihre eigene Domain",
                    "soft_fail_mx_value": "Leichter Fehler bei MX-Einträgen für :value"
                }
            },
            "edit_drawer": {
                "header": "SPF-Anweisung bearbeiten"
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "host": {
                    "example": "z.B. _spf.example.com",
                    "input_label": ":type Wert"
                },
                "mechanism": {
                    "input_label": "Eintragstyp"
                },
                "qualifier": {
                    "input_label": "Qualifikator des Eintrags",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (nicht empfohlen)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "SPF-Anweisung aktualisieren",
                "use_own_domain": {
                    "input_label": "Mechanismus-Domain"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "SPF-Import abgeschlossen"
                },
                "header": "Vorhandene SPF-Einträge und -Anweisungen für :domain importieren",
                "message": "Alle vorhandenen Anweisungen werden entfernt und durch importierte Anweisungen ersetzt."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Qualifikator des Eintrags der Top-Level-Domain",
                        "options": {
                            "allow": "+ Pass (nicht empfohlen)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (nicht empfohlen)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+all ist so eingestellt, dass es die Authentifizierung von E-Mails von Absendern besteht, die nicht autorisiert sind.",
                            "-": "Fail ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern fehlschlägt.",
                            "?": "Neutral ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern weder bestanden noch fehlgeschlagen wird.",
                            "~": "Soft Fail ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern scheitert und sie als verdächtig behandelt werden."
                        }
                    },
                    "flattening": {
                        "input_label": "SPF Flattening",
                        "tooltip": "SPF-Flattening behebt DNS-Abfragebeschränkungen, indem es Include-, MX- und A-Direktiven in IP-Adressen umwandelt und so einen einzigen erweiterten Eintrag erstellt, um Einschränkungen zu umgehen."
                    }
                },
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – SPF"
        },
        "tables": {
            "clear": "Leeren",
            "not_found": "Keine passenden Einträge gefunden",
            "search": "Suchen"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "TLS-RPT-Einstellungen konfigurieren",
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis die Daten erscheinen. TLS-RPT-Berichte können nur für Domains abgerufen werden, bei denen TLS-RPT-Berichterstattung aktiviert ist.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            },
            "heading": "TLS-RPT-Berichte für :domain",
            "reporters": {
                "heading": "Berichte",
                "legend": {
                    "reports": "Berichte"
                },
                "table": {
                    "header": {
                        "policy_mode": "Richtlinieneinstellung",
                        "policy_type": "Richtlinientyp",
                        "reporter": "Berichtersteller",
                        "total": "Gesamt"
                    }
                }
            },
            "timeline": {
                "heading": "Zeitübersicht",
                "legend": {
                    "failure": "Fehlgeschlagen",
                    "success": "Erfolgreich"
                }
            },
            "title": "TLS-RPT-Berichte"
        },
        "tls_settings": {
            "disable": {
                "action": "TLS-RPT-Berichterstellung deaktivieren",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "TLS-RPT-Berichterstellung für :domain deaktiviert",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie die TLS-RPT-Berichterstellung",
                "heading": "Die TLS-RPT-Berichterstellung ist deaktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "Aktivieren Sie die TLS-RPT-Berichterstellung für :domain",
                    "message": "Durch das Aktivieren der TLS-RPT-Berichterstattung können Sie Statistiken und Informationen über Fehler mit Empfängerdomains überwachen."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Berichte werden gesendet an"
                }
            },
            "heading": "TLS-RPT-Berichterstellung",
            "title": "Einstellungen bearbeiten – TLS-RPT"
        },
        "upload": {
            "add": "Dateien hinzufügen",
            "drop_zone": "Ziehen Sie die Datei hierher, um sie hochzuladen.",
            "title": "Hochladen"
        },
        "users": {
            "email": {
                "edit": "E-Mail bearbeiten",
                "placeholder": "Geben Sie eine E-Mail-Adresse ein"
            },
            "menu": "Benutzer",
            "name": {
                "title": "Name"
            },
            "title": "Benutzer verwalten"
        },
        "validation": {
            "email": "Keine gültige E-Mail-Adresse",
            "invalid_attr": ":attribute ist ungültig."
        }
    },
    "en": {
        "alerts": {
            "actions": {
                "create_activate": "Create & Activate",
                "edit_alert": {
                    "tooltip": "Edit Alert"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Mail Senders",
                    "none": "None",
                    "reports": "Reports",
                    "status": "Status"
                },
                "description": "Select the activity you would like to monitor.",
                "heading": "Activity",
                "options": {
                    "customer-created": {
                        "description": "Notify me when an account is created.",
                        "heading": "Customer Created"
                    },
                    "customer-deleted": {
                        "description": "Notify me when an account is deleted.",
                        "heading": "Customer Deleted"
                    },
                    "customer-exposure-report": {
                        "description": "Schedule when you would like to receive the Exposure Report.",
                        "heading": "Exposure Report"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Schedule DMARC Compliance Certificate when DMARC Policy is set to Reject.",
                        "heading": "DMARC Compliance Certificate On Reject"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notify me when DNS delegation re-verification fails.",
                        "heading": "Setup Re-Verification Failed"
                    },
                    "dns-delegation-verified": {
                        "description": "Notify where the verification status has changed",
                        "heading": "Verification Status Changed"
                    },
                    "domain-compliance-drop": {
                        "description": "Notify me when compliance drops below a threshold.",
                        "heading": "Compliance Dropped"
                    },
                    "domain-created": {
                        "description": "Notify me when a new domain is added.",
                        "heading": "Domain Created"
                    },
                    "domain-deleted": {
                        "description": "Notify me when a domain is deleted.",
                        "heading": "Domain Deleted"
                    },
                    "domain-score-change": {
                        "description": "Notify me when the DMARC Compliance Score has changed.",
                        "heading": "DMARC Compliance Score Changed"
                    },
                    "domain-settings-change": {
                        "description": "Notify me when settings are changed.",
                        "heading": "Changes to Settings"
                    },
                    "sender-threat-level-change": {
                        "description": "Notify me when Threat Level increase for new or existing senders.",
                        "heading": "Threat Level Increased"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notify me when a new, commonly approved sender is detected.",
                        "heading": "Approved Sender Found"
                    }
                }
            },
            "create": {
                "title": "Create Alert"
            },
            "edit": {
                "title": "Edit Alert"
            },
            "email": {
                "description": "Specify the email recipient you would like to notify.",
                "heading": "Email"
            },
            "email_settings": {
                "heading": "Customize Email"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Customer",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequency"
                },
                "recipients": {
                    "input_label": "Recipients"
                },
                "scope": {
                    "input_label": "Level of Monitoring",
                    "listing-title": ":scope Activity",
                    "options": {
                        "customer": "Customer",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup Verification",
                    "input_label": "Select Below"
                },
                "start_date": {
                    "input_label": "Start Date"
                },
                "status": {
                    "input_label": "Status"
                },
                "subject_tags": {
                    "example": "e.g. [alert-severity][account][package][domain]",
                    "input_label": "Enable subject line categorization prefixes"
                }
            },
            "frequency": {
                "description": "Choose how often you would like to receive this notification.",
                "disabled-text": "Not Available - This is an event-based activity",
                "heading": "Frequency",
                "options": {
                    "monthly": "Monthly",
                    "quarterly": "Quarterly",
                    "six_monthly": "Six Monthly",
                    "two_monthly": "Two Monthly",
                    "yearly": "Yearly"
                },
                "starting": "Starting"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alert",
                        "frequency": "Frequency",
                        "recipients": "Recipients",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Activity"
                    }
                },
                "title": "Alerts"
            },
            "monitor": {
                "description": "Select the activity you would like to monitor.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Daily",
                        "fortnightly": "Fortnightly",
                        "hourly": "Hourly",
                        "immediate": "Immediate",
                        "monthly": "Monthly",
                        "quarterly": "Quarterly",
                        "six_monthly": "Six monthly",
                        "two_monthly": "Two monthly",
                        "weekly": "Weekly",
                        "yearly": "Yearly"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Alert is inactive",
                        "value": "off"
                    },
                    "on": {
                        "tooltip": "Alert is active",
                        "value": "on"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Disable BIMI Record Hosting",
                "heading": "BIMI Record Hosting is currently enabled",
                "message": "It is recommended that BIMI Hosting is enabled to easily manage your logo and certificate.",
                "modal": {
                    "header": "Disable BIMI Record Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable BIMI Record Hosting",
                "heading": "BIMI Hosting is Disabled",
                "message": "It is recommended that BIMI Hosting is enabled to manage your Logo and Certificate.",
                "modal": {
                    "header": "Enable BIMI Record Hosting :domain",
                    "message": "To use BIMI, you need a Verified Mark Certificate (VMC) or Common Mark Certificate (CMC) for your domain issued from a third-party certificate authority."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificate CN",
                    "expiry": "Certificate Expiry",
                    "input_label": "BIMI Verified Mark Certificate (File type: pem)",
                    "url": "Authority \/ Certificate URL"
                },
                "image": {
                    "input_label": "BIMI Logo (File type: svg)",
                    "preview": "Logo Preview",
                    "size": "Logo size",
                    "url": "Logo Url"
                }
            },
            "info": {
                "heading": "What is BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) is a standard that makes it easier to get your logo displayed next to your messages in the inbox, prevent fraudulent emails, and aide deliverability. BIMI works alongside DMARC, DKIM, and SPF to verify an email's authenticity and help with brand visibility."
            },
            "settings": {
                "heading": "Settings"
            },
            "title": "Edit Settings - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Back to Settings",
                "share": "Share Setup Instructions",
                "verify": "Verify",
                "view": "View Setup Instructions"
            },
            "bimi": {
                "description": "Update your DNS to allow the configuration of your domain’s BIMI Public Keys.",
                "modal": {
                    "not_configured": {
                        "header": "Setup Instruction Not Available",
                        "message": "In order to generate DNS records for your BIMI configuration you’ll need to upload a logo."
                    }
                }
            },
            "buttons": {
                "raw_record": "Use TXT Record Instead"
            },
            "description": "To enable monitoring and management through DMARC Manager please implement the following changes in your domain’s DNS settings.",
            "dkim": {
                "description": "Add the entries below to your domain`s DNS records to allow the configuration of its DKIM public keys.",
                "no_nameservers": "The DKIM public key zone is being created, please wait for the setup instructions..."
            },
            "dmarc": {
                "description": "Update your DNS to allow the configuration of your domain’s DMARC policy and to capture reports on email authentication activity through our dashboard.",
                "warning": "Take note of the required underscores (_) in the host and full-stops in the record value."
            },
            "existing_dns_record_found": "Existing :record Record Found",
            "heading": ":title Setup Instructions",
            "instructions": {
                "add": "Add :rdType Record",
                "heading": ":rdType Setup Instructions",
                "new": "Step 2: Add New :rdType Record",
                "remove": "Step 1: Remove Existing :rdType Record"
            },
            "mta_policy": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Policy.",
                "heading": "MTA-STS Policy"
            },
            "mta_record": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Record.",
                "heading": "MTA-STS Record"
            },
            "record": {
                "host": "Host",
                "type": "Record Type",
                "value": "Value"
            },
            "spf": {
                "description": "Update your DNS to allow for the configuration of your domain’s SPF record through our platform. Our platform ensures the validity of the record and makes use of optimization technology which mitigates the risk of reaching DNS lookup limits as well as ensuring that your policy is not overly permissive."
            },
            "status": {
                "last_date": "Last Verified on",
                "pending": "Pending Verification",
                "verified": "Verified"
            },
            "tls_rpt": {
                "description": "Update your DNS to allow the configuration of your domain’s TLS-RPT record and to capture reports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Manage customers"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Email volume",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved & other"
                    }
                },
                "domain_totals": {
                    "action": "View domains",
                    "enforced": "Enforced",
                    "heading": "Domains",
                    "reporting": "Reporting",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "View Senders",
                    "heading": "Senders",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "View All Domains"
                },
                "legends": {
                    "failing": "Failing DMARC",
                    "forwards": "Forwards",
                    "passing": "Passing DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "View Report"
                    },
                    "header": {
                        "approved": "Approved",
                        "domain": "Domains",
                        "senders": "Senders",
                        "unapproved": "Unapproved",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "View Domain Report"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "domain": "Domain",
                        "sender": "Approved Senders by Volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Dashboard"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "No matching name servers could be found.",
                        "mismatch": "The nameserver records do not match the expected configuration.",
                        "missing": "At least two nameserver records need to be present."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "The DKIM alignment mode does not match the configuration.",
                        "missing": "The DKIM alignment mode is missing but is not configured to the default."
                    },
                    "aspf": {
                        "mismatch": "The SPF alignment mode does not match the configuration.",
                        "missing": "The SPF alignment mode is missing but is not configured to the default."
                    },
                    "cname": {
                        "mismatch": "The CNAME record has not been added correctly and the record doesn't match the expected configuration.",
                        "partial": "The CNAME record has not been added correctly. This can cause configurations to conflict."
                    },
                    "fo": {
                        "mismatch": "The failure reporting options do not match the configuration.",
                        "missing": "The failure reporting options are missing but are not configured to the default."
                    },
                    "p": {
                        "mismatch": "The policy does not match the configuration.",
                        "missing": "The policy is missing."
                    },
                    "pct": {
                        "mismatch": "The policy percentage does not match the configuration.",
                        "missing": "The policy percentage is missing but is not configured to the default."
                    },
                    "rf": {
                        "mismatch": "The failure reporting format does not match the configuration."
                    },
                    "ri": {
                        "mismatch": "The aggregate report interval does not match the configuration.",
                        "missing": "The aggregate report interval is missing but is not configured to the default."
                    },
                    "rua": {
                        "mismatch": "The aggregate report recipients do not match the configuration.",
                        "order": "The aggregate report recipients are not in the correct order."
                    },
                    "ruf": {
                        "mismatch": "The failure report recipients do not match the configuration.",
                        "order": "The failure report recipients are not in the correct order."
                    },
                    "sp": {
                        "mismatch": "The subdomain policy does not match the configuration.",
                        "missing": "The subdomain policy is missing but is configured differently to the domain policy."
                    },
                    "txt": {
                        "reporting-only": "The record doesn't match the expected configuration, but our reporting address is present."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "The TXT value does not match the expected value."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    },
                    "rua": {
                        "missing": "The expected reporting address is missing."
                    }
                }
            },
            "verify": {
                "error": "Verification Failed",
                "nxdomain": "Record Not Found.",
                "success": "Verification Completed Successfully",
                "timeout": "Verification Timeout"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Add New Public Key",
                "edit_public_key": {
                    "tooltip": "Edit Public Key"
                }
            },
            "create_drawer": {
                "header": "Add New Public Key"
            },
            "disable": {
                "action": "Disabled DKIM Management",
                "heading": "DKIM Public Key Hosting is Currently Enabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Disable DKIM Management for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "edit_drawer": {
                "header": "Edit Public Key"
            },
            "enable": {
                "action": "Enable DKIM Management",
                "heading": "DKIM Public Key Hosting is Disabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Enable DKIM Management for :domain",
                    "message": "By enabling DKIM management you can manage your public keys with ease."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Record Type"
                },
                "record_value": {
                    "example": "e.g. dkim.example.com",
                    "input_label": "Record Value"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "DKIM Public Key Import Completed"
                },
                "disabled_tooltip": "DKIM public keys can only be imported for domains that have reports.",
                "header": "Re-import DKIM Public Keys for :domain",
                "message": "All existing public keys will be removed and replaced with imported directives."
            },
            "info": {
                "heading": "What is DKIM?",
                "message": "Domain Keys Identified Mail is an email authentication method that allows a sender to digitally sign an email using a private key associated with their domain name, which can be verified by the recipient's mail server using a public key published in the sender's DNS records. This helps to verify the authenticity and integrity of the email, reducing the risk of spam and phishing attacks."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Outbound Signing Policy",
                        "options": {
                            "all": "All Emails",
                            "some": "Some Emails"
                        }
                    },
                    "testing": {
                        "input_label": "Testing Mode"
                    }
                },
                "heading": "Policy Settings"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "value": "Value"
                    }
                }
            },
            "title": "Edit Settings - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM Alignment mode"
                    },
                    "options": {
                        "r": "Relaxed",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "SPF Alignment mode"
                    }
                },
                "heading": "Alignment Settings"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Apply Different Subdomain Policy"
                    },
                    "percentage": {
                        "input_label": "Percentage"
                    },
                    "policy": {
                        "input_label": "DMARC Policy"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain Policy"
                    }
                },
                "heading": "Policy Settings"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Reports Will be Sent to"
                    },
                    "interval": {
                        "input_label": "Report Interval",
                        "options": {
                            "1_day": "Once a Day",
                            "1_hour": "Every Hour",
                            "2_hours": "Every 2 Hours",
                            "4_hours": "Every 4 Hours",
                            "6_hours": "Every 6 Hours"
                        }
                    }
                },
                "heading": "Aggregate Reports"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Enable",
                    "header": "Enable failure reporting",
                    "message": "Failure Reports will give you further insights into the emails that failed DMARC. Enabling this feature allows us to process and display the report data which may contain personal information such as email addresses or message content.",
                    "message_disclaimer": "If your organization's legal policies prohibits you from enabling this type of report, rather process these reports manually and specifying the recipient for the reports.",
                    "reject": "Disable"
                },
                "fields": {
                    "options": {
                        "input_label": "Report Options",
                        "options": {
                            "d": "DKIM fails regardless of alignment",
                            "s": "SPF fails regardless of alignment",
                            "0": "SPF or DKIM fail and don't align",
                            "1": "SPF and DKIM fail and don't align"
                        }
                    },
                    "status": {
                        "input_label": "Report Processing",
                        "options": {
                            "disabled": "Disabled",
                            "enabled": "Enabled",
                            "specified": "Specific Recipients"
                        }
                    }
                },
                "heading": "Failure Reports"
            },
            "title": "Edit Settings - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC Compliance Certificate",
                "domain_report": "Domain Report",
                "edit_settings": {
                    "tooltip": "Edit Settings"
                },
                "remove_primary": "Remove from Favourite",
                "set_as_active": "Set as Active",
                "set_as_parked": "Set as Parked",
                "set_primary": "Mark as Primary",
                "sync_dns_records": "Sync DNS Records",
                "view_logs": "View Logs"
            },
            "create_drawer": {
                "header": "Add New Domain"
            },
            "fields": {
                "name": {
                    "example": "e.g. 'example.com' or 'subdomain.example.com'",
                    "input_label": "Domain Name"
                },
                "type": {
                    "input_label": "Domain Type",
                    "options": {
                        "active": "Active",
                        "parked": "Parked"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Policy",
                        "score": "Score",
                        "status": "Status",
                        "type": "Type"
                    }
                },
                "title": "Domains"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "park_modal": {
                "action": "Set as Parked",
                "description": "Parked domains are for non-mail-sending domains. Setting the domain as parked will change the DMARC policy to reject as well as set the SPF Mechanism Qualifier to Fail all, this will prevent mail from being sent.",
                "header": "Confirm Domain as Parked"
            },
            "report_modal": {
                "action": "Generate Link",
                "fields": {
                    "copy_link": {
                        "input_label": "Copy the link below to share domain report"
                    },
                    "date_range": {
                        "input_label": "Date Range"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Domain Report"
            },
            "settings": {
                "title": "Edit Settings"
            },
            "setup_modal": {
                "action": "Generate Link",
                "fields": {
                    "copy_link": {
                        "input_label": "Copy the link below to share setup instructions"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Link to Setup Instructions"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "None"
                    },
                    "quarantine": {
                        "value": "Quarantine"
                    },
                    "reject": {
                        "value": "Reject"
                    },
                    "tooltip": "Policy Applied"
                },
                "primary": {
                    "tooltip": "Primary",
                    "value": "Primary"
                },
                "score": {
                    "high": {
                        "tooltip": "High Risk"
                    },
                    "low": {
                        "tooltip": "Low Risk"
                    },
                    "moderate": {
                        "tooltip": "Moderate Risk"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verified, Not Reporting",
                        "tooltip_partial": "Partially Verified, Not Reporting",
                        "value": "Not Reporting"
                    },
                    "not_verified": {
                        "tooltip": "Not Verified, Not Reporting",
                        "value": "Not Verified"
                    },
                    "reporting": {
                        "tooltip": "Verified, Reporting",
                        "tooltip_partial": "Partially Verified, Reporting",
                        "value": "Reporting"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Active Domain",
                        "value": "Active"
                    },
                    "parked": {
                        "tooltip": "Parked Domain",
                        "value": "Parked"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "e.g. recipient@example.com",
                    "input_label": "Edit Email",
                    "placeholder": "Enter Email Address",
                    "subscribed_label": "Subscribed",
                    "unsubscribed_label": "Unsubscribed",
                    "validation_message": "Enter Valid Email Address"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Sorry, you are unauthorized to access this page.",
                "title": "401: Unauthorized"
            },
            "403": {
                "description": "Sorry, you are forbidden from accessing this page.",
                "title": "403: Forbidden"
            },
            "404": {
                "description": "Sorry, the page you are looking for could not be found.",
                "title": "404: Page Not Found"
            },
            "500": {
                "description": "Whoops, something went wrong on our servers.",
                "title": "500: Server Error"
            },
            "502": {
                "description": "Sorry, our servers are down.",
                "title": "502: Bad Gateway"
            },
            "503": {
                "description": "Sorry, we are doing some maintenance. Please check back soon.",
                "title": "503: Service Unavailable"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Email Volume",
                "legends": {
                    "failing": "Failing DMARC",
                    "forward": "Forwards",
                    "passing": "Passing DMARC"
                }
            },
            "heading": "Domain Report",
            "insights": {
                "exposure": {
                    "compliance": {
                        "configured": "Most of your email volume is DMARC ready",
                        "highly_configured": "A high percentage of your email volume is not DMARC ready",
                        "semi_configured": "Most of your email volume will fail DMARC authentication preventing you from increasing your protection levels"
                    },
                    "compliance_type": {
                        "configured": "Most of your email volume is passing both email authentication mechanisms",
                        "dkim": "Most of your email volume will pass automatic forwards using DKIM",
                        "one_mechanism": "A lot of your email volume is only passing one authentication mechanism",
                        "spf": "Most of your email volume will fail DMARC during automatic forwards"
                    },
                    "dmarc": {
                        "invalid_record": "Your DMARC policy is invalid and cannot be enforced leaving your domain open to abuse",
                        "no_aggregate_report_addresses": "You do not have full visibility on email sent from your domain making it harder to configure",
                        "no_failure_report_addresses": "You are not receiving failure reports making it harder to find senders abusing your domain",
                        "no_record": "Your domain cannot enforce a DMARC policy or receive reports because there is no DMARC record leaving your domain open to abuse",
                        "percentage_not_full": "Your DMARC policy is only applied to a percentage of emails and you may not receive reports on all volume",
                        "percentage_zero": "Your DMARC policy is not being applied to any emails and you may not receive reports on all volume",
                        "policy_none": "No action will be taken with unauthenticated email from your domain leaving your domain open to abuse.",
                        "policy_quarantine": "Unauthenticated emails from your domain will only be quarantined.",
                        "sub_domain_policy_none": "No action will be taken from unauthenticated email from sub-domains leaving your domain open to abuse.",
                        "sub_domain_policy_quarantine": "Unauthenticated email from sub-domains will only be quarantined.",
                        "too_many_records": "Your domain has too many DMARC policies and these will be ignored leaving your domain open to abuse."
                    },
                    "sender_compliance": {
                        "configured": "Your top sender is DMARC ready.",
                        "fail_auto_forward": "Most of the email from your top sender will fail DMARC during automatic forwards.",
                        "less_50": "Your top sending service is not correctly configured",
                        "less_80": "A lot of the email from your top sender is not authenticated.",
                        "pass_auto_forward": "Most of the email from your top sender will pass DMARC during automatic forwards",
                        "semi_configured": "Your top sender is not sending fully authenticated emails."
                    },
                    "sender_exposure": {
                        "configured": "All of your top senders are considered to be configured.",
                        "less_50": "Most of your top senders are configured correctly.",
                        "more_50": "Most of your top senders are not configured leaving your domain open to abuse.",
                        "none": "None of your top senders are configured leaving your domain open to abuse."
                    },
                    "spf": {
                        "all_missing": "Unauthorised sending servers will only be flagged as suspicious and not rejected.",
                        "all_neutral": "Set your SPF record to hard fail when a sender is not authorised.",
                        "all_pass": "Unauthorised sending servers allowed to send from your domain.",
                        "all_soft_fail": "Unauthorised sending servers may be allowed to send from your domain",
                        "invalid_record": "Your SPF record is invalid and cannot be enforced leaving your domain open to abuse.",
                        "no_record": "Your domain does not list any authorised sending servers leaving your domain open to abuse.",
                        "record_contains_errors": "Your SPF record contains errors and may not be listing all authorised senders.",
                        "recursive_lookup": "Your SPF record contains recursive includes making it invalid and leaving your domain open to abuse.",
                        "spf_reporting": "SPF reporting is obsoleted by DMARC reporting",
                        "too_many_dns_lookups": "Your SPF record has too many DNS lookups making it invalid and leaving your domain open to abuse.",
                        "too_many_mx_address_records": "Your SPF record has too many MX resource records making it invalid and leaving your domain open to abuse.",
                        "too_many_records": "Your domain has too many SPF records and these will be ignored leaving your domain open to abuse.",
                        "too_many_void_lookups": "Your SPF record has too many void lookups making it invalid and leaving your domain open to abuse."
                    }
                },
                "recommendations": {
                    "compliance": {
                        "warning": "Consider increasing your DMARC protection level."
                    },
                    "compliance_type": {
                        "warning": "Implement DKIM signing on your sending servers."
                    },
                    "dmarc": {
                        "invalid_record": "Correct the errors in your DMARC record.",
                        "no_record": "Add the DMARC record for your domain to your DNS.",
                        "no_rua": "Add the DMARC Manager aggregate report addresses to your DMARC record to start receiving reports.",
                        "no_ruf": "Add the DMARC Manager failure report addresses to your DMARC record for in-depth error reporting.",
                        "percentage_not_full": "Set the policy percentage to 100% as soon as it is safe to do so.",
                        "percentage_zero": "Increase the policy percentage in your DMARC settings.",
                        "policy_quarantine": "Set your DMARC policy to reject as soon as it is safe to do so.",
                        "sub_domain_policy_quarantine": "Set the DMARC policy for your sub-domains to reject as soon as it is safe to do so.",
                        "too_many_records": "Remove and unnecessary DMARC records, you should only have one."
                    },
                    "senders": {
                        "configured": "All of your top senders are configured.",
                        "info": "Use the reporting to ensure all legitimate sending services are correctly authorised.",
                        "semi_configured": "Use the reporting to identify which services still require configuration.",
                        "zero_configured": "All sending sources require configuration, implement effective authorisation mechanisms on each."
                    },
                    "spf": {
                        "all_missing": "Set your SPF record to hard fail when a sender is not authorised.",
                        "all_neutral": "Set your SPF record to hard fail when a sender is not authorised.",
                        "all_pass": "Set your SPF record to hard fail when a sender is not authorised.",
                        "all_soft_fail": "Set your SPF record to hard fail when a sender is not authorised.",
                        "contains_errors": "Correct the errors in your SPF record.",
                        "high_lookup_count": "Use SPF optimisation to reduce the number of DNS lookups.",
                        "high_void_lookup_count": "Use SPF optimisation to reduce the number of failing lookups.",
                        "invalid_record": "Correct the errors in your SPF record",
                        "no_record": "Add an SPF record for you domain and manage it easily inside DMARC Manager.",
                        "recursive_lookup": "Use SPF management in DMARC Manager to remove any recursive includes.",
                        "too_many_dns_lookups": "Use SPF optimisation in DMARC Manager to reduce the number of DNS lookups.",
                        "too_many_mx_records": "Use SPF optimisation in DMARC Manager to reduce the number resource record lookups in your SPF record.",
                        "too_many_records": "Remove any unnecessary SPF records, you should only have one.",
                        "too_many_void_lookups": "Use SPF optimisation in Sendmarc to reduce the number of unnecessary lookups and remove any invalid includes in your SPF record."
                    }
                }
            },
            "recommendations": {
                "heading": "Recommendations"
            },
            "risk_analysis": {
                "heading": "Risk Analysis"
            },
            "scoring": {
                "note": "Note: The domain score is calculated at the time of generating the report and may not be up to date at the time of viewing."
            },
            "sender_readiness": {
                "heading": "Sender DMARC Readiness",
                "legends": {
                    "configured": {
                        "description": "DMARC Readiness is a measure of the configuration require to implement full compliance.",
                        "heading": "Configured"
                    },
                    "partially": {
                        "description": "Partially Configured Sources have at least one authorisation mechanism active however, additional setup may be necessary.",
                        "heading": "Partially Configured"
                    },
                    "unconfigured": {
                        "description": "Illegitimate or Unconfigured Sources, are sources sending email from your domain that may be impersonating your brand.",
                        "heading": "Illegitimate or Unconfigured"
                    }
                }
            },
            "title": "Domain report for :domain",
            "top_countries": {
                "heading": "Top Origination Countries - Email Volume"
            },
            "top_senders": {
                "heading": "Top Senders - Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Download Report",
                "header": "Failure Report",
                "menu": {
                    "key_properties": "Key Properties",
                    "more_info": "More Info"
                }
            },
            "properties": {
                "authentication_failure": "Authentication Failure",
                "date": "Date",
                "delivery_results": "Delivery Results",
                "dkim_domain": "DKIM Domain",
                "dkim_header": "DKIM Canonicalised Header",
                "dkim_identity": "DKIM Identity",
                "dkim_selector": "DKIM Selector",
                "dmarc_version": "DMARC Version",
                "feedback_type": "Feedback Type",
                "incidents": "Number of Incidents",
                "original_recipients": "Original Recipients",
                "reporting_domains": "Reported Domains",
                "reporting_mta": "Reporting MTA",
                "reporting_user_agent": "Reporting User Agent",
                "return_path": "Return Path",
                "source_ip": "Source IP Address",
                "spf_dns": "SPF DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentication",
                    "date": "Date",
                    "ip_address": "IP Address",
                    "result": "Result",
                    "return_path": "Return Path"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Delivered",
                    "none": "None, Accept",
                    "smg_policy_action": "Policy Applied",
                    "spam": "Rejected",
                    "unknown": "Unknown"
                }
            },
            "title": "Failure Reports for :domain"
        },
        "global": {
            "buttons": {
                "add": "Add",
                "add_new": "Add New",
                "back": "Back",
                "cancel": "Cancel",
                "delete": "Delete",
                "import": "Import",
                "next": "Next",
                "no": "No",
                "open_link": "Open Link",
                "remove": "Remove",
                "save": "Save",
                "save_exit": "Save and Back",
                "select": "Select",
                "yes": "Yes"
            },
            "confirm_delete": "Do you want to continue?",
            "copied": "Copied",
            "created": "Created",
            "deleted": "Deleted",
            "fail": "Fail",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Disabled",
                        "enabled": "Enabled"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Off",
                        "on": "On"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Yes"
                    }
                }
            },
            "form": {
                "dirty": {
                    "accept": "Discard Changes",
                    "header": "You have unsaved changes",
                    "message": "Your changes will be discarded. Do you want to continue?",
                    "reject": "Cancel"
                },
                "failed": {
                    "description": "Error: Please fill out all required fields correctly.",
                    "title": "There were errors with your form."
                },
                "success": {
                    "title": "Your changes were saved."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Logout",
            "not_found": "Not found",
            "pagination": {
                "of": "of",
                "showing": "Showing",
                "to": "to"
            },
            "pass": "Pass",
            "read_more": "more",
            "remove": "Remove",
            "show_less": "Show less",
            "show_more": "Show more",
            "system": "System",
            "theme": {
                "dark": "Dark",
                "light": "Light"
            },
            "to": "To"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Date range",
                "to": "to"
            },
            "menu": {
                "overrides": "Overrides",
                "overview": "Overview",
                "reporters": "Reporters",
                "results": "Results"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Policy applied 'None'",
                    "policy_applied_quarantine": "Policy applied 'Quarantine'",
                    "policy_applied_reject": "Policy applied 'Reject'"
                },
                "overridden_policy_breakdown": "Overridden policy Breakdown",
                "volume_overridden_policy": "Volume with overridden policies"
            },
            "overview": {
                "cards": {
                    "compliance": "Compliance",
                    "deliverability": "Deliverability",
                    "volume": "Volume"
                },
                "properties": {
                    "country": "Country",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Sender"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "envelope_from": "Envelope From",
                        "envelope_to": "Envelope To",
                        "header_from": "Header From",
                        "no_data": "No reporter data available",
                        "percent_of_total": "% of Total",
                        "reporter": "Reporter",
                        "volume": "Volume"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alignment",
                    "error": "Error",
                    "fail": "Fail",
                    "failed": "Failed\/Not Aligned",
                    "none": "None",
                    "pass": "Pass",
                    "passed": "Passed\/Aligned"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Action Applied",
                    "info": {
                        "tooltip": "Action Taken by Receiver"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Delivered",
                            "value": "Delivered"
                        },
                        "quarantine": {
                            "tooltip": "Quarantined",
                            "value": "Quarantined"
                        },
                        "reject": {
                            "tooltip": "Rejected",
                            "value": "Rejected"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_dkim": "DMARC via DKIM",
                            "results": "Results",
                            "selector": "Selector",
                            "signing_domain": "Signing Domain"
                        },
                        "heading": "DKIM Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM Not Aligned",
                            "no_info": "No DKIM Info",
                            "yes": "DKIM Aligned"
                        },
                        "header": "DKIM Results",
                        "no_details": "No DKIM Signature Details",
                        "pass_tooltips": {
                            "no": "DKIM Failed",
                            "none": "DKIM None",
                            "yes": "DKIM Pass"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "Policy of ':policy' was applied by receiver"
                        },
                        "header": {
                            "from_domain": "From Domain"
                        },
                        "heading": "Other Details"
                    },
                    "results": {
                        "header": "DMARC Results",
                        "pass_tooltips": {
                            "no": "DMARC Failed",
                            "yes": "DMARC Passed"
                        }
                    }
                },
                "override_comment": {
                    "header": "Comment"
                },
                "override_type": {
                    "header": "Override Type"
                },
                "published_policy": {
                    "header": "Published Policy",
                    "info": {
                        "tooltip": "DMARC policy at time of validation"
                    }
                },
                "reporter": {
                    "header": "Reporter"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_spf": "DMARC via SPF",
                            "result": "Result",
                            "return_path_domain": "Return Path Domain"
                        },
                        "heading": "SPF Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF Not Aligned",
                            "no_info": "No SPF Info",
                            "yes": "SPF Aligned"
                        },
                        "header": "SPF Results",
                        "no_details": "No SPF Details",
                        "pass_tooltips": {
                            "error": "SPF Temporary Error",
                            "no": "SPF Failed",
                            "none": "SPF None",
                            "yes": "SPF Pass"
                        }
                    }
                },
                "volume": {
                    "header": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "created",
                "deleted": "deleted",
                "restored": "restored",
                "updated": "updated"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificate",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegation Enabled",
                        "nameservers": "Nameservers",
                        "selector": "Selector"
                    },
                    "title": "BIMI settings :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Value"
                    },
                    "title": "DKIM public key :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegation Enabled",
                        "is_policy_enabled": "Policy Enabled",
                        "nameservers": "Nameservers",
                        "policy": "Policy"
                    },
                    "title": "DKIM settings :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM Alignment",
                        "policy": "Policy",
                        "policy_percentage": "Policy Percentage",
                        "rua_emails": "Aggregate Report Recipients",
                        "rua_interval": "Aggregate Report Interval",
                        "ruf_emails": "Failure Report Recipients",
                        "ruf_format": "Failure Report Format",
                        "ruf_options": "Failure Report Options",
                        "ruf_status": "Failure Report Status",
                        "spf_alignment": "SPF Alignment",
                        "subdomain_policy": "Subdomain Policy"
                    },
                    "title": "DMARC settings :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Result",
                        "started_at": "Started at",
                        "status": "Status",
                        "type": "Type"
                    },
                    "title": "DNS Delegation Verification :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Primary",
                        "logo": "Logo",
                        "score": "Domain Score",
                        "type": "Type"
                    },
                    "title": "Domain :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Record Hosting Enabled",
                        "mx_records": "MX Records",
                        "policy": "Policy Mode"
                    },
                    "title": "MTA-STS settings :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Record Type",
                        "order": "Order",
                        "qualifier": "Record Qualifier",
                        "status": "Status",
                        "value": "Host Value"
                    },
                    "title": "SPF directives :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Top Level Record Qualifier",
                        "enable_flattening": "Flattening Enabled"
                    },
                    "title": "SPF settings :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT Enabled",
                        "rua_emails": "Report Emails"
                    },
                    "title": "TLS-RPT settings :event"
                }
            },
            "header": ":type Logs"
        },
        "menu": {
            "account": "Account",
            "domains": "Domains",
            "home": "Dashboard",
            "reports": {
                "email_senders": "Email Senders",
                "failure_reports": "Failure Reports",
                "reports": "Reports",
                "tls_rpt_reports": "TLS-RPT Reports"
            },
            "settings": {
                "alerts": "Alerts",
                "logout": "Logout",
                "settings": "Settings"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Disabled MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Enabled",
                "message": "It is recommended that TLS-RPT Reporting and MTA-STS Policy Hosting is enabled.",
                "modal": {
                    "header": "Disable MTA-STS Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Disabled",
                "message": "It is recommended that MTA-STS Record and Policy Hosting is enabled to affective manage your records.",
                "modal": {
                    "header": "Enable MTA-STS Hosting for :domain",
                    "message": "By enabling MTA-STS Hosting you can manage your record and hosting for your policy."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX Servers"
                },
                "policy": {
                    "input_label": "Policy Enforcement Level",
                    "options": {
                        "enforce": "Enforced",
                        "none": "None",
                        "testing": "In Testing"
                    }
                }
            },
            "heading": "MTA-STS Record & Policy Hosting",
            "info": {
                "heading": "What is TLS-RPT and MTA-STS?",
                "message": "TLS-RPT or SMTP TLS Reporting (Transport Layer Security Reporting) enables sending systems to share statistics and information about failures with recipient domains. MTA-STS (Mail Transfer Agents - Strict Transport Security) allows email service providers to enforce that incoming SMTP connections are only accepted if they are secured via TLS."
            },
            "status": {
                "policy": {
                    "pending": "Policy Pending Verification",
                    "verified": "Policy Verified"
                },
                "record": {
                    "pending": "Record Pending Verification",
                    "verified": "Record Verified"
                }
            }
        },
        "partners": {
            "title": "Manage partners"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configure DMARC Settings",
                        "manage_domains": "Manage domains"
                    },
                    "description": "We have not yet received reporting data for your domain, it can take up to 72 hours for data to appear. Reports can only be retrieved for active and verified domains. Manage your domains in the Domains module.",
                    "header": "No Reporting Data Available"
                },
                "no_reporting_data": {
                    "description": "No reporting data available for the selected date range, please adjust your selection and try again.",
                    "header": "No Reporting Data Available"
                }
            },
            "senders": {
                "unknown": "Unknown"
            }
        },
        "scoring": {
            "impersonation": {
                "blurb": {
                    "high": "Your domain currently has little to no protection, leaving it vulnerable to cybercriminal use in email-based attacks. This could lead to financial loss, decreased trust and reputational damage.",
                    "low": "Your organization has very low risk of cybercriminals using your domain to send fake emails. Domains with a rating of 5 are 100% DMARC compliant and are protected from impersonation and phishing attacks.",
                    "moderate": "One more change is needed to fully protect your domain. Savvy cybercriminals could still impersonate your business and stakeholders. Deliverability of legitimate email may be disrupted and some messages may land in Spam or Junk folders."
                },
                "header": "Impersonation"
            },
            "marketing": {
                "blurb": {
                    "high": "Right now, your branding isn`t displayed with your emails. Branding builds trust and assures recipients that an email is authentic. We recommend full BIMI implementation for boosted recognition, visibility and trust.",
                    "low": "Your organization uses email branding effectively, making your emails more trustworthy. Your logo and verified mark (if applicable) will be visible to recipients in email clients that support BIMI.",
                    "moderate": "Your email branding settings need some tweaks. Your logo and verified mark (if applicable) may show in some email clients, but most require a full BIMI setup to display branding."
                },
                "header": "Marketing"
            },
            "note": "NOTE: This is your current domain score and may not reflect changes you've made today.",
            "overall": {
                "blurb": {
                    "high": "You do not have effective controls in place to protect your domain from impersonation and interception of email communication. This puts your brand and email recipients at risk of attacks, reduces trust, and can damage email deliverability.",
                    "low": "You have implemented measures to protect recipients from malicious emails from your domain. Attention should be paid to areas where controls can be improved. These measures build trust in your brand, protect recipients from attacks and help ensure email is delivered effectively.",
                    "moderate": "You have implemented some measures to protect recipients from malicious emails sent from your domain. However, attention should be given to areas where controls can be strengthened. These measures help build trust in your organization, protect recipients from attacks, and ensure effective email deliverability."
                },
                "header": "Domain Score"
            },
            "privacy": {
                "blurb": {
                    "high": "Your domain has minimal to no defenses in place for email privacy. There`s a high risk of your communications being intercepted or compromised by unauthorized users. While most senders encrypt their communications by default, it`s critical to enforce policies for those hat don`t.",
                    "low": "Your emails are at low risk of being seen or intercepted by cybercriminals. Your domain has strong encryption standards and you`ll get reports on how well these are being upheld.",
                    "moderate": "You need to tighten up your email encryption. If it`s not secure, your emails could be intercepted."
                },
                "header": "Privacy"
            },
            "rating": {
                "high": "High Risk",
                "low": "Low Risk",
                "moderate": "Moderate Risk"
            }
        },
        "senders": {
            "type": {
                "hosting": "Hosting",
                "isp": "ISP",
                "mailbox_provider": "Mailbox Provider",
                "mail_filter": "Mail Filter",
                "other": "Other",
                "sales_and_marketing": "Sales & Marketing",
                "social": "Social",
                "transactional_mail": "Transactional Mail"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Blocked Volume",
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "deliverable": "Deliverable",
                "deliverable_volume": "Deliverable Volume",
                "ip_addresses": "IP Addresses",
                "senders": "Senders",
                "volume": "Email Volume"
            },
            "categories": {
                "all": "All Senders",
                "authorised_compliant": "Approved",
                "forwarder": "Forwarder",
                "suspicious": "Suspicious",
                "unauthorised": "Unapproved",
                "uncategorized": {
                    "message": "analyzing source...",
                    "tooltip": "Sender not Identified"
                },
                "unknown": "None"
            },
            "menu": {
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "reputation": "Reputation",
                "threats": "Threats"
            },
            "messages": {
                "category_updated": {
                    "error": "Failed to Update Sender Category",
                    "success": "Sender Category Updated"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Blacklisted",
                    "clean": "Clean",
                    "compliant": "Compliant",
                    "delivered": "Delivered",
                    "failing_dmarc": "Failing DMARC",
                    "forward": "Forwards",
                    "not_delivered": "Not Delivered",
                    "overridden": "Overridden",
                    "overridden_as_delivered": "Overridden As Delivered",
                    "overridden_as_not_delivered": "Overridden As Not Delivered",
                    "passing": "Passing",
                    "passing_dkim": "Passing DKIM Only",
                    "passing_dkim_spf": "Passing DKIM & SPF",
                    "passing_dmarc": "Passing DMARC",
                    "passing_spf": "Passing SPF Only",
                    "senders": "Senders"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Approved Sender IPs",
                        "approved_sender_volume": "Approved Sender Volume",
                        "blacklisted": "Blacklisted",
                        "blacklists": "Blacklists",
                        "blocked_volume": "Blocked Volume",
                        "category": {
                            "header": "Category",
                            "tooltip": "Categorize each sender to sort reporting data"
                        },
                        "clean": "Clean",
                        "compliance": "Compliance",
                        "country": "Country",
                        "deliverability": "Deliverability",
                        "delivered": "Delivered",
                        "failing": "Failing",
                        "forwards": "Forwards",
                        "host": "Host",
                        "ip": "IP",
                        "ip_addresses": "IP Addresses",
                        "not_delivered": "Not Delivered",
                        "passing": "Passing",
                        "sender": "Sender",
                        "top_blacklisted_senders": "Top Blacklisted Senders",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Amount of Blacklists the IP Address has been listed in"
                    }
                }
            },
            "title": "Email Senders for :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Add New SPF Directive",
                "edit_record": {
                    "tooltip": "Edit Record"
                }
            },
            "create_drawer": {
                "header": "Add New SPF Directive"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Order"
                    }
                },
                "titles": {
                    "allow_a_none": "Allow mail from A records for your own domain",
                    "allow_a_value": "Allow mail from A records for :value",
                    "allow_exists": "Allow mail from the exists macro :value",
                    "allow_include": "Allow mail from the include :value",
                    "allow_ip4_address": "Allow mail from the IPv4 address :value",
                    "allow_ip4_addresses": "Allow mail from the IPv4 addresses in :value",
                    "allow_ip6_address": "Allow mail from the IPv6 address :value",
                    "allow_ip6_addresses": "Allow mail from the IPv6 addresses in :value",
                    "allow_mx_none": "Allow mail from MX records for your own domain",
                    "allow_mx_value": "Allow mail from MX records for :value",
                    "fail_a_none": "Fail mail from A records for your own domain",
                    "fail_a_value": "Fail mail from A records for :value",
                    "fail_exists": "Fail mail from the exists macro :value",
                    "fail_include": "Fail mail from the include :value",
                    "fail_ip4_address": "Fail mail from the IPv4 address :value",
                    "fail_ip4_addresses": "Fail mail from the IPv4 addresses in :value",
                    "fail_ip6_address": "Fail mail from the IPv6 address :value",
                    "fail_ip6_addresses": "Fail mail from the IPv6 addresses in :value",
                    "fail_mx_none": "Fail mail from MX records for your own domain",
                    "fail_mx_value": "Fail mail from MX records for :value",
                    "neutral_a_none": "Treat mail from A records for your own domain as neutral",
                    "neutral_a_value": "Treat mail from A records for :value as neutral",
                    "neutral_exists": "Treat mail from the exists macro :value as neutral",
                    "neutral_include": "Treat mail from the include :value as neutral",
                    "neutral_ip4_address": "Treat mail from the IPv4 address :value as neutral",
                    "neutral_ip4_addresses": "Treat mail from the IPv4 addresses in :value as neutral",
                    "neutral_ip6_address": "Treat mail from the IPv6 address :value as neutral",
                    "neutral_ip6_addresses": "Treat mail from the IPv6 addresses in :value as neutral",
                    "neutral_mx_none": "Treat mail from MX records for your own domain as neutral",
                    "neutral_mx_value": "Treat mail from MX records for :value as neutral",
                    "soft_fail_a_none": "Soft fail mail from A records for your own domain",
                    "soft_fail_a_value": "Soft fail mail from A records for :value",
                    "soft_fail_exists": "Soft fail mail from the exists macro :value",
                    "soft_fail_include": "Soft fail mail from the include :value",
                    "soft_fail_ip4_address": "Soft fail mail from the IPv4 address :value",
                    "soft_fail_ip4_addresses": "Soft fail mail from the IPv4 addresses in :value",
                    "soft_fail_ip6_address": "Soft fail mail from the IPv6 address :value",
                    "soft_fail_ip6_addresses": "Soft fail mail from the IPv6 addresses in :value",
                    "soft_fail_mx_none": "Soft fail mail from MX records for your own domain",
                    "soft_fail_mx_value": "Soft fail mail from MX records for :value"
                }
            },
            "edit_drawer": {
                "header": "Edit SPF Directive"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "e.g. _spf.example.com",
                    "input_label": ":type Value"
                },
                "mechanism": {
                    "input_label": "Record Type"
                },
                "qualifier": {
                    "input_label": "Record Qualifier",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (Not Recommended)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Update an Spf Directive",
                "use_own_domain": {
                    "input_label": "Mechanism Domain"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "SPF Import Completed"
                },
                "header": "Import Existing SPF Record & Directives for :domain",
                "message": "All existing directives will be removed and replaced with imported directives."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Top Level Record Qualifier",
                        "options": {
                            "+": "+ Pass (Not Recommended)",
                            "-": "- Fail",
                            "?": "? Neutral (Not Recommended)",
                            "~": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+all Set to pass authentication of emails from senders that are not authorized.",
                            "-": "Set to fail authentication of emails from senders that are not authorized.",
                            "?": "Set to neutral, which neither passes nor fails authentication of emails from senders that are not authorized.",
                            "~": "Set to soft fail authentication of emails from senders that are not authorized and treat them as suspicious."
                        }
                    },
                    "flattening": {
                        "input_label": "SPF Flattening",
                        "tooltip": "SPF flattening addresses DNS lookup limits by converting include, MX, and A directives into IP addresses, creating a single, extended record to bypass restrictions."
                    }
                },
                "heading": "Settings"
            },
            "title": "Edit Settings - SPF"
        },
        "tables": {
            "clear": "Clear",
            "created_on": "Created on",
            "export": "Export",
            "not_found": "No Matching Records Found",
            "search": "Search"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configure TLS-RPT Settings",
                    "description": "We have not yet received reporting data for your domain, it can take up to 72hours for data to appear. TLS-RPT Reports can only be retrieved for domains that have TLS-RPT Reporting enabled.",
                    "header": "No Reporting Data Available"
                }
            },
            "heading": "TLS-RPT reports for :domain",
            "reporters": {
                "heading": "Reports",
                "legend": {
                    "reports": "Reports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Policy Mode",
                        "policy_type": "Policy Type",
                        "reporter": "Reporter",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Timeline",
                "legend": {
                    "failure": "Failed",
                    "success": "Successful"
                }
            },
            "title": "TLS-RPT reports"
        },
        "tls_settings": {
            "disable": {
                "action": "Disable TLS-RPT Reporting",
                "message": "It is recommended that TLS-RPT reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Disabled TLS-RPT reporting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable TLS-RPT Reporting",
                "heading": "TLS-RPT Reporting is Disabled",
                "message": "It is recommended that TLS-RPT Reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Enable TLS-RPT Reporting for :domain",
                    "message": "By enabling TLS-RPT reporting you can monitor statistics and information about failures with recipient domains."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Reports Will be Sent to"
                }
            },
            "heading": "TLS-RPT Reporting",
            "title": "Edit Settings - TLS-RPT"
        },
        "upload": {
            "add": "Add Files",
            "drop_zone": "Drag and drop file here to upload.",
            "title": "Upload"
        },
        "users": {
            "email": {
                "edit": "Edit Email",
                "placeholder": "Enter email address"
            },
            "menu": "Users",
            "name": {
                "title": "Name"
            },
            "title": "Manage Users"
        },
        "validation": {
            "email": "Not a valid email address",
            "invalid_attr": "The :attribute is not valid."
        }
    },
    "es": {
        "alerts": {
            "actions": {
                "create_activate": "Crear y activar",
                "edit_alert": {
                    "tooltip": "Editar alerta"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuración",
                    "mail_senders": "Remitentes de correo",
                    "none": "Ninguno",
                    "reports": "Informes",
                    "status": "Estado"
                },
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Actividad",
                "options": {
                    "customer-created": {
                        "description": "Notificarme cuando se crea una cuenta.",
                        "heading": "Cliente creado"
                    },
                    "customer-deleted": {
                        "description": "Notificarme cuando se elimine una cuenta.",
                        "heading": "Cliente eliminado"
                    },
                    "customer-exposure-report": {
                        "description": "Programe cuándo le gustaría recibir el Informe de exposición.",
                        "heading": "Informe de exposición"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Programe el certificado de cumplimiento de DMARC cuando la directiva DMARC esté configurada en Rechazar.",
                        "heading": "Certificado de cumplimiento DMARC en caso de rechazo"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notificarme cuando falle la nueva verificación de la delegación de DNS.",
                        "heading": "Error en la nueva verificación de configuración"
                    },
                    "dns-delegation-verified": {
                        "description": "Notificar dónde ha cambiado el estado de verificación",
                        "heading": "Estado de verificación cambiado"
                    },
                    "domain-compliance-drop": {
                        "description": "Notificarme cuando el cumplimiento caiga por debajo de un umbral.",
                        "heading": "Cumplimiento caído"
                    },
                    "domain-created": {
                        "description": "Notificarme cuando se agregue un nuevo dominio.",
                        "heading": "Dominio creado"
                    },
                    "domain-deleted": {
                        "description": "Notificarme cuando se elimine un dominio.",
                        "heading": "Dominio eliminado"
                    },
                    "domain-score-change": {
                        "description": "Notificarme cuando la puntuación de cumplimiento DMARC haya cambiado.",
                        "heading": "Se modificó la puntuación de cumplimiento de DMARC"
                    },
                    "domain-settings-change": {
                        "description": "Notificarme cuando se cambie la configuración.",
                        "heading": "Cambios en la configuración"
                    },
                    "sender-threat-level-change": {
                        "description": "Notificarme cuando aumente el nivel de amenaza para remitentes nuevos o existentes.",
                        "heading": "Nivel de amenaza aumentado"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notificarme cuando se detecte un remitente nuevo y comúnmente aprobado.",
                        "heading": "Remitente normalmente autorizado encontrado"
                    }
                }
            },
            "create": {
                "title": "Crear alerta"
            },
            "edit": {
                "title": "Editar alerta"
            },
            "email": {
                "description": "Especifica el destinatario del correo electrónico que te gustaría notificar.",
                "heading": "Correo electrónico"
            },
            "email_settings": {
                "heading": "Personalizar correo electrónico"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Cliente",
                        "domain": "Dominio"
                    }
                },
                "frequency": {
                    "input_label": "Frecuencia"
                },
                "recipients": {
                    "input_label": "Destinatarios"
                },
                "scope": {
                    "input_label": "Nivel de supervisión",
                    "listing-title": ":scope Actividad",
                    "options": {
                        "customer": "Cliente",
                        "partner": "Partner",
                        "system": "Sistema"
                    }
                },
                "select_below": {
                    "description": "Verificación de configuración",
                    "input_label": "Seleccione a continuación"
                },
                "start_date": {
                    "input_label": "Fecha de inicio"
                },
                "status": {
                    "input_label": "Estado"
                },
                "subject_tags": {
                    "example": "p.ej. [gravedad-alerta][cuenta][paquete][dominio]",
                    "input_label": "Habilitar prefijos de categorización de líneas de asunto"
                }
            },
            "frequency": {
                "description": "Elija con qué frecuencia desea recibir esta notificación.",
                "disabled-text": "No disponible: esta es una actividad basada en eventos.",
                "heading": "Frecuencia",
                "options": {
                    "monthly": "Mensual",
                    "quarterly": "Trimestral",
                    "six_monthly": "Semestral",
                    "two_monthly": "Dos meses",
                    "yearly": "Anual"
                },
                "starting": "A partir de"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerta",
                        "frequency": "Frecuencia",
                        "recipients": "Destinatarios",
                        "status": "Estado"
                    },
                    "record": {
                        "alert_activity": ":scope Actividad"
                    }
                },
                "title": "Alertas"
            },
            "monitor": {
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "A diario",
                        "fortnightly": "Quincenal",
                        "hourly": "Cada hora",
                        "immediate": "Inmediato",
                        "monthly": "Mensual",
                        "quarterly": "Trimestral",
                        "six_monthly": "Semestral",
                        "two_monthly": "Dos meses",
                        "weekly": "Semanalmente",
                        "yearly": "Anual"
                    }
                }
            },
            "senders": {
                "type": {
                    "hosting": "Alojamiento",
                    "isp": "ISP",
                    "mailbox_provider": "Proveedor de buzón",
                    "mail_filter": "Filtro de correo",
                    "other": "Otro",
                    "sales_and_marketing": "Ventas y Marketing",
                    "social": "Redes sociales",
                    "transactional_mail": "Correo transaccional"
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "La alerta está inactiva",
                        "value": "apagado"
                    },
                    "on": {
                        "tooltip": "La alerta está activa",
                        "value": "en"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deshabilitar el hosting de registros BIMI",
                "heading": "El servicio de hosting del registro BIMI está actualmente habilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar fácilmente su logotipo y certificado.",
                "modal": {
                    "header": "Deshabilitar el hosting del registro BIMI para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar hosting de registros BIMI",
                "heading": "El hosting BIMI está deshabilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar su Logotipo y Certificado.",
                "modal": {
                    "header": "Habilitar hosting de registros BIMI :domain",
                    "message": "Para utilizar BIMI, necesita un Certificado de marca verificada (VMC) o un Certificado de marca común (CMC) para su dominio emitido por una autoridad de certificación de terceros."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificado CN",
                    "expiry": "Caducidad del certificado",
                    "input_label": "Certificado de marca verificada BIMI (Tipo de archivo: pem)",
                    "url": "URL de autoridad\/certificado"
                },
                "image": {
                    "input_label": "Logotipo BIMI (Tipo de archivo: svg)",
                    "preview": "Vista previa del logotipo",
                    "size": "Tamaño del logotipo",
                    "url": "URL del logotipo"
                }
            },
            "info": {
                "heading": "¿Qué es BIMI?",
                "message": "BIMI (Indicadores de marca para la identificación de mensajes) es un estándar que facilita que su logotipo se muestre junto a sus mensajes en la bandeja de entrada, previene correos electrónicos fraudulentos y facilita la entrega. BIMI trabaja junto con DMARC, DKIM y SPF para verificar la autenticidad de un correo electrónico y ayudar con la visibilidad de la marca."
            },
            "settings": {
                "heading": "Ajustes"
            },
            "title": "Editar configuración - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Volver a configuración",
                "share": "Compartir instrucciones de configuración",
                "verify": "Verificar",
                "view": "Ver instrucciones de configuración"
            },
            "bimi": {
                "description": "Actualice su DNS para permitir la configuración de las claves públicas BIMI de su dominio.",
                "modal": {
                    "not_configured": {
                        "header": "Instrucciones de configuración no disponibles",
                        "message": "Para generar registros DNS para su configuración BIMI, debe cargar un logotipo."
                    }
                }
            },
            "buttons": {
                "raw_record": "Usar el registro TXT en su lugar"
            },
            "description": "Para habilitar la supervisión y gestión a través de DMARC, implemente los siguientes cambios en la configuración DNS de su dominio.",
            "dkim": {
                "description": "Agregue las siguientes entradas a los registros DNS de su dominio para permitir la configuración de sus claves públicas DKIM.",
                "no_nameservers": "La zona de clave pública DKIM se está creando, por favor espere las instrucciones de configuración..."
            },
            "dmarc": {
                "description": "Actualice su DNS para permitir la configuración de la directiva DMARC de su dominio y capturar informes sobre la actividad de autenticación de correo electrónico a través de nuestro panel.",
                "warning": "Tome nota de los guiones bajos requeridos (_) en el host y los puntos en el valor del registro."
            },
            "existing_dns_record_found": "Registro :record existente encontrado",
            "heading": ":title Instrucciones de configuración",
            "instructions": {
                "add": "Agregar registro :rdType",
                "heading": ":rdType Instrucciones de configuración",
                "new": "Paso 2: Agregar nuevo registro :rdType",
                "remove": "Paso 1: eliminar el registro :rdType existente"
            },
            "mta_policy": {
                "description": "Actualice su DNS para permitir la configuración de la directiva MTA de su dominio.",
                "heading": "Directiva MTA-STS"
            },
            "mta_record": {
                "description": "Actualice su DNS para permitir la configuración del registro MTA de su dominio.",
                "heading": "Registro MTA-STS"
            },
            "record": {
                "host": "Servidor",
                "type": "Tipo de registro",
                "value": "Valor"
            },
            "spf": {
                "description": "Actualice su DNS para permitir la configuración del registro SPF de su dominio a través de nuestra plataforma. Nuestra plataforma garantiza la validez del registro y utiliza tecnología de optimización que mitiga el riesgo de alcanzar los límites de búsqueda de DNS, además de garantizar que su directiva no sea demasiado permisiva."
            },
            "status": {
                "last_date": "Última verificación el",
                "pending": "Pendiente de verificación",
                "verified": "Verificado"
            },
            "tls_rpt": {
                "description": "Actualice su DNS para permitir la configuración del registro TLS-RPT de su dominio y capturar informes.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Administrar clientes"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volumen de correo electrónico",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobados y otros"
                    }
                },
                "domain_totals": {
                    "action": "Ver dominios",
                    "enforced": "aplicado",
                    "heading": "Dominios",
                    "reporting": "Informes",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Ver remitentes",
                    "heading": "Remitentes",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobado"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Ver todos los dominios"
                },
                "legends": {
                    "failing": "DMARC fallido",
                    "forwards": "Hacia adelante",
                    "passing": "Pasando DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Ver informe"
                    },
                    "header": {
                        "approved": "Aprobado",
                        "domain": "Dominios",
                        "senders": "Remitentes",
                        "unapproved": "No aprobado",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Ver informe de dominio"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Cumplimiento",
                        "domain": "Dominio",
                        "sender": "Remitentes aprobados por volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Panel"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "No se pudieron encontrar servidores de nombres coincidentes.",
                        "mismatch": "Los registros del servidor de nombres no coinciden con la configuración esperada.",
                        "missing": "Deben estar presentes al menos dos registros de servidores de nombres."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "El modo de alineación DKIM no coincide con la configuración.",
                        "missing": "Falta el modo de alineación DKIM, pero no está configurado al valor predeterminado."
                    },
                    "aspf": {
                        "mismatch": "El modo de alineación SPF no coincide con la configuración.",
                        "missing": "Falta el modo de alineación SPF, pero no está configurado al valor predeterminado."
                    },
                    "cname": {
                        "mismatch": "El registro CNAME no se ha añadido correctamente y no coincide con la configuración esperada.",
                        "partial": "El registro CNAME no se ha añadido correctamente. Esto puede causar conflictos."
                    },
                    "fo": {
                        "mismatch": "Las opciones de informes de fallos no coinciden con la configuración.",
                        "missing": "Faltan las opciones de informes de fallos, pero no están configuradas al valor predeterminado."
                    },
                    "p": {
                        "mismatch": "La directiva no coincide con la configuración.",
                        "missing": "Falta la directiva."
                    },
                    "pct": {
                        "mismatch": "El porcentaje de la directiva no coincide con la configuración.",
                        "missing": "Falta el porcentaje de la directiva, pero no está configurado al valor predeterminado."
                    },
                    "rf": {
                        "mismatch": "El formato de informes de fallos no coincide con la configuración."
                    },
                    "ri": {
                        "mismatch": "El intervalo de los informes agregados no coincide con la configuración.",
                        "missing": "Falta el intervalo de los informes agregados, pero no está configurado al valor predeterminado."
                    },
                    "rua": {
                        "mismatch": "Los destinatarios de los informes agregados no coinciden con la configuración.",
                        "order": "Los destinatarios de los informes agregados no están en el orden correcto."
                    },
                    "ruf": {
                        "mismatch": "Los destinatarios de los informes de fallos no coinciden con la configuración.",
                        "order": "Los destinatarios de los informes de fallos no están en el orden correcto."
                    },
                    "sp": {
                        "mismatch": "La directiva de subdominios no coincide con la configuración.",
                        "missing": "Falta la directiva de subdominios, pero está configurada de manera diferente a la de dominio."
                    },
                    "txt": {
                        "reporting-only": "El registro no coincide con la configuración esperada, pero nuestra dirección de informes está presente."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "El valor TXT no coincide con el valor esperado."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    },
                    "rua": {
                        "missing": "Falta la dirección de informes esperada."
                    }
                }
            },
            "verify": {
                "error": "Verificación fallida",
                "nxdomain": "Registro no encontrado.",
                "success": "Verificación completada con éxito",
                "timeout": "Tiempo de verificación agotado"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Agregar nueva clave pública",
                "edit_public_key": {
                    "tooltip": "Editar clave pública"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva clave pública"
            },
            "disable": {
                "action": "Gestión DKIM deshabilitada",
                "heading": "El hosting de clave pública DKIM está actualmente habilitado",
                "message": "Se recomienda habilitar la gestión de DKIM para ayudarle a administrar sus claves públicas.",
                "modal": {
                    "header": "Deshabilitar la administración DKIM para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "edit_drawer": {
                "header": "Editar clave pública"
            },
            "enable": {
                "action": "Habilitar la gestión DKIM",
                "heading": "El hosting de clave pública DKIM está deshabilitado",
                "message": "Se recomienda que la administración DKIM esté habilitada para ayudarle a administrar sus claves públicas.",
                "modal": {
                    "header": "Habilitar la administración DKIM para :domain",
                    "message": "Al habilitar la administración DKIM, puede administrar sus claves públicas con facilidad."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "record_type": {
                    "input_label": "Tipo de registro"
                },
                "record_value": {
                    "example": "p.ej. dkim.ejemplo.com",
                    "input_label": "Valor de registro"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación de clave pública DKIM completada"
                },
                "disabled_tooltip": "Las claves públicas DKIM solo se pueden importar para dominios que tengan informes.",
                "header": "Volver a importar claves públicas DKIM para :domain",
                "message": "Todas las claves públicas existentes se eliminarán y reemplazarán con directivas importadas."
            },
            "info": {
                "heading": "¿Qué es DKIM?",
                "message": "Domain Keys Identified Mail es un método de autenticación de correos electrónicos que permite al remitente firmar digitalmente un correo utilizando una clave privada asociada con su nombre de dominio, la cual puede ser verificada por el servidor de correo del destinatario mediante una clave pública publicada en los registros DNS del remitente. Esto ayuda a verificar la autenticidad e integridad del correo, reduciendo el riesgo de ataques de spam y phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Directiva de firma saliente",
                        "options": {
                            "all": "Todos los correos electrónicos",
                            "some": "Algunos correos electrónicos"
                        }
                    },
                    "testing": {
                        "input_label": "Modo de prueba"
                    }
                },
                "heading": "Configuración de directivas"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "value": "Valor"
                    }
                }
            },
            "title": "Editar configuración - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Modo de alineación DKIM"
                    },
                    "options": {
                        "r": "Relajado",
                        "s": "Estricto"
                    },
                    "spf_mode": {
                        "input_label": "Modo de alineación SPF"
                    }
                },
                "heading": "Configuración de alineación"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Aplicar una directiva de subdominio diferente"
                    },
                    "percentage": {
                        "input_label": "Porcentaje"
                    },
                    "policy": {
                        "input_label": "directiva DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "directiva de subdominio"
                    }
                },
                "heading": "Configuración de directivas"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Los informes se enviarán a"
                    },
                    "interval": {
                        "input_label": "Intervalo de informe",
                        "options": {
                            "1_day": "Una vez al día",
                            "1_hour": "Cada hora",
                            "2_hours": "Cada 2 horas",
                            "4_hours": "Cada 4 Horas",
                            "6_hours": "Cada 6 horas"
                        }
                    }
                },
                "heading": "Informes agregados"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Permitir",
                    "header": "Habilitar informes de errores",
                    "message": "Los informes de fallos le brindarán más información sobre los correos electrónicos que no pasaron DMARC. Al habilitar esta función, podremos procesar y mostrar los datos del informe, que pueden contener información personal como direcciones de correo electrónico o contenido de mensajes.",
                    "message_disclaimer": "Si las políticas legales de su organización prohíben habilitar este tipo de informe, procese estos informes manualmente y especifique el destinatario de los mismos.",
                    "reject": "Desactivar"
                },
                "fields": {
                    "options": {
                        "input_label": "Opciones de informe",
                        "options": {
                            "d": "DKIM falla independientemente de la alineación",
                            "s": "El SPF falla independientemente de la alineación",
                            "0": "SPF o DKIM fallan y no se alinean",
                            "1": "SPF y DKIM fallan y no se alinean"
                        }
                    },
                    "status": {
                        "input_label": "Procesamiento de informes",
                        "options": {
                            "disabled": "Desactivado",
                            "enabled": "Activado",
                            "specified": "Destinatarios específicos"
                        }
                    }
                },
                "heading": "Informes de fallos"
            },
            "title": "Editar configuración - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificado de cumplimiento DMARC",
                "domain_report": "Informe de dominio",
                "edit_settings": {
                    "tooltip": "Editar configuración"
                },
                "remove_primary": "Quitar de Favoritos",
                "set_as_active": "Establecer como activo",
                "set_as_parked": "Establecer como estacionado",
                "set_primary": "Marcar como Primaria",
                "sync_dns_records": "Sincronizar registros DNS",
                "view_logs": "Ver registros"
            },
            "create_drawer": {
                "header": "Agregar nuevo dominio"
            },
            "fields": {
                "name": {
                    "example": "p.ej. 'ejemplo.com' o 'subdominio.ejemplo.com'",
                    "input_label": "Nombre de dominio"
                },
                "type": {
                    "input_label": "Tipo de dominio",
                    "options": {
                        "active": "Activo",
                        "parked": "estacionado"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Dominio",
                        "policy": "directiva",
                        "score": "Puntuación",
                        "status": "Estado",
                        "type": "Tipo"
                    }
                },
                "title": "Dominios"
            },
            "logs_drawer": {
                "header": "Dominio"
            },
            "park_modal": {
                "action": "Establecer como Aparcado",
                "description": "Los dominios aparcados son para dominios que no envían correo. Establecer el dominio como aparcado cambiará la política DMARC a rechazar y también establecerá el Calificador Mecanismo SPF a Fallar todo, esto evitará que se envíe correo.",
                "header": "Confirmar Dominio como Aparcado"
            },
            "report_modal": {
                "action": "Generar enlace",
                "fields": {
                    "copy_link": {
                        "input_label": "Copie el siguiente enlace para compartir el informe de dominio"
                    },
                    "date_range": {
                        "input_label": "Rango de fechas"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Informe de dominio"
            },
            "settings": {
                "title": "Editar configuración"
            },
            "setup_modal": {
                "action": "Generar enlace",
                "fields": {
                    "copy_link": {
                        "input_label": "Enlace a las instrucciones de configuración"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Enlace a las instrucciones de configuración"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Ninguno"
                    },
                    "quarantine": {
                        "value": "Cuarentena"
                    },
                    "reject": {
                        "value": "Rechazar"
                    },
                    "tooltip": "directiva aplicada"
                },
                "primary": {
                    "tooltip": "Primaria",
                    "value": "Primaria"
                },
                "score": {
                    "high": {
                        "tooltip": "Alto riesgo"
                    },
                    "low": {
                        "tooltip": "Bajo riesgo"
                    },
                    "moderate": {
                        "tooltip": "Riesgo moderado"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verificado, sin informar",
                        "tooltip_partial": "Parcialmente verificado, sin informar",
                        "value": "No informar"
                    },
                    "not_verified": {
                        "tooltip": "No verificado, no reportado",
                        "value": "No verificado"
                    },
                    "reporting": {
                        "tooltip": "Verificado, Informes",
                        "tooltip_partial": "Parcialmente verificado, informes",
                        "value": "Informes"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Dominio activo",
                        "value": "Activo"
                    },
                    "parked": {
                        "tooltip": "Dominio estacionado",
                        "value": "estacionado"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "p.ej. destinatario@ejemplo.com",
                    "input_label": "Editar correo electrónico",
                    "placeholder": "Ingrese la dirección de correo electrónico",
                    "subscribed_label": "suscrito",
                    "unsubscribed_label": "Cancelar suscripción",
                    "validation_message": "Ingrese una dirección de correo electrónico válida"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Lo sentimos, no estás autorizado a acceder a esta página.",
                "title": "401: no autorizado"
            },
            "403": {
                "description": "Lo sentimos, tienes prohibido acceder a esta página.",
                "title": "403: Prohibido"
            },
            "404": {
                "description": "Lo sentimos, no se pudo encontrar la página que estás buscando.",
                "title": "404: Página no encontrada"
            },
            "500": {
                "description": "Vaya, algo salió mal en nuestros servidores.",
                "title": "500: Error del servidor"
            },
            "502": {
                "description": "Lo sentimos, nuestros servidores no están disponibles.",
                "title": "502 Puerta de enlace incorrecta"
            },
            "503": {
                "description": "Lo sentimos, estamos realizando tareas de mantenimiento. Por favor, vuelva a intentarlo pronto.",
                "title": "503: Servicio no disponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volumen de correo electrónico",
                "legends": {
                    "failing": "DMARC fallido",
                    "forward": "Hacia adelante",
                    "passing": "DMARC aprobado"
                }
            },
            "heading": "Informe de dominio",
            "insights": {
                "exposure": {
                    "compliance": {
                        "configured": "La mayor parte de su volumen de correo electrónico está preparado para DMARC",
                        "highly_configured": "Un alto porcentaje de su volumen de correo electrónico no está preparado para DMARC",
                        "semi_configured": "La mayor parte de su volumen de correo electrónico fallará en la autenticación DMARC, lo que le impedirá aumentar sus niveles de protección."
                    },
                    "compliance_type": {
                        "configured": "La mayor parte de su volumen de correo electrónico pasa ambos mecanismos de autenticación de correo electrónico.",
                        "dkim": "La mayor parte de su volumen de correo electrónico se reenviará automáticamente mediante DKIM",
                        "one_mechanism": "Gran parte de su volumen de correo electrónico solo pasa por un mecanismo de autenticación",
                        "spf": "La mayor parte de su volumen de correo electrónico fallará en DMARC durante los reenvíos automáticos"
                    },
                    "dmarc": {
                        "invalid_record": "Su directiva DMARC no es válida y no se puede aplicar, lo que deja su dominio expuesto al abuso",
                        "no_aggregate_report_addresses": "No tiene visibilidad completa sobre los correos electrónicos enviados desde su dominio, lo que dificulta su configuración.",
                        "no_failure_report_addresses": "No recibe informes de fallos, lo que hace que sea más difícil encontrar remitentes que abusen de su dominio.",
                        "no_record": "Su dominio no puede aplicar una directiva DMARC ni recibir informes porque no hay ningún registro DMARC que deje su dominio expuesto al abuso.",
                        "percentage_not_full": "Su directiva DMARC solo se aplica a un porcentaje de correos electrónicos y es posible que no reciba informes sobre todo el volumen.",
                        "percentage_zero": "Su directiva DMARC no se aplica a ningún correo electrónico y es posible que no reciba informes de todo el volumen.",
                        "policy_none": "No se tomarán medidas si el correo electrónico no autenticado de su dominio deja su dominio expuesto al abuso.",
                        "policy_quarantine": "El correo electrónico no autenticado de su dominio solo se pondrá en cuarentena",
                        "sub_domain_policy_none": "No se tomarán medidas por correo electrónico no autenticado de subdominios que dejen su dominio expuesto al abuso.",
                        "sub_domain_policy_quarantine": "El correo electrónico no autenticado de subdominios solo se pondrá en cuarentena",
                        "too_many_records": "Su dominio tiene demasiadas directivas DMARC, y estas serán ignoradas, dejando su dominio vulnerable a abusos."
                    },
                    "sender_compliance": {
                        "configured": "Su remitente principal está preparado para DMARC",
                        "fail_auto_forward": "La mayor parte del correo electrónico de su remitente principal fallará en DMARC durante los reenvíos automáticos.",
                        "less_50": "Su servicio de envío principal no está configurado correctamente",
                        "less_80": "Gran parte del correo electrónico de su remitente principal no está autenticado",
                        "pass_auto_forward": "La mayor parte del correo electrónico de su remitente principal pasará por DMARC durante los reenvíos automáticos.",
                        "semi_configured": "Su remitente principal no envía correos electrónicos completamente autenticados"
                    },
                    "sender_exposure": {
                        "configured": "Se considera que todos sus remitentes principales están configurados",
                        "less_50": "La mayoría de sus remitentes principales están configurados correctamente",
                        "more_50": "La mayoría de sus remitentes principales no están configurados, lo que deja su dominio expuesto al abuso.",
                        "none": "Ninguno de sus principales remitentes está configurado, lo que deja su dominio expuesto al abuso"
                    },
                    "spf": {
                        "all_missing": "Los servidores de envío no autorizados solo se marcarán como sospechosos y no se rechazarán",
                        "all_neutral": "Establezca su registro SPF en Hard Fail si un remitente no está autorizado.",
                        "all_pass": "Servidores de envío no autorizados tienen permitido enviar correos desde su dominio.",
                        "all_soft_fail": "Es posible que servidores de envío no autorizados estén permitidos para enviar correos desde su dominio.",
                        "invalid_record": "Su registro SPF no es válido y no se puede aplicar, lo que deja su dominio expuesto al abuso",
                        "no_record": "Su dominio no incluye ningún servidor de envío autorizado, lo que deja su dominio expuesto a abusos",
                        "record_contains_errors": "Su registro SPF contiene errores y es posible que no incluya todos los remitentes autorizados",
                        "recursive_lookup": "Su registro SPF contiene elementos recursivos que lo invalidan y dejan su dominio expuesto al abuso.",
                        "too_many_dns_lookups": "Su registro SPF tiene demasiadas búsquedas de DNS, lo que lo hace inválido y deja su dominio expuesto al abuso.",
                        "too_many_mx_address_records": "Su registro SPF tiene demasiados registros de recursos MX, lo que lo hace inválido y deja su dominio abierto al abuso.",
                        "too_many_records": "Su dominio tiene demasiados registros SPF, los cuales serán ignorados, dejando su dominio vulnerable a abusos.",
                        "too_many_void_lookups": "Su registro SPF tiene demasiadas búsquedas nulas, lo que lo hace inválido y deja su dominio abierto al abuso."
                    }
                },
                "recommendations": {
                    "compliance": {
                        "warning": "Considere aumentar su nivel de protección DMARC"
                    },
                    "compliance_type": {
                        "warning": "Implemente la firma DKIM en sus servidores de envío"
                    },
                    "dmarc": {
                        "invalid_record": "Corrija los errores en su registro DMARC",
                        "no_record": "Añade el registro DMARC de tu dominio a tu DNS",
                        "no_rua": "Agregue las direcciones de informes agregados de DMARC a su registro DMARC para comenzar a recibir informes",
                        "no_ruf": "Agregue las direcciones del informe de fallos de DMARC a su registro DMARC para obtener informes de errores detallados",
                        "percentage_not_full": "Establezca el porcentaje de la directiva en 100% tan pronto como sea seguro hacerlo.",
                        "percentage_zero": "Aumente el porcentaje de la directiva en su configuración de DMARC.",
                        "policy_quarantine": "Configure su directiva DMARC para que rechace tan pronto como sea seguro hacerlo",
                        "sub_domain_policy_quarantine": "Configure la directiva DMARC para que sus subdominios rechacen tan pronto como sea seguro hacerlo",
                        "too_many_records": "Elimine los registros DMARC innecesarios, solo debería tener uno"
                    },
                    "senders": {
                        "configured": "Todos tus remitentes principales están configurados",
                        "info": "Utilice los informes para garantizar que todos los servicios de envío legítimos estén autorizados correctamente.",
                        "semi_configured": "Utilice los informes para identificar qué servicios aún requieren configuración",
                        "zero_configured": "Todas las fuentes de envío requieren configuración, implementar mecanismos de autorización efectivos en cada una"
                    },
                    "spf": {
                        "all_missing": "Configure su registro SPF para que falle cuando un remitente no esté autorizado",
                        "all_neutral": "Configure su registro SPF para que falle cuando un remitente no esté autorizado",
                        "all_pass": "Configure su registro SPF para que falle cuando un remitente no esté autorizado",
                        "all_soft_fail": "Configure su registro SPF para que falle cuando un remitente no esté autorizado",
                        "contains_errors": "Corrija los errores en su registro SPF",
                        "high_lookup_count": "Utilice la optimización SPF en DMARC para reducir la cantidad de búsquedas de DNS",
                        "high_void_lookup_count": "Utilice la optimización SPF en DMARC para reducir la cantidad de búsquedas fallidas",
                        "invalid_record": "Corrija los errores en su registro SPF",
                        "no_record": "Agregue un registro SPF para su dominio y adminístrelo fácilmente dentro de DMARC",
                        "recursive_lookup": "Utilice la gestión de SPF en DMARC para eliminar cualquier inclusión recursiva",
                        "too_many_dns_lookups": "Utilice la optimización SPF en DMARC para reducir la cantidad de búsquedas de DNS",
                        "too_many_mx_records": "Utilice la optimización SPF en DMARC para reducir el número de búsquedas de registros de recursos en su registro SPF",
                        "too_many_records": "Elimina cualquier registro SPF innecesario, solo deberías tener uno",
                        "too_many_void_lookups": "Utilice la optimización de SPF en DMARC para reducir la cantidad de búsquedas innecesarias y eliminar cualquier inclusión no válida en su registro SPF"
                    }
                }
            },
            "recommendations": {
                "heading": "Recomendaciones"
            },
            "risk_analysis": {
                "heading": "Análisis de riesgos"
            },
            "scoring": {
                "note": "Nota: La puntuación del dominio se calcula al momento de generar el informe y es posible que no esté actualizada al momento de verlo."
            },
            "sender_readiness": {
                "heading": "Preparación de DMARC del remitente",
                "legends": {
                    "configured": {
                        "description": "La preparación de DMARC es una medida de la configuración necesaria para implementar el cumplimiento total.",
                        "heading": "Configurado"
                    },
                    "partially": {
                        "description": "Las fuentes parcialmente configuradas tienen al menos un mecanismo de autorización activo; sin embargo, es posible que sea necesaria una configuración adicional.",
                        "heading": "Parcialmente configurado"
                    },
                    "unconfigured": {
                        "description": "Fuentes ilegítimas o no configuradas son fuentes que envían correo electrónico desde su dominio y que pueden estar suplantando su marca.",
                        "heading": "Ilegítimo o no configurado"
                    }
                }
            },
            "title": "Informe de dominio para :domain",
            "top_countries": {
                "heading": "Principales países de origen: volumen de correo electrónico"
            },
            "top_senders": {
                "heading": "Principales remitentes: Cumplimiento"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Descargar Informe",
                "header": "Informe de falla",
                "menu": {
                    "key_properties": "Propiedades clave",
                    "more_info": "Más información"
                }
            },
            "properties": {
                "authentication_failure": "Fallo de autenticación",
                "date": "Fecha",
                "delivery_results": "Resultados de entrega",
                "dkim_domain": "Dominio DKIM",
                "dkim_header": "Encabezado canonicalizado DKIM",
                "dkim_identity": "Identidad DKIM",
                "dkim_selector": "Selector DKIM",
                "dmarc_version": "Versión DMARC",
                "feedback_type": "Tipo de comentario",
                "incidents": "Número de incidentes",
                "original_recipients": "Destinatarios originales",
                "reporting_domains": "Dominios reportados",
                "reporting_mta": "MTA de informes",
                "reporting_user_agent": "Agente de usuario de informes",
                "return_path": "Camino de regreso",
                "source_ip": "Dirección IP de origen",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Autenticación",
                    "date": "Fecha",
                    "ip_address": "Dirección IP",
                    "result": "Resultado",
                    "return_path": "Camino de regreso"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Entregado",
                    "none": "Ninguno, Aceptar",
                    "smg_policy_action": "Directiva aplicada",
                    "spam": "Rechazado",
                    "unknown": "Desconocido"
                }
            },
            "title": "Informes de fallos para :domain"
        },
        "global": {
            "buttons": {
                "add": "Agregar",
                "add_new": "Agregar nuevo",
                "back": "Atrás",
                "cancel": "Cancelar",
                "delete": "Borrar",
                "import": "Importar",
                "next": "Próximo",
                "no": "No",
                "open_link": "Abrir enlace",
                "remove": "Eliminar",
                "save": "Guardar",
                "save_exit": "Guardar y volver",
                "select": "Seleccionar",
                "yes": "Sí"
            },
            "confirm_delete": "¿Quieres continuar?",
            "copied": "copiado",
            "created": "Creado",
            "deleted": "Eliminado",
            "fail": "Fallar",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Desactivado",
                        "enabled": "Activado"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Apagado",
                        "on": "En"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Sí"
                    }
                }
            },
            "form": {
                "dirty": {
                    "accept": "Descartar cambios",
                    "header": "Tienes cambios no guardados",
                    "message": "Tus cambios se descartarán. ¿Deseas continuar?",
                    "reject": "Cancelar"
                },
                "failed": {
                    "description": "Error: complete todos los campos obligatorios correctamente.",
                    "title": "Hubo errores con su formulario."
                },
                "success": {
                    "title": "Tus cambios fueron guardados."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Cerrar sesión",
            "not_found": "No encontrado",
            "pagination": {
                "of": "de",
                "showing": "Demostración",
                "to": "a"
            },
            "pass": "Aprobar",
            "read_more": "Más",
            "remove": "Eliminar",
            "show_less": "Mostrar menos",
            "show_more": "Mostrar más",
            "system": "Sistema",
            "theme": {
                "dark": "Oscuro",
                "light": "Claro"
            },
            "to": "A"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Rango de fechas",
                "to": "a"
            },
            "menu": {
                "overrides": "Anulaciones",
                "overview": "Descripción general",
                "reporters": "Reporteros",
                "results": "Resultados"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Directiva aplicada 'Ninguna'",
                    "policy_applied_quarantine": "Directiva aplicada 'Cuarentena'",
                    "policy_applied_reject": "Directiva aplicada 'Rechazar'"
                },
                "overridden_policy_breakdown": "Desglose de directivas anuladas",
                "volume_overridden_policy": "Volumen con directivas anuladas"
            },
            "overview": {
                "cards": {
                    "compliance": "Cumplimiento",
                    "deliverability": "Capacidad de entrega",
                    "volume": "Volumen"
                },
                "properties": {
                    "country": "País",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organización",
                    "sender": "Remitente"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Cumplimiento",
                        "envelope_from": "Sobre de",
                        "envelope_to": "Sobre a",
                        "header_from": "Encabezado desde",
                        "no_data": "No hay datos de reportes disponibles.",
                        "percent_of_total": "% del total",
                        "reporter": "Reportero",
                        "volume": "Volumen"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alineación",
                    "error": "Error",
                    "fail": "Fallar",
                    "failed": "Fallido\/No alineado",
                    "none": "Ninguno",
                    "pass": "Aprobar",
                    "passed": "Aprobado\/Alineado"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Acción aplicada",
                    "info": {
                        "tooltip": "Acción tomada por el receptor"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Entregado",
                            "value": "Entregado"
                        },
                        "quarantine": {
                            "tooltip": "En cuarentena",
                            "value": "En cuarentena"
                        },
                        "reject": {
                            "tooltip": "Rechazado",
                            "value": "Rechazado"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alineación",
                            "dmarc_via_dkim": "DMARC a través de DKIM",
                            "results": "Resultados",
                            "selector": "Selector",
                            "signing_domain": "Dominio de firma"
                        },
                        "heading": "Detalles de DKIM"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM no alineado",
                            "no_info": "Sin información DKIM",
                            "yes": "DKIM alineado"
                        },
                        "header": "Resultados DKIM",
                        "no_details": "Sin detalles de firma DKIM",
                        "pass_tooltips": {
                            "no": "DKIM falló",
                            "none": "DKIM Ninguno",
                            "yes": "Pase DKIM"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "El receptor aplicó la directiva de ':policy'"
                        },
                        "header": {
                            "from_domain": "Desde dominio"
                        },
                        "heading": "Otros detalles"
                    },
                    "results": {
                        "header": "Resultados DMARC",
                        "pass_tooltips": {
                            "no": "DMARC falló",
                            "yes": "DMARC aprobado"
                        }
                    }
                },
                "override_comment": {
                    "header": "Comentario"
                },
                "override_type": {
                    "header": "Tipo de anulación"
                },
                "published_policy": {
                    "header": "Directiva publicada",
                    "info": {
                        "tooltip": "Directiva DMARC en el momento de la validación."
                    }
                },
                "reporter": {
                    "header": "Reportero"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alineación",
                            "dmarc_via_spf": "DMARC vía SPF",
                            "result": "Resultado",
                            "return_path_domain": "Dominio de ruta de retorno"
                        },
                        "heading": "Detalles del FPS"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF no alineado",
                            "no_info": "Sin información SPF",
                            "yes": "SPF alineado"
                        },
                        "header": "Resultados del FPS",
                        "no_details": "Sin detalles de SPF",
                        "pass_tooltips": {
                            "error": "Error temporal del SPF",
                            "no": "FPS fallido",
                            "none": "SPF Ninguno",
                            "yes": "Pase SPF"
                        }
                    }
                },
                "volume": {
                    "header": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "creado",
                "deleted": "eliminado",
                "restored": "restaurado",
                "updated": "actualizado"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificado",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegación habilitada",
                        "nameservers": "Servidores de nombres",
                        "selector": "Selector"
                    },
                    "title": "Configuración BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Descripción",
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "status": "Estado",
                        "ttl": "TTL",
                        "value": "Valor"
                    },
                    "title": "Clave pública DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegación habilitada",
                        "is_policy_enabled": "Directiva habilitada",
                        "nameservers": "Servidores de nombres",
                        "policy": "Directiva"
                    },
                    "title": "Configuración DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alineación DKIM",
                        "policy": "Directiva",
                        "policy_percentage": "Porcentaje de directiva",
                        "rua_emails": "Destinatarios del informe agregado",
                        "rua_interval": "Intervalo de informe agregado",
                        "ruf_emails": "Destinatarios del informe de fallos",
                        "ruf_format": "Formato de informe de fallos",
                        "ruf_options": "Opciones de informe de fallos",
                        "ruf_status": "Estado del informe de fallos",
                        "spf_alignment": "Alineación SPF",
                        "subdomain_policy": "Directiva de subdominio"
                    },
                    "title": "Configuración DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Mensajes",
                        "result": "Resultado",
                        "started_at": "Comenzó en",
                        "status": "Estado",
                        "type": "Tipo"
                    },
                    "title": "Verificación de delegación DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Dominio",
                        "is_favorite": "Primaria",
                        "logo": "Logo",
                        "score": "Puntuación de dominio",
                        "type": "Tipo"
                    },
                    "title": "Dominio :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Hosting de registros habilitado",
                        "mx_records": "Registros MX",
                        "policy": "Modo de directiva"
                    },
                    "title": "Configuración MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Descripción",
                        "mechanism": "Tipo de registro",
                        "order": "Orden",
                        "qualifier": "Clasificatorio récord",
                        "status": "Estado",
                        "value": "Valor del anfitrión"
                    },
                    "title": "Directivas SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Clasificatorio récord de alto nivel",
                        "enable_flattening": "Aplanamiento habilitado"
                    },
                    "title": "Configuración de SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT habilitado",
                        "rua_emails": "Informar correos electrónicos"
                    },
                    "title": "Configuración TLS-RPT :event"
                }
            },
            "header": ":type registros"
        },
        "menu": {
            "account": "Cuenta",
            "domains": "Dominios",
            "home": "Panel",
            "reports": {
                "email_senders": "Remitentes de correo electrónico",
                "failure_reports": "Informes de fallos",
                "reports": "Informes",
                "tls_rpt_reports": "Informes TLS-RPT"
            },
            "settings": {
                "alerts": "Alertas",
                "logout": "Cerrar sesión",
                "settings": "Ajustes"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Hosting MTA-STS deshabilitado",
                "heading": "El hosting MTA-STS está habilitado",
                "message": "Se recomienda que los informes TLS-RPT y el hosting de directivas MTA-STS estén habilitados.",
                "modal": {
                    "header": "Deshabilitar el hosting MTA-STS para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar hosting MTA-STS",
                "heading": "El hosting MTA-STS está deshabilitado",
                "message": "Se recomienda que el hosting de directivas y registros de MTA-STS esté habilitado para administrar sus registros de manera efectiva.",
                "modal": {
                    "header": "Habilite el hosting MTA-STS para :domain",
                    "message": "Al habilitar MTA-STS Hosting, puede administrar su registro y el hosting de su póliza."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Servidores MX"
                },
                "policy": {
                    "input_label": "Nivel de aplicación de directivas",
                    "options": {
                        "enforce": "aplicado",
                        "none": "Ninguno",
                        "testing": "En pruebas"
                    }
                }
            },
            "heading": "hosting de directivas y registros MTA-STS",
            "info": {
                "heading": "¿Qué TLS-RPT y MTA-STS?",
                "message": "TLS-RPT o SMTP TLS Reporting (Informes de seguridad de la capa de transporte) permite que los sistemas de envío compartan estadísticas e información sobre fallos con los dominios del destinatario. MTA-STS (Agentes de transferencia de correo: seguridad de transporte estricta) permite a los proveedores de servicios de correo declarar su capacidad para recibir conexiones SMTP seguras TLS."
            },
            "status": {
                "policy": {
                    "pending": "Directiva pendiente de verificación",
                    "verified": "Directiva verificada"
                },
                "record": {
                    "pending": "Registro pendiente de verificación",
                    "verified": "Registro verificado"
                }
            }
        },
        "partners": {
            "title": "Administrar partners"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurar los ajustes DMARC",
                        "manage_domains": "Administrar dominios"
                    },
                    "description": "Aún no hemos recibido datos de informes para su dominio los datos pueden tardar hasta 72 horas en aparecer. Los informes solo se pueden recuperar para dominios activos y verificados. Administre el resto de sus dominios yendo a la página de dominios.",
                    "header": "No hay datos de informes disponibles"
                },
                "no_reporting_data": {
                    "description": "No hay datos de informes disponibles para el intervalo de fechas seleccionado. Ajuste su selección e inténtelo de nuevo.",
                    "header": "No hay datos de informes disponibles"
                }
            },
            "senders": {
                "unknown": "Desconocida"
            }
        },
        "scoring": {
            "impersonation": {
                "blurb": {
                    "high": "Su dominio actualmente tiene poca o ninguna protección, lo que lo hace vulnerable al uso por parte de ciberdelincuentes en ataques basados en correos electrónicos. Esto podría generar pérdidas financieras, disminución de confianza y daños a la reputación.",
                    "low": "Su organización tiene un riesgo muy bajo de que los ciberdelincuentes usen su dominio para enviar correos electrónicos falsos. Los dominios con una calificación de 5 son 100% compatibles con DMARC y están protegidos contra suplantación de identidad y ataques de phishing.",
                    "moderate": "Es necesario realizar un cambio más para proteger completamente su dominio. Los ciberdelincuentes sofisticados aún podrían suplantar a su empresa y a sus partes interesadas. La entrega de correos electrónicos legítimos podría verse interrumpida y algunos mensajes podrían ir a las carpetas de Spam o Basura."
                },
                "header": "Suplantación de Identidad"
            },
            "marketing": {
                "blurb": {
                    "high": "En este momento, su marca no se muestra en sus correos electrónicos. La marca genera confianza y asegura a los destinatarios que un correo electrónico es auténtico. Recomendamos una implementación completa de BIMI para aumentar el reconocimiento, la visibilidad y la confianza.",
                    "low": "Su organización utiliza eficazmente la marca en los correos electrónicos, haciendo que estos sean más confiables. Su logotipo y la marca verificada (si corresponde) serán visibles para los destinatarios en clientes de correo que admiten BIMI.",
                    "moderate": "La configuración de la marca de sus correos electrónicos necesita algunos ajustes. Es posible que su logotipo y la marca verificada (si corresponde) se muestren en algunos clientes de correo, pero la mayoría requiere una configuración completa de BIMI para mostrar la marca."
                },
                "header": "Marketing"
            },
            "note": "NOTA: Esta es la puntuación actual de su dominio y puede no reflejar los cambios realizados hoy.",
            "overall": {
                "blurb": {
                    "high": "No tiene controles efectivos para proteger su dominio de la suplantación de identidad y la interceptación de la comunicación por correo electrónico. Esto pone en riesgo su marca y a los destinatarios de sus correos electrónicos, reduce la confianza y puede dañar la entregabilidad de los correos electrónicos.",
                    "low": "Ha implementado medidas para proteger a los destinatarios de correos maliciosos enviados desde su dominio. Se debe prestar atención a las áreas donde los controles puedan mejorarse. Estas medidas refuerzan la confianza en su marca, protegen a los destinatarios contra ataques y garantizan una entrega eficaz de los correos.",
                    "moderate": "Ha implementado algunas medidas para proteger a los destinatarios de correos maliciosos enviados desde su dominio. Sin embargo, debe prestar atención a las áreas donde los controles puedan fortalecerse. Estas medidas ayudan a generar confianza en su organización, protegen a los destinatarios de ataques y aseguran una entrega efectiva de correos electrónicos."
                },
                "header": "Puntuación del Dominio"
            },
            "privacy": {
                "blurb": {
                    "high": "Su dominio tiene pocas o ninguna defensa en cuanto a la privacidad del correo electrónico. Existe un alto riesgo de que sus comunicaciones sean interceptadas o comprometidas por usuarios no autorizados. Aunque la mayoría de los remitentes encriptan sus comunicaciones de forma predeterminada, es fundamental hacer cumplir políticas para aquellos que no lo hacen.",
                    "low": "Sus correos electrónicos tienen un bajo riesgo de ser vistos o interceptados por ciberdelincuentes. Su dominio cuenta con estándares de encriptación sólidos y recibirá informes sobre qué tan bien se están aplicando.",
                    "moderate": "Debe reforzar la encriptación de sus correos electrónicos. Si no es segura, sus correos podrían ser interceptados."
                },
                "header": "Privacidad"
            },
            "rating": {
                "high": "Alto riesgo",
                "low": "Bajo riesgo",
                "moderate": "Riesgo moderado"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volumen bloqueado",
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "deliverable": "Entregable",
                "deliverable_volume": "Volumen entregable",
                "ip_addresses": "Direcciones IP",
                "senders": "Remitentes",
                "volume": "Volumen de correo electrónico"
            },
            "categories": {
                "all": "Todos los remitentes",
                "authorised_compliant": "Aprobado",
                "forwarder": "Promotor",
                "suspicious": "Sospechoso",
                "unauthorised": "No aprobado",
                "uncategorized": {
                    "message": "Analizando la fuente...",
                    "tooltip": "Remitente no identificado"
                },
                "unknown": "Ninguno"
            },
            "menu": {
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "reputation": "Reputación",
                "threats": "Amenazas"
            },
            "messages": {
                "category_updated": {
                    "error": "No se pudo actualizar la categoría del remitente",
                    "success": "Categoría de remitente actualizada"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "En la lista negra",
                    "clean": "Limpio",
                    "compliant": "Conforme",
                    "delivered": "Entregado",
                    "failing_dmarc": "DMARC fallido",
                    "forward": "Reenvíos",
                    "not_delivered": "No entregado",
                    "overridden": "Anulado",
                    "overridden_as_delivered": "Anulado tal como se entregó",
                    "overridden_as_not_delivered": "Anulado como no entregado",
                    "passing": "Paso",
                    "passing_dkim": "Pasando solo DKIM",
                    "passing_dkim_spf": "Pasando DKIM y SPF",
                    "passing_dmarc": "Pasando DMARC",
                    "passing_spf": "Pasar solo SPF",
                    "senders": "Remitentes"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "IP de remitente aprobadas",
                        "approved_sender_volume": "Volumen de remitente aprobado",
                        "blacklisted": "En la lista negra",
                        "blacklists": "Listas negras",
                        "blocked_volume": "Volumen bloqueado",
                        "category": {
                            "header": "Categoría",
                            "tooltip": "Categoriza a cada remitente para ordenar los datos de los informes."
                        },
                        "clean": "Limpio",
                        "compliance": "Cumplimiento",
                        "country": "País",
                        "deliverability": "Capacidad de entrega",
                        "delivered": "Entregado",
                        "failing": "Defecto",
                        "forwards": "Reenvíos",
                        "host": "Servidor",
                        "ip": "IP",
                        "ip_addresses": "Direcciones IP",
                        "not_delivered": "No entregado",
                        "passing": "Paso",
                        "sender": "Remitente",
                        "top_blacklisted_senders": "Principales remitentes incluidos en la lista negra",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Cantidad de listas negras en las que se ha incluido la dirección IP"
                    }
                }
            },
            "title": "Remitentes de correo electrónico para :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Agregar nueva directiva SPF",
                "edit_record": {
                    "tooltip": "Editar registro"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva directiva SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directivas",
                        "order": "Orden"
                    }
                },
                "titles": {
                    "allow_a_none": "Permitir registros A para su propio dominio",
                    "allow_a_value": "Permitir registros A para :value",
                    "allow_exists": "Permitir el macro de existencia :value",
                    "allow_include": "Incluir :value",
                    "allow_ip4_address": "Incluir la dirección IPv4 :value",
                    "allow_ip4_addresses": "Incluir las direcciones IPv4 en :value",
                    "allow_ip6_address": "Incluir la dirección IPv6 :value",
                    "allow_ip6_addresses": "Incluir las direcciones IPv6 en :value",
                    "allow_mx_none": "Permitir registros MX para su propio dominio",
                    "allow_mx_value": "Permitir registros MX para :value",
                    "fail_a_none": "Fallar registros A para su propio dominio",
                    "fail_a_value": "Fallar registros A para :value",
                    "fail_exists": "Fallar el macro de existencia :value",
                    "fail_include": "Fallar al incluir :value",
                    "fail_ip4_address": "Fallar la dirección IPv4 :value",
                    "fail_ip4_addresses": "Fallar las direcciones IPv4 en :value",
                    "fail_ip6_address": "Fallar la dirección IPv6 :value",
                    "fail_ip6_addresses": "Fallar las direcciones IPv6 en :value",
                    "fail_mx_none": "Fallar registros MX para su propio dominio",
                    "fail_mx_value": "Fallar registros MX para :value",
                    "neutral_a_none": "Ser neutral en registros A para su propio dominio",
                    "neutral_a_value": "Ser neutral en registros A para :value",
                    "neutral_exists": "Ser neutral en el macro de existencia :value",
                    "neutral_include": "Ser neutral al incluir :value",
                    "neutral_ip4_address": "Ser neutral en la dirección IPv4 :value",
                    "neutral_ip4_addresses": "Ser neutral en las direcciones IPv4 en :value",
                    "neutral_ip6_address": "Ser neutral en la dirección IPv6 :value",
                    "neutral_ip6_addresses": "Ser neutral en las direcciones IPv6 en :value",
                    "neutral_mx_none": "Ser neutral en registros MX para su propio dominio",
                    "neutral_mx_value": "Ser neutral en registros MX para :value",
                    "soft_fail_a_none": "Fallar levemente registros A para su propio dominio",
                    "soft_fail_a_value": "Fallar levemente registros A para :value",
                    "soft_fail_exists": "Fallar levemente el macro de existencia :value",
                    "soft_fail_include": "Fallar levemente al incluir :value",
                    "soft_fail_ip4_address": "Fallar levemente la dirección IPv4 :value",
                    "soft_fail_ip4_addresses": "Fallar levemente las direcciones IPv4 en :value",
                    "soft_fail_ip6_address": "Fallar levemente la dirección IPv6 :value",
                    "soft_fail_ip6_addresses": "Fallar levemente las direcciones IPv6 en :value",
                    "soft_fail_mx_none": "Fallar levemente registros MX para su propio dominio",
                    "soft_fail_mx_value": "Fallar levemente registros MX para :value"
                }
            },
            "edit_drawer": {
                "header": "Editar directiva SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "host": {
                    "example": "p.ej. _spf.ejemplo.com",
                    "input_label": "Valor :type"
                },
                "mechanism": {
                    "input_label": "Tipo de registro"
                },
                "qualifier": {
                    "input_label": "Clasificatorio récord",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (no recomendado)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Actualizar una directiva SPF",
                "use_own_domain": {
                    "input_label": "Dominio del mecanismo"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación SPF completada"
                },
                "header": "Importar registros y directivas SPF existentes para :domain",
                "message": "Todas las directivas existentes serán eliminadas y reemplazadas por directivas importadas."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Clasificatorio récord de alto nivel",
                        "options": {
                            "allow": "+ Pass (no recomendado)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (no recomendado)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+todos Configurado para pasar la autenticación de correos electrónicos de remitentes que no están autorizados.",
                            "-": "Configurado para fallar la autenticación de correos electrónicos de remitentes no autorizados.",
                            "?": "Configurado como neutral, lo que ni aprueba ni falla la autenticación de correos electrónicos de remitentes no autorizados.",
                            "~": "Configurado para fallo leve en la autenticación de correos electrónicos de remitentes no autorizados y tratarlos como sospechosos."
                        }
                    },
                    "flattening": {
                        "input_label": "SPF Flattening",
                        "tooltip": "El aplanamiento de SPF aborda los límites de búsqueda de DNS convirtiendo las directivas include, MX y A en direcciones IP, creando un único registro extendido para evitar restricciones."
                    }
                },
                "heading": "Ajustes"
            },
            "title": "Editar configuración - SPF"
        },
        "tables": {
            "clear": "Claro",
            "not_found": "No se encontraron registros coincidentes",
            "search": "Buscar"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurar los ajustes de TLS-RPT",
                    "description": "Aún no hemos recibido datos de informes para su dominio los datos pueden tardar hasta 72 horas en aparecer. Los informes TLS-RPT solo se pueden recuperar para dominios que tengan habilitados los informes TLS-RPT.",
                    "header": "No hay datos de informes disponibles"
                }
            },
            "heading": "Informes TLS-RPT para :domain",
            "reporters": {
                "heading": "Informes",
                "legend": {
                    "reports": "Informes"
                },
                "table": {
                    "header": {
                        "policy_mode": "Modo de directiva",
                        "policy_type": "Tipo de directiva",
                        "reporter": "Reportero",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Línea de tiempo",
                "legend": {
                    "failure": "Fallido",
                    "success": "Exitoso"
                }
            },
            "title": "Informes TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Deshabilitar informes TLS-RPT",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Informes TLS-RPT deshabilitados para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar informes TLS-RPT",
                "heading": "Los informes TLS-RPT están deshabilitados",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Habilitar informes TLS-RPT para :domain",
                    "message": "Al habilitar los informes TLS-RPT, puede monitorear estadísticas e información sobre fallos en los dominios de los destinatarios."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Los informes se enviarán a"
                }
            },
            "heading": "Informes TLS-RPT",
            "title": "Editar configuración: TLS-RPT"
        },
        "upload": {
            "add": "Agregar archivos",
            "drop_zone": "Arrastre y suelte el archivo aquí para cargarlo.",
            "title": "Subir"
        },
        "users": {
            "email": {
                "edit": "Editar correo electrónico",
                "placeholder": "Introduzca la dirección de correo electrónico"
            },
            "menu": "Usuarios",
            "name": {
                "title": "Nombre"
            },
            "title": "Administrar usuarios"
        },
        "validation": {
            "email": "La dirección de correo electrónico no es válida",
            "invalid_attr": "El :attribute no es válido."
        }
    },
    "fr": {
        "alerts": {
            "actions": {
                "create_activate": "Créer et activer",
                "edit_alert": {
                    "tooltip": "Modifier l'alerte"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Expéditeurs de courrier",
                    "none": "Aucun",
                    "reports": "Rapports",
                    "status": "Statut"
                },
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Activité",
                "options": {
                    "customer-created": {
                        "description": "M'avertir lorsqu'un compte est créé.",
                        "heading": "Client créé"
                    },
                    "customer-deleted": {
                        "description": "M'avertir lorsqu'un compte est supprimé.",
                        "heading": "Client supprimé"
                    },
                    "customer-exposure-report": {
                        "description": "Planifiez le moment où vous souhaitez recevoir le rapport d’exposition.",
                        "heading": "Rapport d'exposition"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planifiez le certificat de conformité DMARC lorsque la stratégie DMARC est définie sur Rejeter.",
                        "heading": "Certificat de conformité DMARC en cas de rejet"
                    },
                    "dns-delegation-unverified": {
                        "description": "Avertissez-moi lorsque la re-vérification de la délégation DNS échoue.",
                        "heading": "Échec de la revérification de l'installation"
                    },
                    "dns-delegation-verified": {
                        "description": "Notifier où le statut de vérification a changé",
                        "heading": "Statut de vérification modifié"
                    },
                    "domain-compliance-drop": {
                        "description": "Avertissez-moi lorsque la conformité descend en dessous d'un seuil.",
                        "heading": "Conformité abandonnée"
                    },
                    "domain-created": {
                        "description": "Avertissez-moi lorsqu'un nouveau domaine est ajouté.",
                        "heading": "Domaine créé"
                    },
                    "domain-deleted": {
                        "description": "M'avertir lorsqu'un domaine est supprimé.",
                        "heading": "Domaine supprimé"
                    },
                    "domain-score-change": {
                        "description": "Avertissez-moi lorsque le score de conformité DMARC a changé.",
                        "heading": "Score de conformité DMARC modifié"
                    },
                    "domain-settings-change": {
                        "description": "Avertissez-moi lorsque les paramètres sont modifiés.",
                        "heading": "Modifications des paramètres"
                    },
                    "sender-threat-level-change": {
                        "description": "Avertissez-moi lorsque le niveau de menace augmente pour les expéditeurs nouveaux ou existants.",
                        "heading": "Niveau de menace augmenté"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Avertissez-moi lorsqu'un nouvel expéditeur généralement approuvé est détecté.",
                        "heading": "Expéditeur autorisé trouvé"
                    }
                }
            },
            "create": {
                "title": "Créer une alerte"
            },
            "edit": {
                "title": "Modifier l'alerte"
            },
            "email": {
                "description": "Spécifiez le destinataire de l'e-mail que vous souhaitez notifier.",
                "heading": "E-mail"
            },
            "email_settings": {
                "heading": "Personnaliser l'e-mail"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Client",
                        "domain": "Domaine"
                    }
                },
                "frequency": {
                    "input_label": "Fréquence"
                },
                "recipients": {
                    "input_label": "Destinataires"
                },
                "scope": {
                    "input_label": "Niveau de surveillance",
                    "listing-title": ":scope Activité",
                    "options": {
                        "customer": "Client",
                        "partner": "Partenaire",
                        "system": "Système"
                    }
                },
                "select_below": {
                    "description": "Vérification de la configuration",
                    "input_label": "Sélectionnez ci-dessous"
                },
                "start_date": {
                    "input_label": "Date de début"
                },
                "status": {
                    "input_label": "Statut"
                },
                "subject_tags": {
                    "example": "par ex. [alerte-gravité][compte][package][domaine]",
                    "input_label": "Activer les préfixes de catégorisation de lignes d'objets"
                }
            },
            "frequency": {
                "description": "Choisissez à quelle fréquence vous souhaitez recevoir cette notification.",
                "disabled-text": "Non disponible - Il s'agit d'une activité basée sur un événement",
                "heading": "Fréquence",
                "options": {
                    "monthly": "Mensuel",
                    "quarterly": "Trimestriel",
                    "six_monthly": "Semestrielle",
                    "two_monthly": "Deux fois par mois",
                    "yearly": "Annuel"
                },
                "starting": "Départ"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerte",
                        "frequency": "Fréquence",
                        "recipients": "Destinataires",
                        "status": "Statut"
                    },
                    "record": {
                        "alert_activity": ":scope Activité"
                    }
                },
                "title": "Alertes"
            },
            "monitor": {
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Moniteur"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Quotidien",
                        "fortnightly": "Bimensuel",
                        "hourly": "Horaire",
                        "immediate": "Immédiat",
                        "monthly": "Mensuel",
                        "quarterly": "Trimestriel",
                        "six_monthly": "Six mois",
                        "two_monthly": "Deux mois",
                        "weekly": "Hebdomadaire",
                        "yearly": "Annuel"
                    }
                }
            },
            "senders": {
                "type": {
                    "hosting": "Hébergement",
                    "isp": "FAI",
                    "mailbox_provider": "Fournisseur de boîte mail",
                    "mail_filter": "Filtre de courrier",
                    "other": "Autre",
                    "sales_and_marketing": "Ventes & Marketing",
                    "social": "Réseaux sociaux",
                    "transactional_mail": "Courrier transactionnel"
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "L'alerte est inactive",
                        "value": "off"
                    },
                    "on": {
                        "tooltip": "L'alerte est active",
                        "value": "on"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Désactiver l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement d'enregistrements BIMI est actuellement activé",
                "message": "Il est recommandé d'activer BIMI Hosting pour gérer facilement votre logo et votre certificat.",
                "modal": {
                    "header": "Désactiver l'hébergement d'enregistrements BIMI pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement BIMI est désactivé",
                "message": "Il est recommandé que l'hébergement BIMI soit activé pour gérer votre logo et votre certificat.",
                "modal": {
                    "header": "Activer l'hébergement d'enregistrements BIMI :domain",
                    "message": "Pour utiliser BIMI, vous avez besoin d'un certificat de marque vérifiée (VMC) ou d'un certificat de marque commune (CMC) pour votre domaine émis par une autorité de certification tierce."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificat CN",
                    "expiry": "Expiration du certificat",
                    "input_label": "Certificat de marque vérifiée BIMI (Type de fichier : pem)",
                    "url": "Autorité\/URL du certificat"
                },
                "image": {
                    "input_label": "Logo BIMI (Type de fichier : svg)",
                    "preview": "Aperçu du logo",
                    "size": "Taille du logo",
                    "url": "URL du logo"
                }
            },
            "info": {
                "heading": "Qu’est-ce que BIMI ?",
                "message": "BIMI (Brand Indicators for Message Identification) est une norme qui facilite l'affichage de votre logo à côté de vos messages dans la boîte de réception, empêche les e-mails frauduleux et facilite la délivrabilité. BIMI travaille aux côtés de DMARC, DKIM et SPF pour vérifier l'authenticité d'un e-mail et contribuer à la visibilité de la marque."
            },
            "settings": {
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Retour aux paramètres",
                "share": "Partager les instructions de configuration",
                "verify": "Vérifier",
                "view": "Afficher les instructions de configuration"
            },
            "bimi": {
                "description": "Mettez à jour votre DNS pour permettre la configuration des clés publiques BIMI de votre domaine.",
                "modal": {
                    "not_configured": {
                        "header": "Instruction de configuration non disponible",
                        "message": "Afin de générer des enregistrements DNS pour votre configuration BIMI, vous devrez télécharger un logo."
                    }
                }
            },
            "buttons": {
                "raw_record": "Utiliser l’enregistrement TXT à la place"
            },
            "description": "Pour activer la surveillance et la gestion via Sendmarc, veuillez mettre en œuvre les modifications suivantes dans les paramètres DNS de votre domaine.",
            "dkim": {
                "description": "Ajoutez les entrées ci-dessous aux enregistrements DNS de votre domaine pour permettre la configuration de ses clés publiques DKIM.",
                "no_nameservers": "La zone de clé publique DKIM est en cours de création, veuillez attendre les instructions de configuration..."
            },
            "dmarc": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique DMARC de votre domaine et pour capturer des rapports sur l'activité d'authentification des e-mails via notre tableau de bord.",
                "warning": "Prenez note des tirets bas (_) requis dans l'hôte et des points dans la valeur d'enregistrement."
            },
            "existing_dns_record_found": "Enregistrement :record existant trouvé",
            "heading": "Instructions de configuration :title",
            "instructions": {
                "add": "Ajouter un enregistrement :rdType",
                "heading": "Instructions de configuration :rdType",
                "new": "Étape 2 : Ajouter un nouvel enregistrement :rdType",
                "remove": "Étape 1 : Supprimer l'enregistrement :rdType existant"
            },
            "mta_policy": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique MTA de votre domaine.",
                "heading": "Politique MTA-STS"
            },
            "mta_record": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement MTA de votre domaine.",
                "heading": "Enregistrement MTA-STS"
            },
            "record": {
                "host": "Hôte",
                "type": "Type d'enregistrement",
                "value": "Valeur"
            },
            "spf": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement SPF de votre domaine via notre plateforme. Notre plateforme garantit la validité de l'enregistrement et utilise une technologie d'optimisation qui atténue le risque d'atteindre les limites de recherche DNS et garantit que votre politique n'est pas trop permissive."
            },
            "status": {
                "last_date": "Dernière vérification le",
                "pending": "En attente de vérification",
                "verified": "Vérifié"
            },
            "tls_rpt": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement TLS-RPT de votre domaine et saisir des rapports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Gérer les clients"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volume d'e-mails",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé et autre"
                    }
                },
                "domain_totals": {
                    "action": "Afficher les domaines",
                    "enforced": "Appliqué",
                    "heading": "Domaines",
                    "reporting": "Rapports",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Afficher les expéditeurs",
                    "heading": "Expéditeurs",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Afficher tous les domaines"
                },
                "legends": {
                    "failing": "Échec du DMARC",
                    "forwards": "Transferts",
                    "passing": "Passer le DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Afficher le rapport"
                    },
                    "header": {
                        "approved": "Approuvé",
                        "domain": "Domaines",
                        "senders": "Expéditeurs",
                        "unapproved": "Non approuvé",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Afficher le rapport de domaine"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Conformité",
                        "domain": "Domaine",
                        "sender": "Expéditeurs approuvés par volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Tableau de bord"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "Aucun serveur de noms correspondant n'a été trouvé.",
                        "mismatch": "Les enregistrements de serveur de noms ne correspondent pas à la configuration attendue.",
                        "missing": "Au moins deux enregistrements de serveur de noms doivent être présents."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "Le mode d'alignement DKIM ne correspond pas à la configuration.",
                        "missing": "Le mode d'alignement DKIM est absent mais n'est pas configuré sur la valeur par défaut."
                    },
                    "aspf": {
                        "mismatch": "Le mode d'alignement SPF ne correspond pas à la configuration.",
                        "missing": "Le mode d'alignement SPF est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "cname": {
                        "mismatch": "L'enregistrement CNAME n'a pas été ajouté correctement et ne correspond pas à la configuration attendue.",
                        "partial": "L'enregistrement CNAME n'a pas été ajouté correctement. Cela peut causer des conflits de configuration."
                    },
                    "fo": {
                        "mismatch": "Les options de rapport d'échec ne correspondent pas à la configuration.",
                        "missing": "Les options de rapport d'échec sont absentes mais ne sont pas configurées avec la valeur par défaut."
                    },
                    "p": {
                        "mismatch": "La politique ne correspond pas à la configuration.",
                        "missing": "La politique est absente."
                    },
                    "pct": {
                        "mismatch": "Le pourcentage de la politique ne correspond pas à la configuration.",
                        "missing": "Le pourcentage de la politique est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "rf": {
                        "mismatch": "Le format de rapport d'échec ne correspond pas à la configuration."
                    },
                    "ri": {
                        "mismatch": "L'intervalle de rapport agrégé ne correspond pas à la configuration.",
                        "missing": "L'intervalle de rapport agrégé est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "rua": {
                        "mismatch": "Les destinataires des rapports agrégés ne correspondent pas à la configuration.",
                        "order": "Les destinataires des rapports agrégés ne sont pas dans le bon ordre."
                    },
                    "ruf": {
                        "mismatch": "Les destinataires des rapports d'échec ne correspondent pas à la configuration.",
                        "order": "Les destinataires des rapports d'échec ne sont pas dans le bon ordre."
                    },
                    "sp": {
                        "mismatch": "La politique de sous-domaine ne correspond pas à la configuration.",
                        "missing": "La politique de sous-domaine est absente mais est configurée différemment de la politique de domaine."
                    },
                    "txt": {
                        "reporting-only": "L'enregistrement ne correspond pas à la configuration attendue, mais notre adresse de rapport est présente."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "La valeur TXT ne correspond pas à la valeur attendue."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    },
                    "rua": {
                        "missing": "L'adresse de rapport attendue est absente."
                    }
                }
            },
            "verify": {
                "error": "Échec de la vérification",
                "nxdomain": "Enregistrement introuvable.",
                "success": "Vérification terminée avec succès",
                "timeout": "Temps de vérification expiré"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle clé publique",
                "edit_public_key": {
                    "tooltip": "Modifier la clé publique"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle clé publique"
            },
            "disable": {
                "action": "Gestion DKIM désactivée",
                "heading": "L'hébergement par clé publique DKIM est actuellement activé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Désactiver la gestion DKIM pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "edit_drawer": {
                "header": "Modifier la clé publique"
            },
            "enable": {
                "action": "Activer la gestion DKIM",
                "heading": "L'hébergement de clé publique DKIM est désactivé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Activer la gestion DKIM pour :domain",
                    "message": "En activant la gestion DKIM, vous pouvez gérer facilement vos clés publiques."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Type d'enregistrement"
                },
                "record_value": {
                    "example": "par ex. dkim.exemple.com",
                    "input_label": "Enregistrer la valeur"
                },
                "selector": {
                    "input_label": "Sélecteur"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Importation de la clé publique DKIM terminée"
                },
                "disabled_tooltip": "Les clés publiques DKIM ne peuvent être importées que pour les domaines disposant de rapports.",
                "header": "Réimporter les clés publiques DKIM pour :domain",
                "message": "Toutes les clés publiques existantes seront supprimées et remplacées par des directives importées."
            },
            "info": {
                "heading": "Qu’est-ce que le DKIM ?",
                "message": "Domain Keys Identified Mail est une méthode d'authentification d'e-mail qui permet à un expéditeur de signer numériquement un e-mail à l'aide d'une clé privée associée à son nom de domaine, qui peut être vérifiée par le serveur d'e-mail du destinataire en utilisant une clé publique publiée dans les enregistrements DNS de l'expéditeur. Cela permet de vérifier l'authenticité et l'intégrité de l'e-mail, réduisant ainsi le risque d'attaques de spam et de phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Politique de signature sortante",
                        "options": {
                            "all": "Tous les e-mails",
                            "some": "Quelques e-mails"
                        }
                    },
                    "testing": {
                        "input_label": "Mode test"
                    }
                },
                "heading": "Paramètres de politiques"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "value": "Valeur"
                    }
                }
            },
            "title": "Modifier les paramètres – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Mode d'alignement DKIM"
                    },
                    "options": {
                        "r": "Détendu",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "Mode d'alignement SPF"
                    }
                },
                "heading": "Paramètres d'alignement"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Appliquer une politique de sous-domaine différente"
                    },
                    "percentage": {
                        "input_label": "Pourcentage"
                    },
                    "policy": {
                        "input_label": "Politique DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "Politique de sous-domaine"
                    }
                },
                "heading": "Paramètres de politiques"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Les rapports seront envoyés à"
                    },
                    "interval": {
                        "input_label": "Intervalle de rapport",
                        "options": {
                            "1_day": "Une fois par jour",
                            "1_hour": "Toutes les heures",
                            "2_hours": "Toutes les 2 heures",
                            "4_hours": "Toutes les 4 heures",
                            "6_hours": "Toutes les 6 heures"
                        }
                    }
                },
                "heading": "Rapports agrégés"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Activer",
                    "header": "Activer le rapport d'échec",
                    "message": "Les rapports d’échec vous fourniront des informations supplémentaires sur les e-mails qui n’ont pas passé la validation DMARC. En activant cette fonctionnalité, nous pourrons traiter et afficher les données du rapport, qui peuvent contenir des informations personnelles telles que des adresses e-mail ou le contenu des messages.",
                    "message_disclaimer": "Si les politiques juridiques de votre organisation interdisent l’activation de ce type de rapport, traitez plutôt ces rapports manuellement et spécifiez le destinataire des rapports.",
                    "reject": "Désactiver"
                },
                "fields": {
                    "options": {
                        "input_label": "Options de rapport",
                        "options": {
                            "d": "DKIM échoue quel que soit l'alignement",
                            "s": "SPF échoue quel que soit l’alignement",
                            "0": "SPF ou DKIM échouent et ne s'alignent pas",
                            "1": "SPF et DKIM échouent et ne s'alignent pas"
                        }
                    },
                    "status": {
                        "input_label": "Traitement des rapports",
                        "options": {
                            "disabled": "Désactivé",
                            "enabled": "Activé",
                            "specified": "Destinataires spécifiques"
                        }
                    }
                },
                "heading": "Rapports d'échec"
            },
            "title": "Modifier les paramètres - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificat de conformité DMARC",
                "domain_report": "Rapport de domaine",
                "edit_settings": {
                    "tooltip": "Modifier les paramètres"
                },
                "remove_primary": "Supprimer des favoris",
                "set_as_active": "Définir comme actif",
                "set_as_parked": "Définir comme parqué",
                "set_primary": "Marquer comme favori",
                "sync_dns_records": "Synchroniser les enregistrements DNS",
                "view_logs": "Afficher les journaux"
            },
            "create_drawer": {
                "header": "Ajouter un nouveau domaine"
            },
            "fields": {
                "name": {
                    "example": "par ex. \"exemple.com\" ou \"sous-domaine.exemple.com\"",
                    "input_label": "Nom de domaine"
                },
                "type": {
                    "input_label": "Type de domaine",
                    "options": {
                        "active": "Actif",
                        "parked": "Parqué"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domaine",
                        "policy": "Politique",
                        "score": "Score",
                        "status": "Statut",
                        "type": "Type"
                    }
                },
                "title": "Domaines"
            },
            "logs_drawer": {
                "header": "Domaine"
            },
            "park_modal": {
                "action": "Marquer comme Parké",
                "description": "Les domaines parkés sont destinés aux domaines qui n'envoient pas de courrier électronique. Définir le domaine comme parké modifiera la politique DMARC pour rejeter ainsi que définir le qualificateur de mécanisme SPF sur Échouer tout, ce qui empêchera l'envoi de courrier.",
                "header": "Confirmer le Domaine comme Parké"
            },
            "report_modal": {
                "action": "Générer un lien",
                "fields": {
                    "copy_link": {
                        "input_label": "Copiez le lien ci-dessous pour partager le rapport de domaine"
                    },
                    "date_range": {
                        "input_label": "Intervalle de dates"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Rapport de domaine"
            },
            "settings": {
                "title": "Modifier les paramètres"
            },
            "setup_modal": {
                "action": "Générer un lien",
                "fields": {
                    "copy_link": {
                        "input_label": "Copiez le lien ci-dessous pour partager les instructions de configuration"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Lien vers les instructions de configuration"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Aucun"
                    },
                    "quarantine": {
                        "value": "Quarantaine"
                    },
                    "reject": {
                        "value": "Rejeter"
                    },
                    "tooltip": "Politique appliquée"
                },
                "primary": {
                    "tooltip": "Primaire",
                    "value": "Primaire"
                },
                "score": {
                    "high": {
                        "tooltip": "Risque élevé"
                    },
                    "low": {
                        "tooltip": "Risque faible"
                    },
                    "moderate": {
                        "tooltip": "Risque modéré"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Vérifié, pas de rapport",
                        "tooltip_partial": "Partiellement vérifié, pas de rapport",
                        "value": "Pas de rapport"
                    },
                    "not_verified": {
                        "tooltip": "Non vérifié, pas de rapport",
                        "value": "Non vérifié"
                    },
                    "reporting": {
                        "tooltip": "Vérifié, rapport",
                        "tooltip_partial": "Partiellement vérifié, rapport",
                        "value": "Rapports"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Domaine actif",
                        "value": "Actif"
                    },
                    "parked": {
                        "tooltip": "Domaine parqué",
                        "value": "Parqué"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "par ex. destinataire@exemple.com",
                    "input_label": "Modifier l'e-mail",
                    "placeholder": "Entrez l'adresse e-mail",
                    "subscribed_label": "Abonné",
                    "unsubscribed_label": "Désabonné",
                    "validation_message": "Entrez une adresse e-mail valide"
                }
            }
        },
        "errors": {
            "menu": "Erreur",
            "401": {
                "description": "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
                "title": "401 : Non autorisé"
            },
            "403": {
                "description": "Désolé, il vous est interdit d'accéder à cette page.",
                "title": "403 : Interdit"
            },
            "404": {
                "description": "Désolé, la page que vous recherchez est introuvable.",
                "title": "404 : page introuvable"
            },
            "500": {
                "description": "Oups, quelque chose s'est mal passé sur nos serveurs.",
                "title": "500 : erreur du serveur"
            },
            "502": {
                "description": "Désolé, nos serveurs sont en panne.",
                "title": "502 : Mauvaise passerelle"
            },
            "503": {
                "description": "Désolé, nous effectuons des travaux de maintenance. Veuillez revenir bientôt.",
                "title": "503 : Service indisponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volume d'e-mails",
                "legends": {
                    "failing": "Échec du DMARC",
                    "forward": "Transferts",
                    "passing": "Succès du DMARC"
                }
            },
            "heading": "Rapport de domaine",
            "insights": {
                "exposure": {
                    "compliance": {
                        "configured": "La plupart de votre volume d'e-mails est compatible DMARC",
                        "highly_configured": "Un pourcentage élevé de votre volume d'e-mails n'est pas prêt pour DMARC",
                        "semi_configured": "La majeure partie du volume de vos e-mails échouera à l'authentification DMARC, vous empêchant d'augmenter vos niveaux de protection."
                    },
                    "compliance_type": {
                        "configured": "La majeure partie de votre volume d'e-mails passe par les deux mécanismes d'authentification des e-mails",
                        "dkim": "La majeure partie de votre volume d'e-mails sera transféré automatiquement à l'aide de DKIM",
                        "one_mechanism": "Une grande partie de votre volume d’e-mails n'est validé que par un seul mécanisme d’authentification",
                        "spf": "La plupart de votre volume d'e-mails échouera avec DMARC lors des transferts automatiques"
                    },
                    "dmarc": {
                        "invalid_record": "Votre politique DMARC n'est pas valide et ne peut pas être appliquée, laissant votre domaine exposé aux abus",
                        "no_aggregate_report_addresses": "Vous n'avez pas une visibilité complète sur les e-mails envoyés depuis votre domaine, ce qui rend la configuration plus difficile",
                        "no_failure_report_addresses": "Vous ne recevez pas de rapports d'échec, ce qui rend plus difficile la recherche d'expéditeurs abusant de votre domaine.",
                        "no_record": "Votre domaine ne peut pas appliquer de politique DMARC ni recevoir de rapports, car aucun enregistrement DMARC ne laisse votre domaine exposé aux abus.",
                        "percentage_not_full": "Votre politique DMARC ne s'applique qu'à un pourcentage d'e-mails et vous ne recevrez peut-être pas de rapports sur tous les volumes.",
                        "percentage_zero": "Votre politique DMARC n'est appliquée à aucun e-mail et vous ne pouvez pas recevoir de rapports sur tous les volumes.",
                        "policy_none": "Aucune mesure ne sera prise en cas d'e-mail non authentifié provenant de votre domaine, laissant votre domaine ouvert aux abus.",
                        "policy_quarantine": "Les e-mails non authentifiés de votre domaine seront uniquement mis en quarantaine",
                        "sub_domain_policy_none": "Aucune mesure ne sera prise à partir d'e-mails non authentifiés provenant de sous-domaines, laissant votre domaine ouvert aux abus",
                        "sub_domain_policy_quarantine": "Les e-mails non authentifiés provenant de sous-domaines seront uniquement mis en quarantaine",
                        "too_many_records": "Votre domaine comporte trop de politiques DMARC et celles-ci seront ignorées, laissant votre domaine exposé aux abus."
                    },
                    "sender_compliance": {
                        "configured": "Votre principal expéditeur est prêt pour DMARC",
                        "fail_auto_forward": "La plupart des e-mails de votre principal expéditeur échoueront au DMARC lors des transferts automatiques",
                        "less_50": "Votre principal service d'envoi n'est pas correctement configuré",
                        "less_80": "Une grande partie des e-mails de votre principal expéditeur ne sont pas authentifiés",
                        "pass_auto_forward": "La plupart des e-mails de votre principal expéditeur passeront par DMARC lors des transferts automatiques",
                        "semi_configured": "Votre principal expéditeur n'envoie pas d'e-mails entièrement authentifiés"
                    },
                    "sender_exposure": {
                        "configured": "Tous vos principaux expéditeurs sont considérés comme configurés",
                        "less_50": "La plupart de vos principaux expéditeurs sont configurés correctement",
                        "more_50": "La plupart de vos principaux expéditeurs ne sont pas configurés, ce qui laisse votre domaine ouvert aux abus",
                        "none": "Aucun de vos principaux expéditeurs n'est configuré, laissant votre domaine ouvert aux abus"
                    },
                    "spf": {
                        "all_missing": "Les serveurs d'envoi non autorisés seront uniquement signalés comme suspects et non rejetés.",
                        "all_neutral": "Définissez votre enregistrement SPF sur Hard Fail si un expéditeur n'est pas autorisé.",
                        "all_pass": "Serveurs d'envoi non autorisés autorisés à envoyer depuis votre domaine",
                        "all_soft_fail": "Les serveurs d'envoi non autorisés peuvent être autorisés à envoyer depuis votre domaine",
                        "invalid_record": "Votre enregistrement SPF n'est pas valide et ne peut pas être appliqué, laissant votre domaine ouvert aux abus",
                        "no_record": "Votre domaine ne répertorie aucun serveur d'envoi autorisé, ce qui laisse votre domaine ouvert aux abus",
                        "record_contains_errors": "Votre enregistrement SPF contient des erreurs et peut ne pas répertorier tous les expéditeurs autorisés",
                        "recursive_lookup": "Votre enregistrement SPF contient des inclusions récursives, ce qui le rend invalide et laisse votre domaine ouvert aux abus.",
                        "too_many_dns_lookups": "Votre enregistrement SPF contient trop de recherches DNS, ce qui le rend invalide et laisse votre domaine ouvert aux abus.",
                        "too_many_mx_address_records": "Votre enregistrement SPF contient trop d'enregistrements de ressources MX, ce qui le rend invalide et laisse votre domaine ouvert aux abus.",
                        "too_many_records": "Votre domaine contient trop d'enregistrements SPF et ceux-ci seront ignorés, laissant votre domaine exposé aux abus",
                        "too_many_void_lookups": "Votre enregistrement SPF contient trop de recherches nulles, ce qui le rend invalide et laisse votre domaine ouvert aux abus."
                    }
                },
                "recommendations": {
                    "compliance": {
                        "warning": "Pensez à augmenter votre niveau de protection DMARC"
                    },
                    "compliance_type": {
                        "warning": "Implémentez la signature DKIM sur vos serveurs d'envoi"
                    },
                    "dmarc": {
                        "invalid_record": "Corrigez les erreurs dans votre enregistrement DMARC",
                        "no_record": "Ajoutez l'enregistrement DMARC de votre domaine à votre DNS",
                        "no_rua": "Ajoutez les adresses des rapports agrégés DMARC Manager à votre enregistrement DMARC pour commencer à recevoir des rapports",
                        "no_ruf": "Ajoutez les adresses du rapport d'échec DMARC Manager à votre enregistrement DMARC pour un rapport d'erreurs détaillé",
                        "percentage_not_full": "Réglez le pourcentage de politique à 100 % dès que vous pouvez le faire en toute sécurité",
                        "percentage_zero": "Augmentez le pourcentage de politique dans vos paramètres DMARC",
                        "policy_quarantine": "Définissez votre politique DMARC pour qu'elle soit rejetée dès que vous pouvez le faire en toute sécurité",
                        "sub_domain_policy_quarantine": "Définissez la politique DMARC pour que vos sous-domaines soient rejetés dès que vous pouvez le faire en toute sécurité",
                        "too_many_records": "Supprimez les enregistrements DMARC inutiles, vous ne devriez en avoir qu'un seul"
                    },
                    "senders": {
                        "configured": "Tous vos principaux expéditeurs sont configurés",
                        "info": "Utilisez les rapports pour vous assurer que tous les services d'envoi légitimes sont correctement autorisés",
                        "semi_configured": "Utilisez les rapports pour identifier les services qui nécessitent encore une configuration",
                        "zero_configured": "Toutes les sources d'envoi nécessitent une configuration, mettez en œuvre des mécanismes d'autorisation efficaces sur chacune"
                    },
                    "spf": {
                        "all_missing": "Définissez votre enregistrement SPF sur un échec brutal lorsqu'un expéditeur n'est pas autorisé",
                        "all_neutral": "Définissez votre enregistrement SPF sur un échec brutal lorsqu'un expéditeur n'est pas autorisé",
                        "all_pass": "Définissez votre enregistrement SPF sur un échec brutal lorsqu'un expéditeur n'est pas autorisé",
                        "all_soft_fail": "Définissez votre enregistrement SPF sur un échec brutal lorsqu'un expéditeur n'est pas autorisé",
                        "contains_errors": "Corrigez les erreurs dans votre fiche SPF",
                        "high_lookup_count": "Utilisez l'optimisation SPF dans DMARC Manager pour réduire le nombre de recherches DNS",
                        "high_void_lookup_count": "Utilisez l'optimisation SPF dans DMARC Manager pour réduire le nombre d'échecs de recherche",
                        "invalid_record": "Corrigez les erreurs dans votre fiche SPF",
                        "no_record": "Ajoutez un enregistrement SPF pour votre domaine et gérez-le facilement dans DMARC Manager",
                        "recursive_lookup": "Utilisez la gestion SPF dans DMARC Manager pour supprimer toutes les inclusions récursives",
                        "too_many_dns_lookups": "Utilisez l'optimisation SPF dans DMARC Manager pour réduire le nombre de recherches DNS",
                        "too_many_mx_records": "Utilisez l'optimisation SPF dans DMARC Manager pour réduire le nombre de recherches d'enregistrements de ressources dans votre enregistrement SPF",
                        "too_many_records": "Supprimez tous les enregistrements SPF inutiles, vous ne devriez en avoir qu'un seul",
                        "too_many_void_lookups": "Utilisez l'optimisation SPF dans DMARC Manager pour réduire le nombre de recherches inutiles et supprimer toutes les inclusions non valides dans votre enregistrement SPF"
                    }
                }
            },
            "recommendations": {
                "heading": "Recommandations"
            },
            "risk_analysis": {
                "heading": "Analyse des risques"
            },
            "scoring": {
                "note": "Remarque : Le score du domaine est calculé au moment de la génération du rapport et peut ne pas être à jour au moment de la consultation."
            },
            "sender_readiness": {
                "heading": "Préparation DMARC de l'expéditeur",
                "legends": {
                    "configured": {
                        "description": "La préparation DMARC est une mesure de la configuration requise pour mettre en œuvre une conformité totale.",
                        "heading": "Configuré"
                    },
                    "partially": {
                        "description": "Les sources partiellement configurées ont au moins un mécanisme d'autorisation actif, mais une configuration supplémentaire peut être nécessaire.",
                        "heading": "Partiellement configuré"
                    },
                    "unconfigured": {
                        "description": "Les sources illégitimes ou non configurées sont des sources qui envoient des e-mails à partir de votre domaine qui peuvent usurper l'identité de votre marque.",
                        "heading": "Illégitime ou non configuré"
                    }
                }
            },
            "title": "Rapport de domaine pour :domain",
            "top_countries": {
                "heading": "Principaux pays d'origine – Volume d'e-mails"
            },
            "top_senders": {
                "heading": "Principaux expéditeurs – Conformité"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Télécharger le rapport",
                "header": "Rapport d'échec",
                "menu": {
                    "key_properties": "Propriétés clés",
                    "more_info": "Plus d'informations"
                }
            },
            "properties": {
                "authentication_failure": "Échec d'authentification",
                "date": "Date",
                "delivery_results": "Résultats de livraison",
                "dkim_domain": "Domaine DKIM",
                "dkim_header": "En-tête canonique DKIM",
                "dkim_identity": "Identité DKIM",
                "dkim_selector": "Sélecteur DKIM",
                "dmarc_version": "Version DMARC",
                "feedback_type": "Type de commentaires",
                "incidents": "Nombre d'incidents",
                "original_recipients": "Destinataires originaux",
                "reporting_domains": "Domaines signalés",
                "reporting_mta": "MTA de rapporting",
                "reporting_user_agent": "Agent utilisateur de reporting",
                "return_path": "Chemin de retour",
                "source_ip": "Adresse IP source",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Authentification",
                    "date": "Date",
                    "ip_address": "Adresse IP",
                    "result": "Résultat",
                    "return_path": "Chemin de retour"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Livré",
                    "none": "Aucun, accepter",
                    "smg_policy_action": "Politique appliquée",
                    "spam": "Rejeté",
                    "unknown": "Inconnu"
                }
            },
            "title": "Rapports d'échec pour :domain"
        },
        "global": {
            "buttons": {
                "add": "Ajouter",
                "add_new": "Ajouter un nouveau",
                "back": "Dos",
                "cancel": "Annuler",
                "delete": "Supprimer",
                "import": "Importer",
                "next": "Suivant",
                "no": "Non",
                "open_link": "Ouvrir le lien",
                "remove": "Retirer",
                "save": "Enregistrer",
                "save_exit": "Enregistrer et retour",
                "select": "Sélectionner",
                "yes": "Oui"
            },
            "confirm_delete": "Voulez-vous continuer ?",
            "copied": "Copié",
            "created": "Créé",
            "deleted": "Supprimé",
            "fail": "Échec",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Désactivé",
                        "enabled": "Activé"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Off",
                        "on": "On"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "Non",
                        "yes": "Oui"
                    }
                }
            },
            "form": {
                "dirty": {
                    "accept": "Ignorer les modifications",
                    "header": "Vous avez des modifications non sauvegardées",
                    "message": "Vos modifications seront ignorées. Voulez-vous continuer ?",
                    "reject": "Annuler"
                },
                "failed": {
                    "description": "Erreur : Veuillez remplir correctement tous les champs obligatoires.",
                    "title": "Il y a eu des erreurs avec votre formulaire."
                },
                "success": {
                    "title": "Vos modifications ont été enregistrées."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Déconnexion",
            "not_found": "Pas trouvé",
            "pagination": {
                "of": "de",
                "showing": "Affichage",
                "to": "à"
            },
            "pass": "Passer",
            "read_more": "Plus",
            "remove": "Retirer",
            "show_less": "Afficher moins",
            "show_more": "Afficher plus",
            "system": "Système",
            "theme": {
                "dark": "Sombre",
                "light": "Clair"
            },
            "to": "À"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Intervalle de dates",
                "to": "à"
            },
            "menu": {
                "overrides": "Remplacements",
                "overview": "Vue d'ensemble",
                "reporters": "Raporteurs",
                "results": "Résultats"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Politique appliquée \"Aucune\"",
                    "policy_applied_quarantine": "Politique appliquée \"Quarantaine\"",
                    "policy_applied_reject": "Politique appliquée \"Rejeter\""
                },
                "overridden_policy_breakdown": "Répartition de la politique remplacée",
                "volume_overridden_policy": "Volume avec des politiques remplacées"
            },
            "overview": {
                "cards": {
                    "compliance": "Conformité",
                    "deliverability": "Délivrabilité",
                    "volume": "Volume"
                },
                "properties": {
                    "country": "Pays",
                    "host": "HÔTE",
                    "isp": "FAI",
                    "organisation": "Organisation",
                    "sender": "Expéditeur"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Conformité",
                        "envelope_from": "Enveloppe De",
                        "envelope_to": "Enveloppe à",
                        "header_from": "En-tête de",
                        "no_data": "Aucune donnée de raporteur disponible",
                        "percent_of_total": "% du total",
                        "reporter": "Raporteur",
                        "volume": "Volume"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alignement",
                    "error": "Erreur",
                    "fail": "Échec",
                    "failed": "Échec\/non aligné",
                    "none": "Aucun",
                    "pass": "Succès",
                    "passed": "Succès\/Aligné"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Action appliquée",
                    "info": {
                        "tooltip": "Mesure prise par le destinataire"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Livré",
                            "value": "Livré"
                        },
                        "quarantine": {
                            "tooltip": "Mis en quarantaine",
                            "value": "Mis en quarantaine"
                        },
                        "reject": {
                            "tooltip": "Rejeté",
                            "value": "Rejeté"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alignement",
                            "dmarc_via_dkim": "DMARC via DKIM",
                            "results": "Résultats",
                            "selector": "Sélecteur",
                            "signing_domain": "Domaine de signature"
                        },
                        "heading": "Détails sur DKIM"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM non aligné",
                            "no_info": "Aucune information sur DKIM",
                            "yes": "Aligné sur DKIM"
                        },
                        "header": "Résultats du DKIM",
                        "no_details": "Aucun détail de signature DKIM",
                        "pass_tooltips": {
                            "no": "Échec du DKIM",
                            "none": "Aucun DKIM",
                            "yes": "Succès du DKIM"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "La politique :policy a été appliquée par le destinataire"
                        },
                        "header": {
                            "from_domain": "Du domaine"
                        },
                        "heading": "Autres détails"
                    },
                    "results": {
                        "header": "Résultats du DMARC",
                        "pass_tooltips": {
                            "no": "Échec du DMARC",
                            "yes": "Succès du DMARC"
                        }
                    }
                },
                "override_comment": {
                    "header": "Commentaire"
                },
                "override_type": {
                    "header": "Type de remplacement"
                },
                "published_policy": {
                    "header": "Politique publiée",
                    "info": {
                        "tooltip": "Politique DMARC au moment de la validation"
                    }
                },
                "reporter": {
                    "header": "Raporteur"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alignement",
                            "dmarc_via_spf": "DMARC via SPF",
                            "result": "Résultat",
                            "return_path_domain": "Domaine du chemin de retour"
                        },
                        "heading": "Détails sur SPF"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF non aligné",
                            "no_info": "Aucune information sur SPF",
                            "yes": "Aligné sur SPF"
                        },
                        "header": "Résultats du SPF",
                        "no_details": "Aucun détail sur SPF",
                        "pass_tooltips": {
                            "error": "Erreur temporaire du SPF",
                            "no": "Échec du SPF",
                            "none": "Aucun FPS",
                            "yes": "Succès du SPF"
                        }
                    }
                },
                "volume": {
                    "header": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "créé",
                "deleted": "supprimé",
                "restored": "restauré",
                "updated": "mis à jour"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Délégation activée",
                        "nameservers": "Serveurs de noms",
                        "selector": "Sélecteur"
                    },
                    "title": "Paramètres BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "status": "Statut",
                        "ttl": "TTL",
                        "value": "Valeur"
                    },
                    "title": "Clé publique DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Délégation activée",
                        "is_policy_enabled": "Politique activée",
                        "nameservers": "Serveurs de noms",
                        "policy": "Politique"
                    },
                    "title": "Paramètres DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alignement DKIM",
                        "policy": "Politique",
                        "policy_percentage": "Pourcentage de politique",
                        "rua_emails": "Destinataires du rapport global",
                        "rua_interval": "Intervalle de rapport global",
                        "ruf_emails": "Destinataires du rapport d'échec",
                        "ruf_format": "Format du rapport d'échec",
                        "ruf_options": "Options de rapport d'échec",
                        "ruf_status": "État du rapport d'échec",
                        "spf_alignment": "Alignement SPF",
                        "subdomain_policy": "Politique de sous-domaine"
                    },
                    "title": "Paramètres DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Résultat",
                        "started_at": "Commencé à",
                        "status": "Statut",
                        "type": "Taper"
                    },
                    "title": "Vérification de la délégation DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domaine",
                        "is_favorite": "Primaire",
                        "logo": "Logo",
                        "score": "Score de domaine",
                        "type": "Type"
                    },
                    "title": "Domaine :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Hébergement d'enregistrement activé",
                        "mx_records": "Enregistrements MX",
                        "policy": "Mode politique"
                    },
                    "title": "Paramètres MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Type d'enregistrement",
                        "order": "Ordre",
                        "qualifier": "Qualificateur d'enregistrement",
                        "status": "Statut",
                        "value": "Valeur de l'hôte"
                    },
                    "title": "Directives SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Qualificateur de record de haut niveau",
                        "enable_flattening": "Aplatissement activé"
                    },
                    "title": "Paramètres SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT activé",
                        "rua_emails": "Signaler des e-mails"
                    },
                    "title": "Paramètres TLS-RPT :event"
                }
            },
            "header": ":type Journaux"
        },
        "menu": {
            "account": "Compte",
            "domains": "Domaines",
            "home": "Tableau de bord",
            "reports": {
                "email_senders": "Expéditeurs d'e-mails",
                "failure_reports": "Rapports d'échec",
                "reports": "Rapports",
                "tls_rpt_reports": "Rapports TLS-RPT"
            },
            "settings": {
                "alerts": "Alertes",
                "logout": "Déconnexion",
                "settings": "Paramètres"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Hébergement MTA-STS désactivé",
                "heading": "L'hébergement MTA-STS est activé",
                "message": "Il est recommandé d'activer la création de rapports TLS-RPT et l'hébergement de politiques MTA-STS.",
                "modal": {
                    "header": "Désactiver l'hébergement MTA-STS pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement MTA-STS",
                "heading": "L'hébergement MTA-STS est désactivé",
                "message": "Il est recommandé que l’hébergement des enregistrements et des politiques MTA-STS soit activé pour gérer efficacement vos enregistrements.",
                "modal": {
                    "header": "Activer l'hébergement MTA-STS pour :domain",
                    "message": "En activant l'hébergement MTA-STS, vous pouvez gérer votre dossier et l'hébergement de votre police."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Serveurs MX"
                },
                "policy": {
                    "input_label": "Niveau d'application des politiques",
                    "options": {
                        "enforce": "Appliqué",
                        "none": "Aucun",
                        "testing": "En test"
                    }
                }
            },
            "heading": "Hébergement des enregistrements et des politiques MTA-STS",
            "info": {
                "heading": "Qu'est-ce que TLS-RPT et MTA-STS ?",
                "message": "TLS-RPT ou SMTP TLS Reporting (Transport Layer Security Reporting) permet aux systèmes d'envoi de partager des statistiques et des informations sur les échecs avec les domaines destinataires. MTA-STS (Mail Transfer Agents - Strict Transport Security) permet aux fournisseurs de services de messagerie de forcer à accepter uniquement les connexions SMTP entrantes sécurisées via TLS."
            },
            "status": {
                "policy": {
                    "pending": "Politique en attente de vérification",
                    "verified": "Politique vérifiée"
                },
                "record": {
                    "pending": "Enregistrement en attente de vérification",
                    "verified": "Enregistrement vérifié"
                }
            }
        },
        "partners": {
            "title": "Gérer les partenaires"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurer les paramètres DMARC",
                        "manage_domains": "Gérer les domaines"
                    },
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports ne peuvent être récupérés que pour les domaines actifs et vérifiés. Gérez vos domaines dans le module Domaines.",
                    "header": "Aucune donnée de rapport disponible"
                },
                "no_reporting_data": {
                    "description": "Aucune donnée de rapport disponible pour l'intervalle de dates sélectionné. Veuillez ajuster votre sélection et réessayer.",
                    "header": "Aucune donnée de rapport disponible"
                }
            },
            "senders": {
                "unknown": "Inconnu"
            }
        },
        "scoring": {
            "impersonation": {
                "blurb": {
                    "high": "Votre domaine n’a actuellement que peu ou aucune protection, ce qui le rend vulnérable à une utilisation par des cybercriminels dans des attaques par email. Cela pourrait entraîner des pertes financières, une diminution de la confiance et des dommages à votre réputation.",
                    "low": "Votre organisation présente un très faible risque que des cybercriminels utilisent votre domaine pour envoyer des emails frauduleux. Les domaines avec une note de 5 sont 100% conformes à DMARC et protégés contre les attaques de phishing et d’usurpation d’identité.",
                    "moderate": "Un changement supplémentaire est nécessaire pour protéger entièrement votre domaine. Des cybercriminels expérimentés pourraient encore usurper l’identité de votre entreprise et de vos parties prenantes. La délivrabilité des emails légitimes pourrait être perturbée et certains messages pourraient atterrir dans les dossiers de spam ou de courrier indésirable."
                },
                "header": "Usurpation d’Identité"
            },
            "marketing": {
                "blurb": {
                    "high": "Actuellement, votre marque n’est pas affichée avec vos emails. Le branding inspire confiance et garantit aux destinataires qu’un email est authentique. Nous recommandons une implémentation complète de BIMI pour renforcer la reconnaissance, la visibilité et la confiance.",
                    "low": "Votre organisation utilise efficacement le branding des emails, rendant vos emails plus dignes de confiance. Votre logo et votre marque vérifiée (le cas échéant) seront visibles par les destinataires dans les clients de messagerie compatibles avec BIMI.",
                    "moderate": "Les paramètres de branding de vos emails nécessitent quelques ajustements. Votre logo et votre marque vérifiée (le cas échéant) peuvent apparaître dans certains clients de messagerie, mais la plupart nécessitent une configuration BIMI complète pour afficher le branding."
                },
                "header": "Marketing"
            },
            "note": "REMARQUE : Ceci est le score actuel de votre domaine et peut ne pas refléter les changements apportés aujourd’hui.",
            "overall": {
                "blurb": {
                    "high": "Vous n’avez pas de contrôles efficaces en place pour protéger votre domaine contre l’usurpation d’identité et l’interception des communications par email. Cela met votre marque et les destinataires de vos emails en danger, réduit la confiance et peut nuire à la délivrabilité des emails.",
                    "low": "Vous avez mis en place des mesures pour protéger les destinataires des e-mails malveillants provenant de votre domaine. Une attention particulière doit être portée aux endroits où les contrôles peuvent être améliorés. Ces mesures renforcent la confiance dans votre marque, protègent les destinataires contre les attaques et aident à garantir que les e-mails sont distribués efficacement",
                    "moderate": "Vous avez mis en œuvre certaines mesures pour protéger les destinataires contre les emails malveillants envoyés depuis votre domaine. Cependant, il est important de renforcer les contrôles là où cela est nécessaire. Ces mesures contribuent à renforcer la confiance dans votre organisation, protègent les destinataires des attaques et assurent une livraison efficace des emails."
                },
                "header": "Score de Domaine"
            },
            "privacy": {
                "blurb": {
                    "high": "Votre domaine dispose de peu ou pas de défenses en matière de confidentialité des emails. Il existe un risque élevé que vos communications soient interceptées ou compromises par des utilisateurs non autorisés. Bien que la plupart des expéditeurs chiffrent leurs communications par défaut, il est essentiel d’appliquer des politiques pour ceux qui ne le font pas.",
                    "low": "Vos emails présentent un faible risque d’être vus ou interceptés par des cybercriminels. Votre domaine dispose de normes de chiffrement robustes et vous recevrez des rapports sur la manière dont elles sont appliquées.",
                    "moderate": "Vous devez renforcer le chiffrement de vos emails. Si ce n’est pas sécurisé, vos emails pourraient être interceptés."
                },
                "header": "Confidentialité"
            },
            "rating": {
                "high": "Risque élevé",
                "low": "Risque faible",
                "moderate": "Risque modéré"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volume bloqué",
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "deliverable": "Livrable",
                "deliverable_volume": "Volume livrable",
                "ip_addresses": "Adresses IP",
                "senders": "Expéditeurs",
                "volume": "Volume d'e-mails"
            },
            "categories": {
                "all": "Tous les expéditeurs",
                "authorised_compliant": "Approuvé",
                "forwarder": "Transitaire",
                "suspicious": "Suspect",
                "unauthorised": "Non approuvé",
                "uncategorized": {
                    "message": "Analyse de la source en cours...",
                    "tooltip": "Expéditeur non identifié"
                },
                "unknown": "Aucun"
            },
            "menu": {
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "reputation": "Réputation",
                "threats": "Menaces"
            },
            "messages": {
                "category_updated": {
                    "error": "Échec de la mise à jour de la catégorie d'expéditeur",
                    "success": "Catégorie d'expéditeur mise à jour"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Sur liste noire",
                    "clean": "Nettoyer",
                    "compliant": "Conforme",
                    "delivered": "Livré",
                    "failing_dmarc": "Échec du DMARC",
                    "forward": "Transferts",
                    "not_delivered": "Non livré",
                    "overridden": "Remplacé",
                    "overridden_as_delivered": "Remplacé comme Livré",
                    "overridden_as_not_delivered": "Remplacé comme Non livré",
                    "passing": "Valider",
                    "passing_dkim": "Valider le DKIM uniquement",
                    "passing_dkim_spf": "Valider le DKIM et SPF",
                    "passing_dmarc": "Valider le DMARC",
                    "passing_spf": "Valider le SPF uniquement",
                    "senders": "Expéditeurs"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Adresses IP approuvées d'expéditeurs",
                        "approved_sender_volume": "Volume des expéditeurs approuvés",
                        "blacklisted": "Sur liste noire",
                        "blacklists": "Listes noires",
                        "blocked_volume": "Volume bloqué",
                        "category": {
                            "header": "Catégorie",
                            "tooltip": "Catégorisez chaque expéditeur pour trier les données des rapports."
                        },
                        "clean": "Nettoyer",
                        "compliance": "Conformité",
                        "country": "Pays",
                        "deliverability": "Délivrabilité",
                        "delivered": "Livré",
                        "failing": "En échec",
                        "forwards": "Transferts",
                        "host": "Hôte",
                        "ip": "IP",
                        "ip_addresses": "Adresses IP",
                        "not_delivered": "Non livré",
                        "passing": "Passage",
                        "sender": "Expéditeur",
                        "top_blacklisted_senders": "Principaux expéditeurs sur liste noire",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Nombre de listes noires dans lesquelles l'adresse IP a été répertoriée"
                    }
                }
            },
            "title": "Expéditeurs d'e-mails pour :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle directive SPF",
                "edit_record": {
                    "tooltip": "Modifier l'enregistrement"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle directive SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Ordre"
                    }
                },
                "titles": {
                    "allow_a_none": "Autoriser les enregistrements A pour votre propre domaine",
                    "allow_a_value": "Autoriser les enregistrements A pour :value",
                    "allow_exists": "Autoriser le macro existant :value",
                    "allow_include": "Inclure :value",
                    "allow_ip4_address": "Inclure l'adresse IPv4 :value",
                    "allow_ip4_addresses": "Inclure les adresses IPv4 dans :value",
                    "allow_ip6_address": "Inclure l'adresse IPv6 :value",
                    "allow_ip6_addresses": "Inclure les adresses IPv6 dans :value",
                    "allow_mx_none": "Autoriser les enregistrements MX pour votre propre domaine",
                    "allow_mx_value": "Autoriser les enregistrements MX pour :value",
                    "fail_a_none": "Échec des enregistrements A pour votre propre domaine",
                    "fail_a_value": "Échec des enregistrements A pour :value",
                    "fail_exists": "Échec du macro existant :value",
                    "fail_include": "Échec d'inclusion de :value",
                    "fail_ip4_address": "Échec de l'adresse IPv4 :value",
                    "fail_ip4_addresses": "Échec des adresses IPv4 dans :value",
                    "fail_ip6_address": "Échec de l'adresse IPv6 :value",
                    "fail_ip6_addresses": "Échec des adresses IPv6 dans :value",
                    "fail_mx_none": "Échec des enregistrements MX pour votre propre domaine",
                    "fail_mx_value": "Échec des enregistrements MX pour :value",
                    "neutral_a_none": "Être neutre sur les enregistrements A pour votre propre domaine",
                    "neutral_a_value": "Être neutre sur les enregistrements A pour :value",
                    "neutral_exists": "Être neutre sur le macro existant :value",
                    "neutral_include": "Être neutre sur l'inclusion de :value",
                    "neutral_ip4_address": "Être neutre sur l'adresse IPv4 :value",
                    "neutral_ip4_addresses": "Être neutre sur les adresses IPv4 dans :value",
                    "neutral_ip6_address": "Être neutre sur l'adresse IPv6 :value",
                    "neutral_ip6_addresses": "Être neutre sur les adresses IPv6 dans :value",
                    "neutral_mx_none": "Être neutre sur les enregistrements MX pour votre propre domaine",
                    "neutral_mx_value": "Être neutre sur les enregistrements MX pour :value",
                    "soft_fail_a_none": "Échec léger des enregistrements A pour votre propre domaine",
                    "soft_fail_a_value": "Échec léger des enregistrements A pour :value",
                    "soft_fail_exists": "Échec léger du macro existant :value",
                    "soft_fail_include": "Échec léger d'inclusion de :value",
                    "soft_fail_ip4_address": "Échec léger de l'adresse IPv4 :value",
                    "soft_fail_ip4_addresses": "Échec léger des adresses IPv4 dans :value",
                    "soft_fail_ip6_address": "Échec léger de l'adresse IPv6 :value",
                    "soft_fail_ip6_addresses": "Échec léger des adresses IPv6 dans :value",
                    "soft_fail_mx_none": "Échec léger des enregistrements MX pour votre propre domaine",
                    "soft_fail_mx_value": "Échec léger des enregistrements MX pour :value"
                }
            },
            "edit_drawer": {
                "header": "Modifier la directive SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "par ex. _spf.exemple.com",
                    "input_label": "Valeur :type"
                },
                "mechanism": {
                    "input_label": "Type d'enregistrement"
                },
                "qualifier": {
                    "input_label": "Qualificateur d'enregistrement",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (non recommandé)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Mettre à jour une directive Spf",
                "use_own_domain": {
                    "input_label": "Domaine du mécanisme"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Import SPF terminé"
                },
                "header": "Importer l'enregistrement et les directives SPF existants pour :domain",
                "message": "Toutes les directives existantes seront supprimées et remplacées par des directives importées."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Qualificateur de journal de haut niveau",
                        "options": {
                            "allow": "+ Pass (non recommandé)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (non recommandé)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+tout Régler sur valider l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "-": "Régler sur faire échouer l’authentification des e-mails provenant d’expéditeurs non autorisés.",
                            "?": "Régler sur neutre, qui ne valide ni n'échoue l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "~": "Régler sur provoquer un échec logiciel pour l'authentification des e-mails provenant d'expéditeurs non autorisés et les traiter comme suspects."
                        }
                    },
                    "flattening": {
                        "input_label": "SPF Flattening",
                        "tooltip": "L’aplatissement SPF résout les limites de recherche DNS en convertissant les directives include, MX et A en adresses IP, créant ainsi un seul enregistrement étendu pour contourner les restrictions."
                    }
                },
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - SPF"
        },
        "tables": {
            "clear": "Effacer",
            "not_found": "Aucun enregistrement correspondant trouvé",
            "search": "Recherche"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurer les paramètres TLS-RPT",
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports TLS-RPT ne peuvent être récupérés que pour les domaines pour lesquels les rapports TLS-RPT sont activés.",
                    "header": "Aucune donnée de rapport disponible"
                }
            },
            "heading": "Rapports TLS-RPT pour :domain",
            "reporters": {
                "heading": "Rapports",
                "legend": {
                    "reports": "Rapports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Mode politique",
                        "policy_type": "Type de politique",
                        "reporter": "Rapporteur",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Chronologie",
                "legend": {
                    "failure": "Échoué",
                    "success": "Réussi"
                }
            },
            "title": "Rapports TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Désactiver les rapports TLS-RPT",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Rapports TLS-RPT désactivés pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer les rapports TLS-RPT",
                "heading": "La création de rapports TLS-RPT est désactivée",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Activer la création de rapports TLS-RPT pour :domain",
                    "message": "En activant les rapports TLS-RPT, vous pouvez surveiller les statistiques et les informations sur les échecs avec les domaines destinataires."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Les rapports seront envoyés à"
                }
            },
            "heading": "Rapports TLS-RPT",
            "title": "Modifier les paramètres - TLS-RPT"
        },
        "upload": {
            "add": "Ajouter des fichiers",
            "drop_zone": "Glissez-déposez un fichier ici pour l'envoyer.",
            "title": "Envoyer"
        },
        "users": {
            "email": {
                "edit": "Modifier l'e-mail",
                "placeholder": "Entrez l'adresse e-mail"
            },
            "menu": "Utilisateurs",
            "name": {
                "title": "Nom"
            },
            "title": "Gérer les utilisateurs"
        },
        "validation": {
            "email": "Pas une adresse e-mail valide",
            "invalid_attr": "Le :attribute n'est pas valide."
        }
    },
    "ja": {
        "alerts": {
            "actions": {
                "create_activate": "作成 & 有効化",
                "edit_alert": {
                    "tooltip": "アラートを編集"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "設定",
                    "mail_senders": "メール送信者",
                    "none": "なし",
                    "reports": "レポート",
                    "status": "ステータス"
                },
                "description": "監視したいアクティビティを選択してください。",
                "heading": "アクティビティ",
                "options": {
                    "customer-created": {
                        "description": "アカウントが作成された際に通知する",
                        "heading": "顧客が作成されました"
                    },
                    "customer-deleted": {
                        "description": "アカウントが削除された際に通知する",
                        "heading": "顧客が削除されました"
                    },
                    "customer-exposure-report": {
                        "description": "エクスポージャーレポートを受け取るスケジュールを設定",
                        "heading": "エクスポージャーレポート"
                    },
                    "dmarc-reject-certificate": {
                        "description": "DMARC ポリシーが拒否に設定されたときに DMARC コンプライアンス証明書をスケジュールする",
                        "heading": "DMARC コンプライアンス証明書（拒否時）"
                    },
                    "dns-delegation-unverified": {
                        "description": "DNS委任の再確認に失敗した際に通知する",
                        "heading": "再確認の設定に失敗しました"
                    },
                    "dns-delegation-verified": {
                        "description": "確認ステータスが変更された際に通知する",
                        "heading": "確認ステータスが変更されました"
                    },
                    "domain-compliance-drop": {
                        "description": "コンプライアンスがしきい値を下回った際に通知する",
                        "heading": "コンプライアンスが低下しました"
                    },
                    "domain-created": {
                        "description": "新しいドメインが追加された際に通知する",
                        "heading": "ドメインが作成されました"
                    },
                    "domain-deleted": {
                        "description": "ドメインが削除された際に通知する",
                        "heading": "ドメインが削除されました"
                    },
                    "domain-score-change": {
                        "description": "DMARC コンプライアンススコアが変更された際に通知する",
                        "heading": "DMARC コンプライアンススコアが変更されました"
                    },
                    "domain-settings-change": {
                        "description": "設定が変更された際に通知する",
                        "heading": "設定の変更"
                    },
                    "sender-threat-level-change": {
                        "description": "新規または既存の送信者の脅威レベルが上昇した際に通知する",
                        "heading": "脅威レベルが上昇しました"
                    },
                    "typically-authorized-sender-found": {
                        "description": "新しく、一般的に承認された送信者が検出された際に通知する",
                        "heading": "承認済みの送信者が見つかりました"
                    }
                }
            },
            "create": {
                "title": "アラートを作成"
            },
            "edit": {
                "title": "アラートを編集"
            },
            "email": {
                "description": "通知を送るメール受信者を指定",
                "heading": "メール"
            },
            "email_settings": {
                "heading": "メールをカスタマイズ"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "顧客",
                        "domain": "ドメイン"
                    }
                },
                "frequency": {
                    "input_label": "頻度"
                },
                "recipients": {
                    "input_label": "受信者"
                },
                "scope": {
                    "input_label": "監視レベル",
                    "listing-title": ":scope アクティビティ",
                    "options": {
                        "customer": "顧客",
                        "partner": "パートナー",
                        "system": "システム"
                    }
                },
                "select_below": {
                    "description": "検証を設定",
                    "input_label": "以下を選択"
                },
                "start_date": {
                    "input_label": "開始日"
                },
                "status": {
                    "input_label": "ステータス"
                },
                "subject_tags": {
                    "example": "例: [アラートの重大度][アカウント][パッケージ][ドメイン]",
                    "input_label": "件名の分類プレフィックスを有効にする"
                }
            },
            "frequency": {
                "description": "この通知をどの頻度で受け取るかを選択してください",
                "disabled-text": "利用不可 - これはイベントベースのアクティビティです",
                "heading": "頻度",
                "options": {
                    "monthly": "毎月",
                    "quarterly": "四半期ごと",
                    "six_monthly": "6か月ごと",
                    "two_monthly": "2か月ごと",
                    "yearly": "毎年"
                },
                "starting": "開始"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "アラート",
                        "frequency": "頻度",
                        "recipients": "受信者",
                        "status": "ステータス"
                    },
                    "record": {
                        "alert_activity": ":scope Activity"
                    }
                },
                "title": "アラート"
            },
            "monitor": {
                "description": "監視したいアクティビティを選択してください",
                "heading": "監視"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "毎日",
                        "fortnightly": "2週間ごと",
                        "hourly": "毎時",
                        "immediate": "即時",
                        "monthly": "毎月",
                        "quarterly": "四半期ごと",
                        "six_monthly": "6か月ごと",
                        "two_monthly": "2か月ごと",
                        "weekly": "毎週",
                        "yearly": "毎年"
                    }
                }
            },
            "senders": {
                "type": {
                    "hosting": "ホスティング",
                    "isp": "ISP",
                    "mailbox_provider": "メールボックスプロバイダー",
                    "mail_filter": "メールフィルター",
                    "other": "その他",
                    "sales_and_marketing": "セールス＆マーケティング",
                    "social": "ソーシャル",
                    "transactional_mail": "トランザクションメール"
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "アラートは無効",
                        "value": "オフ"
                    },
                    "on": {
                        "tooltip": "アラートは有効",
                        "value": "オン"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "BIMI レコードホスティングを無効にする",
                "heading": "BIMI レコードホスティングは現在有効です",
                "message": "ロゴと証明書を簡単に管理するために、BIMI ホスティングを有効にすることを推奨します",
                "modal": {
                    "header": ":domain の BIMI レコードホスティングを無効にする",
                    "message": "このドメインのすべての設定と構成が削除されます。この操作は元に戻せません。"
                }
            },
            "enable": {
                "action": "BIMI レコードホスティングを有効にする",
                "heading": "BIMI ホスティングが無効になっています",
                "message": "ロゴと証明書を管理するために、BIMI ホスティングを有効にすることを推奨します。",
                "modal": {
                    "header": ":domain の BIMI レコードホスティングを有効にする",
                    "message": "BIMI を使用するには、第三者の認証局から発行された Verified Mark Certificate（VMC）または Common Mark Certificate（CMC）が必要です。"
                }
            },
            "fields": {
                "certificate": {
                    "cn": "証明書 CN",
                    "expiry": "証明書の有効期限",
                    "input_label": "BIMI Verified Mark 証明書（ファイル形式: pem）",
                    "url": "認証局 \/ 証明書 URL"
                },
                "image": {
                    "input_label": "BIMI ロゴ（ファイル形式: svg）",
                    "preview": "ロゴプレビュー",
                    "size": "ロゴサイズ",
                    "url": "ロゴ URL"
                }
            },
            "info": {
                "heading": "BIMI とは？",
                "message": "BIMI（Brand Indicators for Message Identification）は、受信トレイ内のメッセージ横にロゴを表示することによって不正なメールを防ぎ、配信性を向上させるための標準規格です。BIMI は DMARC、DKIM、SPF と連携してメールの真正性を確認し、ブランドの可視性を向上させます。"
            },
            "settings": {
                "heading": "設定"
            },
            "title": "BIMI の設定を編集"
        },
        "configurations": {
            "actions": {
                "back": "設定に戻る",
                "share": "セットアップ手順を共有",
                "verify": "確認",
                "view": "セットアップ手順を表示"
            },
            "bimi": {
                "description": "ドメインの BIMI 公開鍵の設定を許可するために DNS を更新してください。",
                "modal": {
                    "not_configured": {
                        "header": "セットアップ手順が利用できません",
                        "message": "BIMI 設定の DNS レコードを生成するには、ロゴをアップロードする必要があります。"
                    }
                }
            },
            "buttons": {
                "raw_record": "TXT レコードを使用する"
            },
            "description": "DMARC マネージャーを通じて監視および管理を有効にするには、ドメインの DNS 設定に以下の変更を適用してください。",
            "dkim": {
                "description": "ドメインの DKIM 公開鍵を設定できるように、以下のエントリを DNS レコードに追加してください。",
                "no_nameservers": "DKIM 公開鍵ゾーンを作成中です。セットアップ手順をお待ちください..."
            },
            "dmarc": {
                "description": "ドメインの DMARC ポリシーを設定し、ダッシュボードでメール認証活動のレポートを取得できるようにするために、DNS を更新してください。",
                "warning": "ホスト名のアンダースコア（_）やレコード値のピリオド（.）を正確に記録してください。"
            },
            "existing_dns_record_found": "既存の :record レコードが見つかりました",
            "heading": ":title セットアップ手順",
            "instructions": {
                "add": ":rdType レコードを追加",
                "heading": ":rdType セットアップ手順",
                "new": "ステップ 2: 新しい :rdType レコードを追加",
                "remove": "ステップ 1: 既存の :rdType レコードを削除"
            },
            "mta_policy": {
                "description": "ドメインの MTA ポリシー設定を許可するために DNS を更新してください。",
                "heading": "MTA-STS ポリシー"
            },
            "mta_record": {
                "description": "ドメインの MTA レコード設定を許可するために DNS を更新してください。",
                "heading": "MTA-STS レコード"
            },
            "record": {
                "host": "ホスト",
                "type": "レコードタイプ",
                "value": "値"
            },
            "spf": {
                "description": "プラットフォームを通じてドメインの SPF レコード設定を許可するために DNS を更新してください。当プラットフォームはレコードの有効性を確保し、DNS ルックアップ制限に達するリスクを軽減する最適化技術を使用し、ポリシーが過度に許可的でないことを保証します。"
            },
            "status": {
                "last_date": "最終確認日",
                "pending": "確認待ち",
                "verified": "確認済み"
            },
            "tls_rpt": {
                "description": "ドメインの TLS-RPT レコード設定を許可し、レポートを取得するために DNS を更新してください。",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "顧客管理"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "メール通数",
                    "legends": {
                        "approved": "承認済み",
                        "unapproved": "未承認およびその他"
                    }
                },
                "domain_totals": {
                    "action": "ドメインを表示",
                    "enforced": "適用済み",
                    "heading": "ドメイン",
                    "reporting": "レポート",
                    "total": "合計"
                },
                "sender_totals": {
                    "action": "送信者を表示",
                    "heading": "送信者",
                    "legends": {
                        "approved": "承認済み",
                        "unapproved": "未承認"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "すべてのドメインを表示"
                },
                "legends": {
                    "failing": "DMARC に失敗",
                    "forwards": "転送",
                    "passing": "DMARC に成功"
                },
                "table": {
                    "action": {
                        "view_report": "レポートを表示"
                    },
                    "header": {
                        "approved": "承認済み",
                        "domain": "ドメイン",
                        "senders": "送信者",
                        "unapproved": "未承認",
                        "volume": "通数"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "ドメインレポートを表示"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "コンプライアンス",
                        "domain": "ドメイン",
                        "sender": "送信者（通数別の承認済み）",
                        "volume": "通数"
                    }
                }
            },
            "title": "ダッシュボード"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "CNAME の値が期待される値と一致しません。",
                        "missing": "一致する CNAME レコードが見つかりませんでした。"
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "一致するネームサーバーが見つかりませんでした。",
                        "mismatch": "ネームサーバーレコードが期待される設定と一致しません。",
                        "missing": "少なくとも 2 つのネームサーバーレコードが必要です。"
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "DKIM のアライメントモードが設定と一致しません。",
                        "missing": "DKIM のアライメントモードが見つかりませんが、デフォルト設定には構成されていません。"
                    },
                    "aspf": {
                        "mismatch": "SPF のアライメントモードが設定と一致しません。",
                        "missing": "SPF のアライメントモードが見つかりませんが、デフォルト設定には構成されていません。"
                    },
                    "cname": {
                        "mismatch": "CNAME レコードが正しく追加されておらず、期待される設定と一致しません。",
                        "partial": "CNAME レコードが正しく追加されていません。これにより設定の競合が発生する可能性があります。"
                    },
                    "fo": {
                        "mismatch": "障害レポートのオプションが設定と一致しません。",
                        "missing": "障害レポートのオプションが見つかりませんが、デフォルトには設定されていません。"
                    },
                    "p": {
                        "mismatch": "ポリシーが設定と一致しません。",
                        "missing": "ポリシーが見つかりません。"
                    },
                    "pct": {
                        "mismatch": "ポリシーの割合が設定と一致しません。",
                        "missing": "ポリシーの割合が見つかりませんが、デフォルトには設定されていません。"
                    },
                    "rf": {
                        "mismatch": "障害レポートの形式が設定と一致しません。"
                    },
                    "ri": {
                        "mismatch": "集約レポートの間隔が設定と一致しません。",
                        "missing": "集約レポートの間隔が見つかりませんが、デフォルトには設定されていません。"
                    },
                    "rua": {
                        "mismatch": "集約レポートの受信者が設定と一致しません。",
                        "order": "集約レポートの受信者の順序が正しくありません。"
                    },
                    "ruf": {
                        "mismatch": "障害レポートの受信者が設定と一致しません。",
                        "order": "障害レポートの受信者の順序が正しくありません。"
                    },
                    "sp": {
                        "mismatch": "サブドメインポリシーが設定と一致しません。",
                        "missing": "サブドメインポリシーが見つかりませんが、ドメインポリシーとは異なる設定になっています。"
                    },
                    "txt": {
                        "reporting-only": "レコードが期待される設定と一致しませんが、当社のレポートアドレスは含まれています。"
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "CNAME の値が期待される値と一致しません。",
                        "missing": "一致する CNAME レコードが見つかりませんでした。"
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "TXT の値が期待される値と一致しません。"
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "CNAME の値が期待される値と一致しません。",
                        "missing": "一致する CNAME レコードが見つかりませんでした。"
                    },
                    "rua": {
                        "missing": "期待されるレポートアドレスが見つかりません。"
                    }
                }
            },
            "verify": {
                "error": "検証に失敗しました。",
                "nxdomain": "レコードが見つかりませんでした。",
                "success": "検証が正常に完了しました。",
                "timeout": "検証のタイムアウトが発生しました。"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "新しい公開鍵を追加",
                "edit_public_key": {
                    "tooltip": "公開鍵を編集"
                }
            },
            "create_drawer": {
                "header": "新しい公開鍵を追加"
            },
            "disable": {
                "action": "DKIM 管理が無効になっています。",
                "heading": "DKIM 公開鍵ホスティングは現在有効です。",
                "message": "公開鍵を管理しやすくするために、DKIM 管理を有効にすることを推奨します。",
                "modal": {
                    "header": ":domain の DKIM 管理を無効にする",
                    "message": "このドメインのすべての設定と構成が削除されます。この操作は元に戻せません。"
                }
            },
            "edit_drawer": {
                "header": "公開鍵を編集"
            },
            "enable": {
                "action": "DKIM 管理を有効にする",
                "heading": "DKIM 公開鍵ホスティングが無効になっています。",
                "message": "公開鍵を管理しやすくするために、DKIM 管理を有効にすることを推奨します。",
                "modal": {
                    "header": ":domain の DKIM 管理を有効にする",
                    "message": "DKIM 管理を有効にすると、公開鍵を簡単に管理できます。"
                }
            },
            "fields": {
                "description": {
                    "input_label": "説明"
                },
                "record_type": {
                    "input_label": "レコードタイプ"
                },
                "record_value": {
                    "example": "例: dkim.example.com",
                    "input_label": "レコード値"
                },
                "selector": {
                    "input_label": "セレクター"
                }
            },
            "import_modal": {
                "action": "削除 & インポート",
                "complete": {
                    "message": "DKIM 公開鍵のインポートが完了しました"
                },
                "disabled_tooltip": "DKIM 公開鍵は、レポートを持つドメインに対してのみインポートできます。",
                "header": ":domain の DKIM 公開鍵を再インポート",
                "message": "既存の公開鍵はすべて削除され、インポートされた指示に置き換えられます。"
            },
            "info": {
                "heading": "DKIM とは？",
                "message": "Domain Keys Identified Mail（DKIM）は、送信者が自分のドメイン名に関連付けられた秘密鍵を使用してメールにデジタル署名できる電子メール認証方式です。受信者のメールサーバーは、送信者の DNS レコードに公開鍵があるかを確認することで、メールの真正性と完全性を検証できます。これにより、スパムやフィッシング攻撃のリスクを低減できます。"
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "送信時署名ポリシー",
                        "options": {
                            "all": "すべてのメール",
                            "some": "一部のメール"
                        }
                    },
                    "testing": {
                        "input_label": "テストモード"
                    }
                },
                "heading": "ポリシー設定"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "レコードタイプ",
                        "selector": "セレクター",
                        "value": "値"
                    }
                }
            },
            "title": "DKIM 設定を編集"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM アライメントモード"
                    },
                    "options": {
                        "r": "緩和（Relaxed）",
                        "s": "厳格（Strict）"
                    },
                    "spf_mode": {
                        "input_label": "SPF アライメントモード"
                    }
                },
                "heading": "アライメント設定"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "異なるサブドメインポリシーを適用"
                    },
                    "percentage": {
                        "input_label": "割合"
                    },
                    "policy": {
                        "input_label": "DMARC ポリシー"
                    },
                    "subdomain_policy": {
                        "input_label": "サブドメインポリシー"
                    }
                },
                "heading": "ポリシー設定"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "レポートの送信先"
                    },
                    "interval": {
                        "input_label": "レポート間隔",
                        "options": {
                            "1_day": "1日1回",
                            "1_hour": "毎時",
                            "2_hours": "2時間ごと",
                            "4_hours": "4時間ごと",
                            "6_hours": "6時間ごと"
                        }
                    }
                },
                "heading": "集約レポート"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "有効化",
                    "header": "障害レポートを有効化",
                    "message": "障害レポートを有効化すると、DMARC に失敗したメールの詳細情報を取得できます。この機能を有効にすると、レポートデータを処理・表示できるようになり、電子メールアドレスやメッセージ内容などの個人情報が含まれる可能性があります。",
                    "message_disclaimer": "組織の法的ポリシーにより、この種類のレポートを有効にできない場合は、手動で処理し、レポートの受信者を指定してください。",
                    "reject": "無効化"
                },
                "fields": {
                    "options": {
                        "input_label": "レポートオプション",
                        "options": {
                            "d": "アライメントに関係なく DKIM が失敗",
                            "s": "アライメントに関係なく SPF が失敗",
                            "0": "SPF または DKIM が失敗し、アライメントされていない",
                            "1": "SPF と DKIM がともに失敗し、アライメントされていない"
                        }
                    },
                    "status": {
                        "input_label": "レポート処理",
                        "options": {
                            "disabled": "無効",
                            "enabled": "有効",
                            "specified": "特定の受信者"
                        }
                    }
                },
                "heading": "障害レポート"
            },
            "title": "DMARC 設定を編集"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC コンプライアンス証明書",
                "domain_report": "ドメインレポート",
                "edit_settings": {
                    "tooltip": "設定を編集"
                },
                "remove_primary": "お気に入りから削除",
                "set_as_active": "アクティブとして設定",
                "set_as_parked": "パーキングとして設定",
                "set_primary": "プライマリとして設定",
                "view_logs": "ログを表示"
            },
            "create_drawer": {
                "header": "新しいドメインを追加"
            },
            "fields": {
                "name": {
                    "example": "例: 'example.com' または 'subdomain.example.com'",
                    "input_label": "ドメイン名"
                },
                "type": {
                    "input_label": "ドメインタイプ",
                    "options": {
                        "active": "アクティブ",
                        "parked": "パーキング"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "ドメイン",
                        "policy": "ポリシー",
                        "score": "スコア",
                        "status": "ステータス",
                        "type": "タイプ"
                    }
                },
                "title": "ドメイン"
            },
            "logs_drawer": {
                "header": "ドメイン"
            },
            "park_modal": {
                "action": "パーキングとして設定",
                "description": "パーキングドメインはメール送信を行わないドメインです。ドメインをパーキングとして設定すると、DMARC ポリシーが拒否（Reject）に変更され、SPF メカニズムのクオリファイアが「すべて失敗（Fail all）」に設定され、メール送信が防止されます。",
                "header": "ドメインをパーキングとして確認"
            },
            "report_modal": {
                "action": "リンクを生成",
                "fields": {
                    "copy_link": {
                        "input_label": "以下のリンクをコピーしてドメインレポートを共有"
                    },
                    "date_range": {
                        "input_label": "日付範囲"
                    },
                    "expire_link": {
                        "input_label": "リンクの有効期限",
                        "options": {
                            "1_day": "1日",
                            "1_month": "1か月",
                            "1_week": "1週間",
                            "6_hours": "6時間",
                            "12_hours": "12時間",
                            "never": "無期限"
                        }
                    }
                },
                "header": "ドメインレポート"
            },
            "settings": {
                "title": "設定を編集"
            },
            "setup_modal": {
                "action": "リンクを生成",
                "fields": {
                    "copy_link": {
                        "input_label": "以下のリンクをコピーしてセットアップ手順を共有"
                    },
                    "expire_link": {
                        "input_label": "リンクの有効期限",
                        "options": {
                            "1_day": "1日",
                            "1_month": "1か月",
                            "1_week": "1週間",
                            "6_hours": "6時間",
                            "12_hours": "12時間",
                            "never": "無期限"
                        }
                    }
                },
                "header": "セットアップ手順へのリンク"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "なし"
                    },
                    "quarantine": {
                        "value": "隔離（Quarantine）"
                    },
                    "reject": {
                        "value": "拒否（Reject）"
                    },
                    "tooltip": "ポリシー適用済み"
                },
                "primary": {
                    "tooltip": "プライマリ",
                    "value": "プライマリ"
                },
                "score": {
                    "high": {
                        "tooltip": "高リスク"
                    },
                    "low": {
                        "tooltip": "低リスク"
                    },
                    "moderate": {
                        "tooltip": "中リスク"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "確認済み（レポートなし）",
                        "tooltip_partial": "部分的に確認済み（レポートなし）",
                        "value": "レポートなし"
                    },
                    "not_verified": {
                        "tooltip": "未確認（レポートなし）",
                        "value": "未確認"
                    },
                    "reporting": {
                        "tooltip": "確認済み（レポートあり）",
                        "tooltip_partial": "部分的に確認済み（レポートあり）",
                        "value": "レポート中"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "アクティブドメイン",
                        "value": "アクティブ"
                    },
                    "parked": {
                        "tooltip": "パーキングドメイン",
                        "value": "パーキング"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "例: recipient@example.com",
                    "input_label": "メールを編集",
                    "placeholder": "メールアドレスを入力",
                    "subscribed_label": "購読済み",
                    "unsubscribed_label": "購読解除済み",
                    "validation_message": "有効なメールアドレスを入力してください"
                }
            }
        },
        "errors": {
            "menu": "エラー",
            "401": {
                "description": "申し訳ありませんが、このページへのアクセスは許可されていません。",
                "title": "401: 認証が必要です（Unauthorized）"
            },
            "403": {
                "description": "申し訳ありませんが、このページへのアクセスは制限されています。",
                "title": "403: 禁止されています（Forbidden）"
            },
            "404": {
                "description": "申し訳ありませんが、お探しのページが見つかりませんでした。",
                "title": "404: ページが見つかりません（Page Not Found）"
            },
            "500": {
                "description": "申し訳ありませんが、サーバーで問題が発生しました。",
                "title": "500: サーバーエラー（Server Error）"
            },
            "502": {
                "description": "申し訳ありませんが、サーバーがダウンしています。",
                "title": "502: 不正なゲートウェイ（Bad Gateway）"
            },
            "503": {
                "description": "申し訳ありませんが、メンテナンスを実施中です。しばらくしてから再度ご確認ください。",
                "title": "503: サービス利用不可（Service Unavailable）"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "メール通数",
                "legends": {
                    "failing": "DMARC 失敗",
                    "forward": "転送",
                    "passing": "DMARC 成功"
                }
            },
            "heading": "ドメインレポート",
            "insights": {
                "exposure": {
                    "compliance": {
                        "configured": "電子メールの大部分が DMARC に対応しています",
                        "highly_configured": "電子メールの多くが DMARC に対応していません",
                        "semi_configured": "電子メールの大部分が DMARC 認証に失敗し、保護レベルを向上できません"
                    },
                    "compliance_type": {
                        "configured": "電子メールの大部分が両方のメール認証メカニズムを通過しています",
                        "dkim": "電子メールの大部分が DKIM を使用した自動転送を通過します",
                        "one_mechanism": "電子メールの多くが 1 つの認証メカニズムのみを通過しています",
                        "spf": "電子メールの大部分が DMARC 認証に失敗し、自動転送時に問題が発生します"
                    },
                    "dmarc": {
                        "invalid_record": "DMARC ポリシーが無効で適用できず、ドメインが悪用される可能性があります",
                        "no_aggregate_report_addresses": "ドメインから送信されるメールの可視性が不十分で、適切な設定が難しくなっています",
                        "no_failure_report_addresses": "障害レポートを受信しておらず、ドメインの悪用を特定するのが困難です",
                        "no_record": "ドメインには DMARC レコードがなく、DMARC ポリシーの適用やレポートの受信ができず、悪用される可能性があります",
                        "percentage_not_full": "DMARC ポリシーが一部のメールにしか適用されておらず、すべてのレポートを受信できてない可能性があります",
                        "percentage_zero": "DMARC ポリシーが適用されていないため、未認証のメールに対して何の処置も取られず、ドメインが悪用される可能性があります",
                        "policy_none": "未認証のメールに対して何の処置も取られず、ドメインが悪用される可能性があります。",
                        "policy_quarantine": "ドメインから送信された未認証のメールは、隔離されるだけです。",
                        "sub_domain_policy_none": "サブドメインからの未認証メールに対して何の処置も取られず、ドメインが悪用される可能性があります。",
                        "sub_domain_policy_quarantine": "サブドメインからの未認証メールは、隔離されるだけです。",
                        "too_many_records": "ドメインに DMARC ポリシーが多すぎるため、無視され、ドメインが悪用される可能性があります。"
                    },
                    "sender_compliance": {
                        "configured": "主要送信者は DMARC に対応しています。",
                        "fail_auto_forward": "主要送信者からのメールのほとんどが、自動転送時に DMARC で失敗します。",
                        "less_50": "主要な送信サービスが正しく構成されていません。",
                        "less_80": "主要送信者からのメールの多くが認証されていません。",
                        "pass_auto_forward": "主要送信者からのメールのほとんどが、自動転送時に DMARC で成功します。",
                        "semi_configured": "主要送信者は完全に認証されたメールを送信していません。"
                    },
                    "sender_exposure": {
                        "configured": "すべての主要送信者が正しく構成されています。",
                        "less_50": "ほとんどの主要送信者が正しく構成されています。",
                        "more_50": "ほとんどの主要送信者が構成されておらず、ドメインが悪用される可能性があります。",
                        "none": "主要送信者が 1 人も構成されておらず、ドメインが悪用される可能性があります。"
                    },
                    "spf": {
                        "all_missing": "許可されていない送信サーバーは、疑わしいとフラグ付けされるだけで、拒否されません。",
                        "all_neutral": "送信者が許可されていない場合、SPF レコードをハードフェイルに設定してください。",
                        "all_pass": "許可されていない送信サーバーがドメインから送信を許可されています。",
                        "all_soft_fail": "許可されていない送信サーバーがドメインから送信を許可される可能性があります。",
                        "invalid_record": "SPF レコードが無効で適用できず、ドメインが悪用される可能性があります。",
                        "no_record": "ドメインには、許可された送信サーバーがリストされておらず、悪用される可能性があります。",
                        "record_contains_errors": "SPF レコードにエラーが含まれており、すべての許可済み送信者をリストしていない可能性があります。",
                        "recursive_lookup": "SPF レコードに再帰的な `include` が含まれており、無効になっていてドメインが悪用される可能性があります。",
                        "spf_reporting": "SPF レポートは DMARC レポートにより廃止されました。",
                        "too_many_dns_lookups": "SPF レコードの DNS ルックアップ数が多すぎて無効になり、ドメインが悪用される可能性があります。",
                        "too_many_mx_address_records": "SPF レコードの MX リソースレコードが多すぎて無効になり、ドメインが悪用される可能性があります。",
                        "too_many_records": "ドメインに SPF レコードが多すぎるため、無視され、ドメインが悪用される可能性があります。",
                        "too_many_void_lookups": "SPF レコードの無効なルックアップが多すぎて、ドメインが悪用される可能性があります。"
                    }
                },
                "recommendations": {
                    "compliance": {
                        "warning": "DMARC 保護レベルの引き上げを検討してください。"
                    },
                    "compliance_type": {
                        "warning": "送信サーバーに DKIM 署名を実装してください。"
                    },
                    "dmarc": {
                        "invalid_record": "DMARC レコードのエラーを修正してください。",
                        "no_record": "ドメインの DNS に DMARC レコードを追加してください。",
                        "no_rua": "DMARC マネージャーの集約レポートアドレスを DMARC レコードに追加し、レポートの受信を開始してください。",
                        "no_ruf": "DMARC マネージャーの障害レポートアドレスを DMARC レコードに追加し、詳細なエラー報告を取得してください。",
                        "percentage_not_full": "ポリシー適用割合を 100% に設定し、安全が確認でき次第適用してください。",
                        "percentage_zero": "DMARC 設定のポリシー適用割合を増やしてください。",
                        "policy_quarantine": "DMARC ポリシーを「拒否」に設定し、安全が確認でき次第適用してください。",
                        "sub_domain_policy_quarantine": "サブドメインの DMARC ポリシーを「拒否」に設定し、安全が確認でき次第適用してください。",
                        "too_many_records": "不要な DMARC レコードを削除し、1 つのレコードに統合してください。"
                    },
                    "senders": {
                        "configured": "すべての主要送信者が正しく構成されています。",
                        "info": "レポートを活用し、すべての正当な送信サービスが適切に認可されていることを確認してください。",
                        "semi_configured": "レポートを使用して、まだ設定が必要なサービスを特定してください。",
                        "zero_configured": "すべての送信ソースに対し、適切な認可メカニズムを実装してください。"
                    },
                    "spf": {
                        "all_missing": "送信者が許可されていない場合、SPF レコードをハードフェイルに設定してください。",
                        "all_neutral": "送信者が許可されていない場合、SPF レコードをハードフェイルに設定してください。",
                        "all_pass": "送信者が許可されていない場合、SPF レコードをハードフェイルに設定してください。",
                        "all_soft_fail": "送信者が許可されていない場合、SPF レコードをハードフェイルに設定してください。",
                        "contains_errors": "SPF レコードのエラーを修正してください。",
                        "high_lookup_count": "SPF 最適化を利用して、DNS ルックアップ数を減らしてください。",
                        "high_void_lookup_count": "SPF 最適化を利用して、失敗するルックアップ数を減らしてください。",
                        "invalid_record": "SPF レコードのエラーを修正してください。",
                        "no_record": "自ドメイン用の SPF レコードを追加し、DMARC マネージャー内で簡単に管理してください。",
                        "recursive_lookup": "DMARC マネージャーの SPF 管理を使用して、再帰的な `include` を削除してください。",
                        "too_many_dns_lookups": "DMARC マネージャーの SPF 最適化を使用して、DNS ルックアップ数を削減してください。",
                        "too_many_mx_records": "DMARC マネージャーの SPF 最適化を使用して、SPF レコードのリソースレコード数を削減してください。",
                        "too_many_records": "不要な SPF レコードを削除し、1 つのレコードに統合してください。",
                        "too_many_void_lookups": "Sendmarc の SPF 最適化を利用して、不要なルックアップ数を削減し、無効な `include` を削除してください。"
                    }
                }
            },
            "recommendations": {
                "heading": "推奨事項"
            },
            "risk_analysis": {
                "heading": "リスク分析"
            },
            "scoring": {
                "note": "注: ドメインスコアはレポート作成時に計算されており、表示時点では最新でない可能性があります。"
            },
            "sender_readiness": {
                "heading": "送信者の DMARC 適合性",
                "legends": {
                    "configured": {
                        "description": "DMARC 適合性は、完全準拠を実装するために必要な設定の指標です。",
                        "heading": "設定済み"
                    },
                    "partially": {
                        "description": "部分的に設定された送信元には、少なくとも 1 つの認証メカニズムがアクティブですが、追加の設定が必要な場合があります。",
                        "heading": "部分的に設定済み"
                    },
                    "unconfigured": {
                        "description": "不正または未設定の送信元は、あなたのドメインからメールを送信している可能性があり、ブランドのなりすましの可能性があります。",
                        "heading": "不正または未設定"
                    }
                }
            },
            "title": ":domain のドメインレポート",
            "top_countries": {
                "heading": "主要な送信元国 - メール通数"
            },
            "top_senders": {
                "heading": "主要送信者 - コンプライアンス"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "レポートをダウンロード",
                "header": "障害レポート",
                "menu": {
                    "key_properties": "主要プロパティ",
                    "more_info": "詳細情報"
                }
            },
            "properties": {
                "authentication_failure": "認証失敗",
                "date": "日付",
                "delivery_results": "配信結果",
                "dkim_domain": "DKIM ドメイン",
                "dkim_header": "DKIM 正規化ヘッダー",
                "dkim_identity": "DKIM アイデンティティ",
                "dkim_selector": "DKIM セレクター",
                "dmarc_version": "DMARC バージョン",
                "feedback_type": "フィードバックタイプ",
                "incidents": "インシデント数",
                "original_recipients": "元の受信者",
                "reporting_domains": "報告されたドメイン",
                "reporting_mta": "報告 MTA",
                "reporting_user_agent": "報告ユーザーエージェント",
                "return_path": "リターンパス",
                "source_ip": "送信元 IP アドレス",
                "spf_dns": "SPF DNS"
            },
            "table": {
                "header": {
                    "authentication": "認証",
                    "date": "日付",
                    "ip_address": "IP アドレス",
                    "result": "結果",
                    "return_path": "リターンパス"
                }
            },
            "tags": {
                "result": {
                    "delivered": "配信済み",
                    "none": "なし、承認",
                    "smg_policy_action": "ポリシー適用済み",
                    "spam": "拒否",
                    "unknown": "不明"
                }
            },
            "title": ":domain の障害レポート"
        },
        "global": {
            "buttons": {
                "add": "追加",
                "add_new": "新規追加",
                "back": "戻る",
                "cancel": "キャンセル",
                "delete": "削除",
                "import": "インポート",
                "next": "次へ",
                "no": "いいえ",
                "open_link": "リンクを開く",
                "remove": "削除",
                "save": "保存",
                "save_exit": "保存して戻る",
                "select": "選択",
                "yes": "はい"
            },
            "confirm_delete": "続行しますか？",
            "copied": "コピー済み",
            "created": "作成済み",
            "deleted": "削除済み",
            "fail": "失敗",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "無効",
                        "enabled": "有効"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "オフ",
                        "on": "オン"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "いいえ",
                        "yes": "はい"
                    }
                }
            },
            "form": {
                "dirty": {
                    "accept": "変更を破棄",
                    "header": "保存されていない変更があります",
                    "message": "変更が破棄されます。続行しますか？",
                    "reject": "キャンセル"
                },
                "failed": {
                    "description": "エラー: 必須項目をすべて正しく入力してください。",
                    "title": "フォームにエラーがあります。"
                },
                "success": {
                    "title": "変更が保存されました。"
                }
            },
            "graphs": {
                "legends": {
                    "volume": "通数"
                }
            },
            "logout": "ログアウト",
            "not_found": "見つかりません",
            "pagination": {
                "of": "の",
                "showing": "表示中",
                "to": "から"
            },
            "pass": "合格",
            "read_more": "さらに",
            "remove": "削除",
            "show_less": "表示を減らす",
            "show_more": "さらに表示",
            "system": "システム",
            "theme": {
                "dark": "ダーク",
                "light": "ライト"
            },
            "to": "へ"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "日付範囲",
                "to": "から"
            },
            "menu": {
                "overrides": "オーバーライド",
                "overview": "概要",
                "reporters": "レポーター",
                "results": "結果"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "適用ポリシー「なし」",
                    "policy_applied_quarantine": "適用ポリシー「隔離」",
                    "policy_applied_reject": "適用ポリシー「拒否」"
                },
                "overridden_policy_breakdown": "オーバーライドされたポリシーの内訳",
                "volume_overridden_policy": "オーバーライドされたポリシーを含む通数"
            },
            "overview": {
                "cards": {
                    "compliance": "コンプライアンス",
                    "deliverability": "配信性",
                    "volume": "通数"
                },
                "properties": {
                    "country": "国",
                    "host": "ホスト",
                    "isp": "ISP",
                    "organisation": "組織",
                    "sender": "送信者"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "コンプライアンス",
                        "envelope_from": "送信元（Envelope From）",
                        "envelope_to": "送信先（Envelope To）",
                        "header_from": "ヘッダー送信元（Header From）",
                        "no_data": "利用可能なレポーター データなし",
                        "percent_of_total": "合計の %",
                        "reporter": "レポーター",
                        "volume": "通数"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "アライメント",
                    "error": "エラー",
                    "fail": "失敗",
                    "failed": "失敗 \/ 非アライメント",
                    "none": "なし",
                    "pass": "合格",
                    "passed": "合格 \/ アライメント済み"
                }
            },
            "table": {
                "action_applied": {
                    "header": "適用されたアクション",
                    "info": {
                        "tooltip": "受信者によるアクション"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "配信済み",
                            "value": "配信済み"
                        },
                        "quarantine": {
                            "tooltip": "隔離済み",
                            "value": "隔離済み"
                        },
                        "reject": {
                            "tooltip": "拒否",
                            "value": "拒否"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "アライメント",
                            "dmarc_via_dkim": "DKIM 経由の DMARC",
                            "results": "結果",
                            "selector": "セレクター",
                            "signing_domain": "署名ドメイン"
                        },
                        "heading": "DKIM 詳細"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM 非アライメント",
                            "no_info": "DKIM 情報なし",
                            "yes": "DKIM アライメント済み"
                        },
                        "header": "DKIM 結果",
                        "no_details": "DKIM 署名の詳細なし",
                        "pass_tooltips": {
                            "no": "DKIM 失敗",
                            "none": "DKIM なし",
                            "yes": "DKIM 合格"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "受信者により適用されたポリシー ':policy'"
                        },
                        "header": {
                            "from_domain": "送信元ドメイン"
                        },
                        "heading": "その他の詳細"
                    },
                    "results": {
                        "header": "DMARC 結果",
                        "pass_tooltips": {
                            "no": "DMARC 失敗",
                            "yes": "DMARC 合格"
                        }
                    }
                },
                "override_comment": {
                    "header": "コメント"
                },
                "override_type": {
                    "header": "オーバーライドタイプ"
                },
                "published_policy": {
                    "header": "公開ポリシー",
                    "info": {
                        "tooltip": "検証時点の DMARC ポリシー"
                    }
                },
                "reporter": {
                    "header": "レポーター"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "アライメント",
                            "dmarc_via_spf": "SPF 経由の DMARC",
                            "result": "結果",
                            "return_path_domain": "リターンパスドメイン"
                        },
                        "heading": "SPF 詳細"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF 非アライメント",
                            "no_info": "SPF 情報なし",
                            "yes": "SPF アライメント済み"
                        },
                        "header": "SPF 結果",
                        "no_details": "SPF 詳細なし",
                        "pass_tooltips": {
                            "error": "SPF 一時的なエラー",
                            "no": "SPF 失敗",
                            "none": "SPF なし",
                            "yes": "SPF 合格"
                        }
                    }
                },
                "volume": {
                    "header": "通数"
                }
            }
        },
        "logs": {
            "events": {
                "created": "作成済み",
                "deleted": "削除済み",
                "restored": "復元済み",
                "updated": "更新済み"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "証明書",
                        "image_path": "ロゴ",
                        "is_delegation_enabled": "委任有効",
                        "nameservers": "ネームサーバー",
                        "selector": "セレクター"
                    },
                    "title": "BIMI 設定 :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "説明",
                        "record_type": "レコードタイプ",
                        "selector": "セレクター",
                        "status": "ステータス",
                        "ttl": "TTL",
                        "value": "値"
                    },
                    "title": "DKIM 公開鍵 :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "委任有効",
                        "is_policy_enabled": "ポリシー有効",
                        "nameservers": "ネームサーバー",
                        "policy": "ポリシー"
                    },
                    "title": "DKIM 設定 :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM アライメント",
                        "policy": "ポリシー",
                        "policy_percentage": "ポリシー割合",
                        "rua_emails": "集約レポート受信者",
                        "rua_interval": "集約レポート間隔",
                        "ruf_emails": "障害レポート受信者",
                        "ruf_format": "障害レポート形式",
                        "ruf_options": "障害レポートオプション",
                        "ruf_status": "障害レポートステータス",
                        "spf_alignment": "SPF アライメント",
                        "subdomain_policy": "サブドメインポリシー"
                    },
                    "title": "DMARC 設定 :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "メッセージ",
                        "result": "結果",
                        "started_at": "開始時刻",
                        "status": "ステータス",
                        "type": "タイプ"
                    },
                    "title": "DNS 委任検証 :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "ドメイン",
                        "is_favorite": "プライマリ",
                        "logo": "ロゴ",
                        "score": "ドメインスコア",
                        "type": "タイプ"
                    },
                    "title": "ドメイン :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "レコードホスティング有効",
                        "mx_records": "MX レコード",
                        "policy": "ポリシーモード"
                    },
                    "title": "MTA-STS 設定 :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "説明",
                        "mechanism": "レコードタイプ",
                        "order": "順序",
                        "qualifier": "レコード修飾子",
                        "status": "ステータス",
                        "value": "ホスト値"
                    },
                    "title": "SPF 指令 :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "最上位レコード修飾子",
                        "enable_flattening": "フラッテン有効"
                    },
                    "title": "SPF 設定 :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT 有効",
                        "rua_emails": "レポートメール"
                    },
                    "title": "TLS-RPT 設定 :event"
                }
            },
            "header": ":type ログ"
        },
        "menu": {
            "account": "アカウント",
            "domains": "ドメイン",
            "home": "ダッシュボード",
            "reports": {
                "email_senders": "メール送信者",
                "failure_reports": "障害レポート",
                "reports": "レポート",
                "tls_rpt_reports": "TLS-RPT レポート"
            },
            "settings": {
                "alerts": "アラート",
                "logout": "ログアウト",
                "settings": "設定"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "MTA-STS ホスティング無効",
                "heading": "MTA-STS ホスティング有効",
                "message": "TLS-RPT レポートと MTA-STS ポリシーホスティングを有効にすることを推奨します。",
                "modal": {
                    "header": ":domain の MTA-STS ホスティングを無効にする",
                    "message": "このドメインのすべての設定と構成が削除されます。この操作は元に戻せません。"
                }
            },
            "enable": {
                "action": "MTA-STS ホスティングを有効にする",
                "heading": "MTA-STS ホスティング無効",
                "message": "MTA-STS レコードとポリシーホスティングを有効にして、レコードを適切に管理することを推奨します。",
                "modal": {
                    "header": ":domain の MTA-STS ホスティングを有効にする",
                    "message": "MTA-STS ホスティングを有効にすると、レコードとポリシーのホスティングを管理できます。"
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX サーバー"
                },
                "policy": {
                    "input_label": "ポリシー施行レベル",
                    "options": {
                        "enforce": "施行済み",
                        "none": "なし",
                        "testing": "テスト中"
                    }
                }
            },
            "heading": "MTA-STS レコード & ポリシーホスティング",
            "info": {
                "heading": "TLS-RPT と MTA-STS とは？",
                "message": "TLS-RPT（SMTP TLS レポート）は、送信システムが受信ドメインと共有する統計や障害情報を提供します。MTA-STS（メール転送エージェント - 厳格転送セキュリティ）は、電子メールサービスプロバイダーが、受信 SMTP 接続が TLS で保護されている場合のみ受け入れるよう強制できます。"
            },
            "status": {
                "policy": {
                    "pending": "ポリシー検証待ち",
                    "verified": "ポリシー検証済み"
                },
                "record": {
                    "pending": "レコード検証待ち",
                    "verified": "レコード検証済み"
                }
            }
        },
        "partners": {
            "title": "パートナー管理"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "DMARC 設定を構成",
                        "manage_domains": "ドメインを管理"
                    },
                    "description": "ドメインのレポートデータがまだ受信されていません。データが反映されるまで最大 72 時間かかる場合があります。レポートはアクティブで検証済みのドメインに対してのみ取得できます。ドメインの管理は「ドメイン」モジュールで行えます。",
                    "header": "レポートデータなし"
                },
                "no_reporting_data": {
                    "description": "選択した日付範囲に対するレポートデータがありません。範囲を調整して再試行してください。",
                    "header": "レポートデータなし"
                }
            },
            "senders": {
                "unknown": "不明"
            }
        },
        "scoring": {
            "impersonation": {
                "blurb": {
                    "high": "ドメインには現在、ほとんど保護がなく、サイバー犯罪者による電子メール攻撃の標的になる可能性があります。これにより、財務的損失、信頼の低下、評判の損失につながる可能性があります。",
                    "low": "組織のドメインは、サイバー犯罪者が偽のメールを送信するリスクが非常に低い状態です。評価スコア 5 のドメインは 100% DMARC 準拠であり、なりすましやフィッシング攻撃から保護されています。",
                    "moderate": "ドメインを完全に保護するには、もう 1 つの変更が必要です。巧妙なサイバー犯罪者は、依然としてあなたのビジネスや関係者になりすます可能性があります。正当なメールの配信性が低下し、一部のメッセージがスパムまたは迷惑メールフォルダーに入る可能性があります。"
                },
                "header": "なりすまし"
            },
            "marketing": {
                "blurb": {
                    "high": "現在、電子メールにブランド情報が表示されていません。ブランド情報は信頼を構築し、メールが本物であることを受信者に保証します。認知度、可視性、信頼性を向上させるために、BIMI の完全実装を推奨します。",
                    "low": "組織では、電子メールのブランド化が効果的に行われており、電子メールがより信頼性の高いものになっています。ロゴと認証マーク（該当する場合）が、BIMI をサポートする電子メールクライアントで受信者に表示されます。",
                    "moderate": "電子メールのブランド設定を少し調整する必要があります。ロゴと認証マーク（該当する場合）は、一部の電子メールクライアントで表示されますが、多くの場合、BIMI の完全設定が必要です。"
                },
                "header": "マーケティング"
            },
            "note": "注: これは現在のドメインスコアであり、今日行った変更が反映されていない可能性があります。",
            "overall": {
                "blurb": {
                    "high": "ドメインのなりすましや電子メール通信の傍受を防ぐための有効な対策が講じられていません。これにより、ブランドと電子メールの受信者が攻撃のリスクにさらされ、信頼が損なわれ、メール配信性が低下する可能性があります。",
                    "low": "受信者を悪意のあるメールから保護するための対策が実施されていますが、改善できる分野に注意を払う必要があります。これらの対策は、ブランドの信頼性を向上させ、受信者を攻撃から保護し、電子メールの配信を効果的にするのに役立ちます。",
                    "moderate": "ドメインから送信される悪意のあるメールから受信者を保護するための対策が一部実施されています。しかし、セキュリティ対策を強化できる部分に注意を払う必要があります。これらの対策は、組織の信頼性を向上させ、受信者を攻撃から保護し、メールの配信性を確保するのに役立ちます。"
                },
                "header": "ドメインスコア"
            },
            "privacy": {
                "blurb": {
                    "high": "ドメインには電子メールのプライバシー保護がほとんどまたはまったく実装されていません。これにより、通信が不正アクセスや傍受されるリスクが高まります。ほとんどの送信者はデフォルトで暗号化を使用しますが、未対応の送信者に対してポリシーを適用することが重要です。",
                    "low": "電子メールがサイバー犯罪者に傍受されるリスクは低い状態です。ドメインには強力な暗号化基準が適用されており、これらが適切に維持されているかのレポートが取得できます。",
                    "moderate": "電子メールの暗号化を強化する必要があります。暗号化が適切でない場合、電子メールが傍受される可能性があります。"
                },
                "header": "プライバシー"
            },
            "rating": {
                "high": "高リスク",
                "low": "低リスク",
                "moderate": "中リスク"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "ブロックされた通数",
                "compliance": "コンプライアンス",
                "deliverability": "配信性",
                "deliverable": "配信可能",
                "deliverable_volume": "配信可能な通数",
                "ip_addresses": "IP アドレス",
                "senders": "送信者",
                "volume": "メール通数"
            },
            "categories": {
                "all": "すべての送信者",
                "authorised_compliant": "承認済み",
                "forwarder": "転送",
                "suspicious": "疑わしい",
                "unauthorised": "未承認",
                "uncategorized": {
                    "message": "ソースを分析中...",
                    "tooltip": "送信者が特定されていません"
                },
                "unknown": "なし"
            },
            "menu": {
                "compliance": "コンプライアンス",
                "deliverability": "配信性",
                "reputation": "評判",
                "threats": "脅威"
            },
            "messages": {
                "category_updated": {
                    "error": "送信者カテゴリの更新に失敗しました",
                    "success": "送信者カテゴリが更新されました"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "ブラックリスト登録済み",
                    "clean": "クリーン",
                    "compliant": "適合",
                    "delivered": "配信済み",
                    "failing_dmarc": "DMARC 失敗",
                    "forward": "転送",
                    "not_delivered": "未配信",
                    "overridden": "オーバーライド済み",
                    "overridden_as_delivered": "オーバーライド済み（配信済み）",
                    "overridden_as_not_delivered": "オーバーライド済み（未配信）",
                    "passing": "合格",
                    "passing_dkim": "DKIM のみ合格",
                    "passing_dkim_spf": "DKIM & SPF 合格",
                    "passing_dmarc": "DMARC 合格",
                    "passing_spf": "SPF のみ合格",
                    "senders": "送信者"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "承認済み送信者 IP",
                        "approved_sender_volume": "承認済み送信者数",
                        "blacklisted": "ブラックリスト登録済み",
                        "blacklists": "ブラックリスト",
                        "blocked_volume": "ブロックされた通数",
                        "category": {
                            "header": "カテゴリ",
                            "tooltip": "各送信者を分類してレポートデータを整理"
                        },
                        "clean": "クリーン",
                        "compliance": "コンプライアンス",
                        "country": "国",
                        "deliverability": "配信性",
                        "delivered": "配信済み",
                        "failing": "失敗",
                        "forwards": "転送",
                        "host": "ホスト",
                        "ip": "IP",
                        "ip_addresses": "IP アドレス",
                        "not_delivered": "未配信",
                        "passing": "合格",
                        "sender": "送信者",
                        "top_blacklisted_senders": "最もブラックリスト登録された送信者",
                        "volume": "通数"
                    },
                    "info": {
                        "blacklists": "この IP アドレスが登録されたブラックリストの数"
                    }
                }
            },
            "title": ":domain のメール送信者"
        },
        "spf_settings": {
            "actions": {
                "add_new": "新しい SPF 指令を追加",
                "edit_record": {
                    "tooltip": "レコードを編集"
                }
            },
            "create_drawer": {
                "header": "新しい SPF 指令を追加"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "指令",
                        "order": "順序"
                    }
                },
                "titles": {
                    "allow_a_none": "自分のドメインの A レコードを許可",
                    "allow_a_value": ":value の A レコードを許可",
                    "allow_exists": ":value の exists マクロを許可",
                    "allow_include": ":value を含める",
                    "allow_ip4_address": ":value の IPv4 アドレスを含める",
                    "allow_ip4_addresses": ":value 内の IPv4 アドレスを含める",
                    "allow_ip6_address": ":value の IPv6 アドレスを含める",
                    "allow_ip6_addresses": ":value 内の IPv6 アドレスを含める",
                    "allow_mx_none": "自分のドメインの MX レコードを許可",
                    "allow_mx_value": ":value の MX レコードを許可",
                    "fail_a_none": "自分のドメインの A レコードを失敗に設定",
                    "fail_a_value": ":value の A レコードを失敗に設定",
                    "fail_exists": ":value の exists マクロを失敗に設定",
                    "fail_include": ":value を含めるのに失敗",
                    "fail_ip4_address": ":value の IPv4 アドレスを失敗に設定",
                    "fail_ip4_addresses": ":value 内の IPv4 アドレスを失敗に設定",
                    "fail_ip6_address": ":value の IPv6 アドレスを失敗に設定",
                    "fail_ip6_addresses": ":value 内の IPv6 アドレスを失敗に設定",
                    "fail_mx_none": "自分のドメインの MX レコードを失敗に設定",
                    "fail_mx_value": ":value の MX レコードを失敗に設定",
                    "neutral_a_none": "自分のドメインの A レコードを中立に設定",
                    "neutral_a_value": ":value の A レコードを中立に設定",
                    "neutral_exists": ":value の exists マクロを中立に設定",
                    "neutral_include": ":value を含めるのを中立に設定",
                    "neutral_ip4_address": ":value の IPv4 アドレスを中立に設定",
                    "neutral_ip4_addresses": ":value 内の IPv4 アドレスを中立に設定",
                    "neutral_ip6_address": ":value の IPv6 アドレスを中立に設定",
                    "neutral_ip6_addresses": ":value 内の IPv6 アドレスを中立に設定",
                    "neutral_mx_none": "自分のドメインの MX レコードを中立に設定",
                    "neutral_mx_value": ":value の MX レコードを中立に設定",
                    "soft_fail_a_none": "自分のドメインの A レコードをソフトフェイルに設定",
                    "soft_fail_a_value": ":value の A レコードをソフトフェイルに設定",
                    "soft_fail_exists": ":value の exists マクロをソフトフェイルに設定",
                    "soft_fail_include": ":value を含めるのをソフトフェイルに設定",
                    "soft_fail_ip4_address": "IPv4 アドレス :value をソフトフェイルに設定",
                    "soft_fail_ip4_addresses": ":value 内の IPv4 アドレスをソフトフェイルに設定",
                    "soft_fail_ip6_address": "IPv6 アドレス :value をソフトフェイルに設定",
                    "soft_fail_ip6_addresses": ":value 内の IPv6 アドレスをソフトフェイルに設定",
                    "soft_fail_mx_none": "自分のドメインの MX レコードをソフトフェイルに設定",
                    "soft_fail_mx_value": ":value の MX レコードをソフトフェイルに設定"
                }
            },
            "edit_drawer": {
                "header": "SPF 指令を編集"
            },
            "fields": {
                "description": {
                    "input_label": "説明"
                },
                "host": {
                    "example": "例: _spf.example.com",
                    "input_label": ":type 値"
                },
                "mechanism": {
                    "input_label": "レコードタイプ"
                },
                "qualifier": {
                    "input_label": "レコード修飾子",
                    "options": {
                        "allow": "+ 許可",
                        "fail": "- 失敗",
                        "neutral": "? 中立（推奨されません）",
                        "soft_fail": "~ ソフトフェイル"
                    }
                },
                "update": "SPF 指令を更新",
                "use_own_domain": {
                    "input_label": "メカニズムドメイン"
                }
            },
            "import_modal": {
                "action": "削除 & インポート",
                "complete": {
                    "message": "SPF インポート完了"
                },
                "header": ":domain の既存の SPF レコード & 指令をインポート",
                "message": "既存の指令はすべて削除され、インポートされた指令に置き換えられます。"
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "最上位レコード修飾子",
                        "options": {
                            "+": "+ 認証合格（推奨されません）",
                            "-": "- 失敗",
                            "?": "? 中立（推奨されません）",
                            "~": "~ ソフトフェイル"
                        },
                        "options_tooltips": {
                            "+": "+all すべての送信者のメール認証を合格とする",
                            "-": "認証されていない送信者のメール認証を失敗に設定",
                            "?": "認証されていない送信者のメールを中立に設定（合格にも失敗にもならない）",
                            "~": "認証されていない送信者のメールをソフトフェイルに設定し、疑わしいメールとして扱う"
                        }
                    },
                    "flattening": {
                        "input_label": "SPF フラッテン",
                        "tooltip": "SPFフラットニングは、include、MX、およびAディレクティブをIPアドレスに変換することにより、DNS検索の制限に対処し、制限を回避するために単一の拡張レコードを作成します。"
                    }
                },
                "heading": "設定"
            },
            "title": "SPF 設定を編集"
        },
        "tables": {
            "clear": "クリア",
            "created_on": "作成日",
            "export": "エクスポート",
            "not_found": "一致するレコードが見つかりません",
            "search": "検索"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "TLS-RPT 設定を構成",
                    "description": "ドメインのレポートデータをまだ受信していません。データが反映されるまで最大 72 時間かかる場合があります。TLS-RPT レポートは、TLS-RPT レポートが有効になっているドメインでのみ取得できます。",
                    "header": "レポートデータなし"
                }
            },
            "heading": ":domain の TLS-RPT レポート",
            "reporters": {
                "heading": "レポート",
                "legend": {
                    "reports": "レポート"
                },
                "table": {
                    "header": {
                        "policy_mode": "ポリシーモード",
                        "policy_type": "ポリシータイプ",
                        "reporter": "レポーター",
                        "total": "合計"
                    }
                }
            },
            "timeline": {
                "heading": "タイムライン",
                "legend": {
                    "failure": "失敗",
                    "success": "成功"
                }
            },
            "title": "TLS-RPT レポート"
        },
        "tls_settings": {
            "disable": {
                "action": "TLS-RPT レポートを無効にする",
                "message": "TLS-RPT レポートを監視するために有効化を推奨します。",
                "modal": {
                    "header": ":domain の TLS-RPT レポートを無効にしました",
                    "message": "このドメインのすべての設定と構成が削除されます。この操作は元に戻せません。"
                }
            },
            "enable": {
                "action": "TLS-RPT レポートを有効にする",
                "heading": "TLS-RPT レポートは無効になっています",
                "message": "TLS-RPT レポートを監視するために有効化を推奨します。",
                "modal": {
                    "header": ":domain の TLS-RPT レポートを有効にする",
                    "message": "TLS-RPT レポートを有効にすると、受信ドメインの障害情報や統計を監視できます。"
                }
            },
            "fields": {
                "email": {
                    "input_label": "レポートの送信先"
                }
            },
            "heading": "TLS-RPT レポート",
            "title": "TLS-RPT 設定を編集"
        },
        "upload": {
            "add": "ファイルを追加",
            "drop_zone": "ここにファイルをドラッグ & ドロップしてアップロード",
            "title": "アップロード"
        },
        "users": {
            "email": {
                "edit": "メールを編集",
                "placeholder": "メールアドレスを入力"
            },
            "menu": "ユーザー",
            "name": {
                "title": "名前"
            },
            "title": "ユーザー管理"
        },
        "validation": {
            "email": "無効なメールアドレスです",
            "invalid_attr": ":attribute は無効です"
        }
    }
} as const;