type FilterString = `filter[${string}]`;

export function useDateRangeFilter(filterParam: FilterString) {
  const params = new URLSearchParams(window.location.href);
  const filter = params.get(filterParam)?.split(',').map(date => new Date(date));

  const end = filter?.[1] ?? new Date();
  const start = filter?.[0] ?? new Date(new Date().setMonth(end.getMonth() - 1));

  return [start, end];
}
