<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import { Link, router } from '@inertiajs/vue3';
import ListingTable from '@/Components/ListingTable.vue';
import PartnerForm from '@/Pages/Partners/Partials/PartnerForm.vue';
import { useRoute } from 'ziggy-js';
import type { PaginatedResponse } from '@/types/http';
import type { PartnerResource } from '@/types/types.gen';

defineProps<{
  partners: PaginatedResponse<PartnerResource>;
  partner: PartnerResource | null;
}>();

const openDrawer = (path: string, uuid: string) => {
  const route = useRoute();
  router.get(route(path, uuid), {}, { preserveState: true });
};
</script>

<template>
  <AppLayout title="Partners">
    <Card class="w-full">
      <template #title>
        {{ $t('partners.title') }}
        <Link :href="route('ui.partners.create')">
          <Button
            v-tooltip.left="'Create Customer'"
            icon="pi pi-plus-circle"
            rounded
            size="large"
            severity="success"
          />
        </Link>
      </template>
      <template #content>
        <ListingTable :value="partners?.data">
          <Column field="name" header="Name">
            <template #body="{ data }">
              <div class="flex items-center">
                <Avatar
                  :image="data.ui_logo"
                  class="flex overflow-hidden rounded-md"
                />
                <div class="ml-4 font-medium leading-6 text-color">
                  {{ data.name }}
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{ data }">
              <div class="flex w-full justify-end gap-3">
                <Button
                  v-tooltip.left="'Update Partner'"
                  @click="openDrawer('ui.partners.edit', data.uuid)"
                  class="!text-"
                  icon="pi pi-pencil"
                  rounded
                  outlined
                  severity="secondary"
                />
                <Link :href="route('ui.customers.index', data.uuid)">
                  <Button
                    v-tooltip.left="'View Customer'"
                    icon="pi pi-shop"
                    rounded
                    outlined
                    severity="secondary"
                  />
                </Link>
              </div>
            </template>
          </Column>
        </ListingTable>
      </template>
    </Card>
    <PartnerForm
      v-if="partner || route().current('ui.partners.create')"
      :partner="partner"
      :partners="partners"
    />
  </AppLayout>
</template>
