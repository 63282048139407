<script setup lang="ts">
import type { CustomerResource, DomainSenderStatisticsResource } from '@/types/types.gen';
import Card from 'primevue/card';
import Button from 'primevue/button';
import DataTable, { type DataTableProps, type DataTableSortEvent } from 'primevue/datatable';
import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import { Link } from '@inertiajs/vue3';
import Avatar from 'primevue/avatar';
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import ComplianceLegend from '@/Pages/Statistics/Sender/Partials/ComplianceLegend.vue';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import { createStatisticsTotals } from '@/Pages/Statistics/Sender/Helpers/TimelineStatisticsHelpers';
import SenderType from '@/Pages/Senders/Partials/SenderType.vue';

const { statistics } = defineProps<{
  title: string;
  customer?: CustomerResource;
  statistics: DomainSenderStatisticsResource[];
}>();

const sortField = ref<DataTableSortEvent['sortField']>('aggregates.total_incidents');
const sortOrder = ref<DataTableProps['sortOrder']>(-1);

const sortedData = computed(() => {
  const senders = orderBy(statistics, [sortField.value], [sortOrder.value === -1 ? 'desc' : 'asc'])
  return senders.slice(0, 5);
});

const onSort = (event: DataTableSortEvent) => {
  sortField.value = event.sortField;
  if (event.sortOrder) {
    sortOrder.value = event.sortOrder;
  }
};

const formatter = useFormatters();
</script>

<template>
  <Card>
    <template #content>
      <DataTable
        :value="sortedData"
        :sortField="sortField"
        :sortOrder="sortOrder"
        data-key="uuid"
        @sort="onSort"
        tableStyle="min-width: 80rem"
      >
        <template #empty>
          {{ $t('tables.not_found') }}
        </template>

        <Column header-class="w-2/6">
          <template #header>
            <div class="font-medium text-xl"
               v-text="title"
            />
          </template>
          <template #body="{ data } : { data: DomainSenderStatisticsResource }">
            <div class="flex items-center">
              <Avatar
                v-if="data.sender_logo"
                :image="data.sender_logo"
                :pt="{image: 'rounded object-contain'}"
                size="large"
              />
              <Avatar
                v-else
                :label="data.sender_service ? data.sender_service[0] : '?'"
                size="large"
              />

              <div class="flex flex-col ml-4">
                <div class="leading-5 text-lg text-color">
                  {{ data?.sender_service ?? $t('reports.senders.unknown') }}
                </div>
                <div class="font-light">
                  <SenderType :sender-type="data.sender_type" />
                </div>
              </div>
            </div>

          </template>
        </Column>

        <Column header-class="w-1/12" field="aggregates.total_incidents" :sortable="true">
          <template #header>
            <div class="font-medium text-xl" v-text="$t('dashboard.senders_compliance.table.header.volume')" />
          </template>
          <template #body="{ data } : { data: DomainSenderStatisticsResource }">
            <div class="text-lg">
              {{ formatter.formatNumber(data.aggregates.total_incidents) }}
            </div>
          </template>
        </Column>

        <Column header-class="w-3/12" >
          <template #header>
            <div class="font-medium text-xl" v-text="$t('dashboard.senders_compliance.table.header.compliance')" />
          </template>
          <template #body="{ data }: {data: DomainSenderStatisticsResource} ">
            <CompliancePercentageBar
              :data="createStatisticsTotals(data.aggregates)"
              :key="data.sender_uuid ?? ''"
            />
          </template>
        </Column>

        <Column v-if="customer" header-class="w-3/12" field="domain_name" :sortable="true">
          <template #header>
            <div class="font-medium text-xl" v-text="$t('dashboard.senders_compliance.table.header.domain')" />
          </template>
          <template #body="{ data } ">
            <Link :href="route('ui.senders-statistics.index', [customer.uuid, data.domain_uuid])">
              <div class="flex justify-between text-primary-default items-center">
                <Button :label="data.domain_name" severity="secondary" text v-tooltip.right="$t('dashboard.senders_compliance.action.view_report.tooltip')"/>
              </div>
            </Link>
          </template>
        </Column>

      </DataTable>
      <div class="flex text-sm mt-4 gap-6 py-3 px-4 bg-section-default rounded">
        <ComplianceLegend />
      </div>
    </template>
  </Card>
</template>
