<script setup lang="ts">
import { Head, usePage } from '@inertiajs/vue3';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import TranslatorService from '@/Utils/Translations/TranslatorService';

type ErrorStatus = 401 | 403 | 404 | 500 | 502 | 503;

const { statusCode } = defineProps<{ statusCode: ErrorStatus }>();
const page = usePage();
const { props: { auth: { user } } } = page

const lang = user ? user.language.code : TranslatorService.fallbackLang;

const { translate, updateLanguage } = useTranslate();
updateLanguage(lang, page);

const title = translate(`errors.${statusCode}.title`);
const description = translate(`errors.${statusCode}.description`);
</script>

<template>
  <Head :title="$t('errors.menu')" />
  <div class="relative flex items-top justify-center min-h-screen sm:items-center sm:pt-0">
    <div class="mx-auto">
      <div class="flex items-center pt-8 sm:justify-start sm:pt-0">
        <div class="px-4 text-lg border-r border-surface-400 tracking-wider">
          {{ title }}
        </div>

        <div class="ml-4 text-lg tracking-wider">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>
