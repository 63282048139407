import defaultTheme from '@/Themes/default';
import h from '@/Themes/h';
import example from '@/Themes/example';

export default function getTheme(theme: string) {
  return {
    preset: {
      default: defaultTheme,
      h: h,
      example: example,
    }[theme],
    options: {
      darkModeSelector: '.g-theme-dark',
    },
  };
}
