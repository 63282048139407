<script setup lang="ts">
import {
  type DnsDelegationVerificationResource,
  type DomainResource,
  MtaStsPolicy,
  type MtaStsSetupInstructionsResource,
} from '@/types/types.gen';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import FormGroup from '@/Components/FormGroup.vue';
import SelectButton from 'primevue/selectbutton';
import { reactive } from 'vue';
import type { MtaTlsForm } from '@/Pages/Domains/TlsRptSettings/types';
import Button from 'primevue/button';
import MtaRecordSetupInstructions from '@/Pages/Domains/TlsRptSettings/Partials/Setup/MtaRecordSetupInstructions.vue';
import VerificationStatus from '@/Components/VerificationStatus.vue';
import type { InertiaForm } from '@inertiajs/vue3';

defineProps<{
  domain: DomainResource;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  verification: DnsDelegationVerificationResource;
}>();

const form = defineModel<InertiaForm<MtaTlsForm>>({ required: true });

const state = reactive({
  showInstructions: window.location.hash === '#mta-record',
});

const toggleInstructions = () => {
  state.showInstructions = !state.showInstructions;
  history.replaceState(null, '', state.showInstructions ? '#mta-record' : ' ');
}
</script>

<template>
    <Card class="border !border-surface">
      <template #content>
        <MtaRecordSetupInstructions
          v-if="state.showInstructions"
          @close="toggleInstructions"
          :domain="domain"
          :mta-sts-setup-instruction="mtaStsSetupInstruction"
          :verification="verification"
        />
        <div v-else>
          <div class="mb-5 flex items-center justify-between">
            <VerificationStatus :verification="verification" :pending-verification-text="$t('mta_settings.status.record.pending')" :verified-text="$t('mta_settings.status.record.verified')"/>
            <Button
              @click="toggleInstructions"
              text
              :label="$t('configurations.actions.view')"
              icon="pi pi-angle-right"
              iconPos="right"
              severity="secondary"
            />
          </div>
          <Divider />
          <div class="flex flex-col gap-7">
            <FormGroup :label="$t('mta_settings.fields.policy.input_label')">
              <template v-slot:element="slotProps">
                <SelectButton
                  v-model="form.mtaStsSettings.policy"
                  v-bind="slotProps"
                  :allow-empty="false"
                  :options="[
                    {
                      label: $t('mta_settings.fields.policy.options.none'),
                      value: MtaStsPolicy.NONE
                    },
                    {
                      label: $t('mta_settings.fields.policy.options.testing'),
                      value: MtaStsPolicy.TESTING
                    },
                    {
                      label: $t('mta_settings.fields.policy.options.enforce'),
                      value: MtaStsPolicy.ENFORCE
                    }
                  ]"
                  optionLabel="label"
                  optionValue="value"
                />
              </template>
            </FormGroup>

            <FormGroup :label="$t('mta_settings.fields.mx_servers.input_label')">
              <template #element>
                <div
                  v-for="(record, index) in form.mtaStsSettings.mx_records"
                  class="!bg-section-default p-2 w-1/3"
                  :key="index"
                  v-text="record"
                />
              </template>
            </FormGroup>
        </div>
        </div>
      </template>
    </Card>
</template>
