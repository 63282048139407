<script setup lang="ts">
import type {
  DkimSetupInstructionsResource,
  DnsDelegationVerificationResource,
  DomainResource,
} from '@/types/types.gen';
import DomainsSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import DkimSetupInstructions from '@/Pages/Domains/DkimSettings/Partials/DkimSetupInstructions.vue';
import Button from 'primevue/button';
import { Link } from '@inertiajs/vue3';
import ShareSetupInstructions from '@/Pages/Domains/Partials/ShareSetupInstructions.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import VerificationStatus from '@/Components/VerificationStatus.vue';
import VerifyButton from '@/Components/VerifyButton.vue';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import { ref } from 'vue';

const { setupInstructions, domain } = defineProps<{
  domain: DomainResource;
  setupInstructions: DkimSetupInstructionsResource;
  cyclopsScore: DomainScore | null;
  verification: DnsDelegationVerificationResource;
}>();

const instructions = ref(setupInstructions);

async function fetchInstructions() {
  try {
    const route = useRoute();
    const result = await axios.get<DkimSetupInstructionsResource>(route('api.setup-instructions.dkim', {
      domain: domain.uuid,
      customer: domain.customer_uuid,
    }));

    if (result.data.nameservers.length === 0) {
      setTimeout(fetchInstructions, 2000);
    } else {
      instructions.value = result.data;
    }
  } catch (e) {
    console.error('Failed to fetch setup instructions:', e);
  }
}

if (setupInstructions.nameservers.length === 0) {
  fetchInstructions();
}

</script>

<template>
  <DomainsSettingsLayout
    :title="$t('dkim_settings.title')"
    :domain="domain"
    :model-value="DomainSettingsTab.DKIM"
    :cyclops-score="cyclopsScore"
  >
    <Link
      :href="
        route('ui.dkim-settings.edit', [domain.customer_uuid, domain.uuid])
      "
      class="my-5 flex"
    >
      <Button
        text
        :label="$t('configurations.actions.back')"
        icon="pi pi-angle-left"
        iconPos="left"
        severity="secondary"
      />

    </Link>
    <DkimSetupInstructions
      :setup-instructions="instructions"
    >
      <template #footer>
        <div
          v-if="instructions.nameservers.length"
          class="flex justify-between mt-2"
        >
          <VerificationStatus :verification="verification"/>
          <div class="flex justify-between gap-4">
            <ShareSetupInstructions :domain="domain"/>
            <VerifyButton
              :verification="verification"
              :domain_uuid="domain.uuid"
              :customer_uuid="domain.customer_uuid"
            />
          </div>
        </div>
      </template>
    </DkimSetupInstructions>
  </DomainsSettingsLayout>
</template>
