<script setup lang="ts">
import Drawer from '@/Pages/Domains/DkimPublicKeys/Partials/Drawer.vue';
import { useForm } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import type { PaginatedResponse } from '@/types/http';
import type {
  DkimPublicKeyResource,
  DkimSettingsResource,
  DomainResource,
} from '@/types/types.gen';
import Edit from '@/Pages/Domains/DkimSettings/Edit.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import type { DkimPublicKeyForm } from '@/Pages/Domains/SpfSettings/types';

const { domain } = defineProps<{
  dkimPublicKeys: PaginatedResponse<DkimPublicKeyResource>;
  dkimSettings: DkimSettingsResource;
  domain: DomainResource;
  cyclopsScore: DomainScore | null;
}>();

const form = useForm<DkimPublicKeyForm>({
  uuid: undefined,
  selector: '',
  record_type: 'cname',
  value: '',
  ttl: 3600,
  description: '',
});

const submit = () => {
  const route = useRoute();
  form.post(
    route('ui.dkim-public-keys.store', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
    }),
  );
};
</script>

<template>
  <div>
    <Edit
      :domain="domain"
      :dkim-public-keys="dkimPublicKeys"
      :dkim-settings="dkimSettings"
      :cyclops-score="cyclopsScore"
    />
    <Drawer
      v-model="form"
      :domain="domain"
      :header="$t('dkim_settings.create_drawer.header')"
      @submit="submit"
    />
  </div>
</template>
