<script setup lang="ts">
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Card from 'primevue/card';
import { ref } from 'vue';
import AppMenu from '@/Layouts/Menus/LeftMenu.vue';
import ApplicationLogo from '@/Pages/Auth/ApplicationLogo.vue';
import { Link } from '@inertiajs/vue3';
import { useComponentTokens } from '@/Utils/useComponentTokens.ts';

const isMobileMenuOpen = ref(false);

const sidebarTokens = useComponentTokens()?.sidebar || {};
</script>

<template>
  <Card class="w-full xl:w-96" :dt="sidebarTokens.card" >
    <template #content>
      <div class="flex justify-between items-center">
        <Link
          class="xl:mx-auto"
          :href="route('ui.dashboard.show', $page.props.activeCustomer.uuid)"
        >
          <ApplicationLogo class="xl:mx-auto mt-1" />
        </Link>
        <div class="xl:hidden">
          <Button
            @click="isMobileMenuOpen = !isMobileMenuOpen"
            text
            :icon="isMobileMenuOpen ? 'pi pi-times' : 'pi pi-bars'"
            severity="secondary"
            aria-label="Bookmark"
          />
        </div>
      </div>

      <div class="hidden xl:block mt-6 xl:mt-10">
        <div class="text-lg font-semibold text-center">
          {{ $page.props.oemConfig.name }}
        </div>
        <Divider :dt="sidebarTokens.divider" />
          <div class="flex items-center gap-5">
            <Avatar
              :dt="sidebarTokens.avatar"
              class="shrink-0"
              icon="pi pi-user"
              size="large"
            />
            <div class="flex flex-col">
              <div v-text="$page.props.auth.user?.name ?? ''" />
              <div v-text="$page.props.activeCustomer.name" />
            </div>
          </div>
        <Divider :dt="sidebarTokens.divider" />
      </div>
      <AppMenu :is-mobile-menu-open="isMobileMenuOpen" />
    </template>
  </Card>
</template>
