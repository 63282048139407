<script setup lang="ts">
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import EmailsInput from '@/Components/EmailsListBox.vue';
import AccordionPanel from 'primevue/accordionpanel';
import { reactive, watch } from 'vue';
import { type InertiaForm } from '@inertiajs/vue3';
import { type DmarcSettingsResource, RufStatus } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useConfirm } from 'primevue/useconfirm';

const form = defineModel<InertiaForm<DmarcSettingsResource>>({ required: true });

const { translate } = useTranslate();
const confirm = useConfirm();

const state = reactive({
  options: form.value.ruf_options.split(':'),
});

watch(
  () => state.options,
  options => form.value.ruf_options = options.join(':'),
  { deep: true },
);

watch(() => form.value.ruf_status, (currentStatus, previousStatus) => {
  if (previousStatus === RufStatus.DISABLED) {
    confirm.require({
      group: 'ruf_confirm',
      header: translate('dmarc_settings.ruf.confirm_modal.header'),
      message: translate('dmarc_settings.ruf.confirm_modal.message'),
      acceptLabel: translate('dmarc_settings.ruf.confirm_modal.accept'),
      rejectProps: {
        label: translate('dmarc_settings.ruf.confirm_modal.reject'),
        text: true,
      },
      reject: () => {
        form.value.ruf_status = RufStatus.DISABLED;
      },
    })
  }
});

</script>

<template>
  <ConfirmDialog group="ruf_confirm" class="w-1/3">
    <template #message>
      <div class="flex flex-col gap-4">
        <p>{{ $t('dmarc_settings.ruf.confirm_modal.message') }}</p>
        <p>{{ $t('dmarc_settings.ruf.confirm_modal.message_disclaimer') }}</p>
      </div>
    </template>
  </ConfirmDialog>
  <AccordionPanel value="2">
    <AccordionHeader>{{ $t('dmarc_settings.ruf.heading') }}</AccordionHeader>
    <AccordionContent>
      <div class="flex flex-col gap-8 px-5 py-4">
        <FormGroup
          :label="$t('dmarc_settings.ruf.fields.status.input_label')"
          :error="form.errors.ruf_status"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.ruf_status"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                {
                  label: $t('dmarc_settings.ruf.fields.status.options.disabled'),
                  value: 'disabled'
                },
                {
                  label: $t('dmarc_settings.ruf.fields.status.options.enabled'),
                  value: 'enabled'
                },
                {
                  label: $t('dmarc_settings.ruf.fields.status.options.specified'),
                  value: 'specified recipients'
                }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="form.ruf_status === RufStatus.SPECIFIED_RECIPIENTS"
          :label="$t('dmarc_settings.rua.fields.emails.input_label')"
          :error="form.errors.ruf_emails"
        >
          <template v-slot:element>
            <EmailsInput :emails="form.ruf_emails ?? []" class="w-full xl:w-1/3" />
          </template>
        </FormGroup>

        <FormGroup
          :label="$t('dmarc_settings.ruf.fields.options.input_label')"
          :error="form.errors.ruf_options"
        >
          <template v-slot:element>
            <div class="flex flex-col gap-2">
              <div
                v-for="(option, key) in [
                  {
                    value: '0',
                    label: $t('dmarc_settings.ruf.fields.options.options.0')
                  },
                  {
                    value: '1',
                    label: $t('dmarc_settings.ruf.fields.options.options.1')
                  },
                  {
                    value: 'd',
                    label: $t('dmarc_settings.ruf.fields.options.options.d')
                  },
                  {
                    value: 's',
                    label: $t('dmarc_settings.ruf.fields.options.options.s')
                  }
                ]"
                :key="key"
                class="flex items-center"
              >
                <Checkbox
                  v-model="state.options"
                  :inputId="'ruf_option_id_' + option.value"
                  name="ruf_option"
                  :value="option.value"
                />
                <label
                  :for="'ruf_option_id_' + option.value"
                  class="ml-2"
                  v-text="option.label"
                />
              </div>
            </div>
          </template>
        </FormGroup>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>
