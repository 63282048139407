<script setup lang="ts">
import Edit from '@/Pages/Domains/SpfSettings/Edit.vue';
import Drawer from '@/Pages/Domains/SpfSettings/Directives/Drawer.vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import { useForm } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import type {
  DomainResource,
  SpfDirectiveResource,
  SpfSettingResource,
} from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http';
import type { DomainScore } from '@/types/cyclops.types.gen';

const { domain, directive } = defineProps<{
  domain: DomainResource;
  directive: SpfDirectiveResource;
  directives: PaginatedResponse<SpfDirectiveResource>;
  settings: SpfSettingResource;
  cyclopsScore: DomainScore | null;
}>();

const form = useForm(directive);

const submit = () => {
  const route = useRoute();
  form.put(
    route('ui.spf-directives.update', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
      spf_directive: directive.uuid,
    }),
    responseCallbacks,
  );
};
</script>

<template>
  <Edit
    :domain="domain"
    :settings="settings"
    :directives="directives"
    :cyclops-score="cyclopsScore"
  />
  <Drawer
    v-model="form"
    :domain="domain"
    :header="$t('spf_settings.edit_drawer.header')"
    @submit="submit"
  />
</template>
