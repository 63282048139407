import type { DkimPublicKeyResource, SpfDirectiveResource } from '@/types/types.gen';

export type SpfSettingsForm = {
  all_mechanism_qualifier: string;
  directives: SpfDirectiveResource[];
  enable_flattening: boolean;
};

export type DkimPublicKeyForm = Pick<DkimPublicKeyResource, 'description' | 'record_type' | 'selector' | 'ttl'> & { uuid: string | undefined; value: string };

// Because there are no names for the qualifiers in the OpenAPI spec, openapi-ts cannot infer them from the punctuation marks
// We have to define them ourselves
export enum SpfQualifier {
  FAIL = '-',
  SOFT_FAIL = '~',
  NEUTRAL = '?',
  PASS = '+',
}

// And override the type of the qualifier in the SpfDirectiveResource
export type SpfDirectiveResourceWithQualifier = Omit<SpfDirectiveResource, 'qualifier'> & { qualifier: SpfQualifier };
